import Swal from "sweetalert2";
import React, {useEffect, useState} from "react";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import "./opnijCustomPopup.scss";
import {Button, red, white} from "../Theme";
import closeIcon from "../resources/images/ic-closeWhite.svg";
import Api from "../util/Api";
import WhatsNewCard from "../components/WhatsNewCard";

export const WhatsNewPopup = withReactContent(Swal)

function showWhatsNewPopup(whatsNew, onOpen, onClose) {
    WhatsNewPopup.fire({
        html: (<PopupContent whatsNew={whatsNew} onOpen={onOpen} onClose={onClose} onClick={() => WhatsNewPopup.close()}/>),
        showCancelButton: false,
        showConfirmButton: false,
        customClass: {
            container: "swal2-container-custom",
            htmlContainer: "swal2-html-container-custom",
            popup: "swal2-popup-custom"
        },
        heightAuto: false
    }).then(() => {
        onClose()
    });
}

function PopupContent(props) {

    const [currentIndex, setCurrentIndex] = useState(0)
    const maxIndex = props.whatsNew.items.length - 1

    useEffect(() => {
        Api.post('whatsNew/' + props.whatsNew.id + '/read')
        props.onOpen()
        // eslint-disable-next-line
    }, [])

    const indicators = [];
    for (let i = 0; i <= maxIndex; i++) {
        indicators.push(<Indicator isActive={i === currentIndex}/>)
    }

    return (
        <PopupContentRoot>
            <CloseButton onClick={props.onClick} src={closeIcon}/>

            <WhatsNewCard
                popUpVariant
                mediaUrl={props.whatsNew.items[currentIndex].mediaUrl}
                title={props.whatsNew.items[currentIndex].title}
                date={props.whatsNew.items[currentIndex].date}
                text={props.whatsNew.items[currentIndex].text}
            />

            <BottomBar>
                <Indicators>
                    {indicators}
                </Indicators>
                <StyledButton onClick={() => nextButtonClicked()}>
                    {currentIndex < maxIndex ? "Volgende" : "Beginnen"}
                </StyledButton>
            </BottomBar>
        </PopupContentRoot>
    );

    function nextButtonClicked() {
        if (currentIndex < maxIndex) {
            setCurrentIndex(currentIndex + 1)
        } else {
            WhatsNewPopup.close()
        }
    }
}

const StyledButton = styled(Button)`
    width: 124px;
`;

const Indicator = styled.div`
    background: ${red};
    opacity: ${props => props.isActive ? 1 : 0.3};
    border-radius: 7px;
    height: 7px;
    width: ${props => props.isActive ? 21 : 7}px;
`;

const Indicators = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

const BottomBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`;

const PopupContentRoot = styled.div`
    max-width: 416px;
    margin: auto;
    background: ${white};
    border-radius: 3px;
    position: relative;
`;

const CloseButton = styled.img`
   max-width: 24px;
   max-height: 24px;
   position: absolute;
   right: 0;
   cursor: pointer;
   z-index: 10;
`;


export default showWhatsNewPopup;