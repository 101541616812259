import {Redirect} from "react-router-dom";
import SentryRoute from './SentryRoute';

function PrivateRoute(props) {

    return (
        <>
            {props.userIsAuthenticated ?
                <SentryRoute
                    path={props.path}
                    exact={props.exact}
                    component={props.component}
                />
                :
                <Redirect to={'/login'}/>
            }
        </>
    )
}

export default PrivateRoute;