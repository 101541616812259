import React from "react";
import styled from "styled-components";
import {black, nunito, purple, purpleDark, sourceSansPro, white} from "../Theme";
import getReadableTimeDifferenceSince from "../util/Methods";
import {device} from "../constants/breakpoints";

function CommentCard(props) {
    return (
        <CommentCardRoot isRead={props.comment.isRead} onClick={openTopic}>
            <TitleAndDate>
                <Title>
                    {getTitle(props.comment)}
                    {getLink(props.comment)}
                    {getDate(props.comment)}
                </Title>
                <DateText>
                    {getReadableTimeDifferenceSince(props.comment.createdDate)}
                </DateText>
            </TitleAndDate>
            <Text>
                {props.comment.text}
            </Text>
        </CommentCardRoot>
    )

    function openTopic() {
        props.navigateToComment(props.comment)
    }
}


function getTitle(comment) {
    if (comment.taskId) {
        return comment.authorName + ' heeft een opmerking geplaatst bij de kaart '
    } else if (comment.examId) {
        return comment.authorName + ' heeft een opmerking geplaatst bij de toets '
    } else if (comment.homeworkItemId) {
        return comment.authorName + ' heeft een opmerking geplaatst bij huiswerk '
    } else {
        return comment.authorName + ' heeft een opmerking geplaatst '
    }
}

function getLink(comment) {
    return <LinkText>{comment.topicTitle}</LinkText>
}

function getDate(comment) {
    if (comment.taskId) {
        return ':'
    }
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    const date = new Date(comment.topicDate)
    return ' van ' + date.toLocaleDateString('nl-NL', options) + ':'
}

export default CommentCard;

const CommentCardRoot = styled.div`
    cursor: pointer;
    max-width: 342px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.isRead ? "#EFEEF3" : white};
    border: 1px solid rgba(86, 89, 146, 0.1);
    border-bottom: 2px solid rgba(86, 89, 146, 0.1);
    border-radius: 3px;
    word-break: break-word;
    padding: 8px;
    gap: 8px;
    
    @media screen and ${device.tablet} {
        max-width: unset;
    }
`;

const TitleAndDate = styled.div`
    display: flex;
    flex-direction: row;
`;

const Title = styled.div`
    font-family: ${sourceSansPro};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${black};
    flex-shrink: 1;
`;

const DateText = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${purpleDark};
    opacity: 0.5;
    flex-grow: 1;
    flex-shrink: 0;
`;

const LinkText = styled.div`
   display: inline-block;
   color: ${purple};
   line-height: 20px;
   font-style: normal;
   font-weight: 400;
   text-decoration: underline;
`;

const Text = styled.div`
   font-family: ${sourceSansPro};
   font-weight: 400;
   font-size: 16px;
   font-style: italic;
   color: ${black};
`;