import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {nunito, red, white} from "../Theme";
import {useMobileDesign} from "./hooks/windowSize";
import {SwitchOption} from "../constants/enums";

function Switch(props) {

    const {
        optionLeft,
        optionRight,
        optionLeftMobile,
        optionRightMobile,
        onAfterSwitch,
        defaultSwitchOption,
    } = props

    const enableMobileDesign = useMobileDesign();
    const [selectedOption, setSelectedOption] = useState(defaultSwitchOption ?? SwitchOption.LEFT)

    /**
     * Propagate the selected option to parent
     * */
    useEffect(() => {
        onAfterSwitch && onAfterSwitch(selectedOption)
        // eslint-disable-next-line
    }, [selectedOption])

    return (
        <SwitchRoot>
            <SwitchPart onClick={() => switchOption()} isSelected={selectedOption === SwitchOption.LEFT}>
                <Title isSelected={selectedOption === SwitchOption.LEFT}>{enableMobileDesign ? optionLeftMobile : optionLeft}</Title>
            </SwitchPart>

            <SwitchPart onClick={() => switchOption()} isSelected={selectedOption === SwitchOption.RIGHT}>
                <Title isSelected={selectedOption === SwitchOption.RIGHT}>{enableMobileDesign ? optionRightMobile : optionRight}</Title>
            </SwitchPart>
        </SwitchRoot>
    )

    function switchOption() {
        switch (selectedOption) {
            case SwitchOption.LEFT: {
                setSelectedOption(SwitchOption.RIGHT)
                break;
            }
            case SwitchOption.RIGHT: {
                setSelectedOption(SwitchOption.LEFT)
                break;
            }
            default: setSelectedOption(SwitchOption.RIGHT)
        }
    }
}

const SwitchRoot = styled.div`
    max-width: 126px;
    height: 30px;
    width: 100%;
    background: rgba(94, 65, 135, 0.07);
    display: flex;
    flex-direction: row;
    border-radius: 5px;
`;

const SwitchPart = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${ props => props.isSelected ? red : undefined };
    border-radius: 5px;
    padding: 6px;
    user-select: none;
`;

const Title = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${white};
    color: ${ props => props.isSelected ? white : 'rgba(94, 65, 135, 0.7)'};
`;

export default Switch;