import styled from 'styled-components';
import {nunito, purple, sourceSansPro, white} from '../../Theme';
import {device} from '../../constants/breakpoints';

export const EmptyStateCard = () => {
    return (
        <StyledEmptyStateCard>
            <Inner>
                <EmptyStateTitle>Geen resultaten gevonden</EmptyStateTitle>
                <p>Er zijn geen resultaten gevonden. Probeer de filters te wijzigen.</p>
            </Inner>
        </StyledEmptyStateCard>
    );
}

const StyledEmptyStateCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 590px;
`;

const Inner = styled.div`
    background-color: ${white};
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0 2px 0 rgba(86, 89, 146, 0.1);
    border-radius: 3px 3px 0 0;
    padding: 38px 67px;

    p {
        font-family: ${sourceSansPro};
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        max-width: 240px;
        color: ${purple};
        margin: 0 auto;
    }

    @media screen and ${device.tablet} {
        padding: 38px 47px;
    }

    @media screen and ${device.mobileL} {
        padding: 20px 23px;
    }
`;

const EmptyStateTitle = styled.h2`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-align: center;
    color: ${purple};
`;