import styled from "styled-components";
import {BasicGrid, Card, CardTitle, purple, sourceSansPro} from "../Theme";
import React, {useEffect, useState} from "react";
import Api from "../util/Api";
import TaskHelper from "../util/TaskHelper";
import addIcon from "../resources/images/ic-add.png";
import {device} from "../constants/breakpoints";
import TaskCard from "./TaskCard";
import {getFormattedDateFromTimestamp} from "./examoverview/ExamOverviewScreen";
import showTaskPopup from "../popups/showTaskPopup";
import {isInReadOnlyMode} from "../App";
import notificationIcon from "../resources/images/ic-notification.svg";
import AppStorage, {StorageKey} from "../util/AppStorage";

function TaskListCard(props) {
    const [tasks, setTasks] = useState([]);
    useEffect(
        getTasksFromApi,
        // eslint-disable-next-line
    []
    )

    return <Card {...props}>
        <CardTitleContainer>
            <CardTitle>Planning</CardTitle>
            <NoTasksWarning $display={tasks.length === 0}>
                <img src={notificationIcon} alt={"Illustratie"}/>
                Er is hier nog niets voor ingepland
            </NoTasksWarning>
        </CardTitleContainer>
        <BasicGrid columnDivision="1fr 1fr" rowGap="15px" columnGap="18px" maxWidth="100%">
            {tasks.sort((a, b) => {
                return a.date - b.date
            }).map((task) => {
                return <TaskCard
                    key={task.id}
                    id={task.id}
                    title={task.title}
                    examId={task.examId}
                    homeworkItemId={task.homeworkItemId}
                    subject={task.subject}
                    colorHex={task.colorHex}
                    isFinished={task.isFinished}
                    setOnFinishedChanged={(isFinished) => {
                        handleOnFinishedClick(task, isFinished)
                    }}
                    commentCount={task.commentCount}
                    notificationCount={task.notificationCount}
                    date={!!task.date ? getFormattedDateFromTimestamp(task.date) : ''}
                    timeEstimate={task.timeEstimate}
                    onClick={() => openTask(task)}
                    deleteAction={() => deleteTask(task)}
                    copyAction={() => copyTask(task)}
                />
            })}

            {!isInReadOnlyMode() &&
            <AddTaskButton onClick={() => {
                openNewTask()
            }}>
                <AddTaskButtonTextWrapper>
                    <img src={addIcon} alt="toevoegen icoon"/>
                    <AddTaskButtonText>
                        Item toevoegen
                    </AddTaskButtonText>
                </AddTaskButtonTextWrapper>
            </AddTaskButton>}
        </BasicGrid>
    </Card>

    function getTasksFromApi() {
        Api.get((props.exam ? 'exams/' : 'homeworkItems/') + props.match.params.id + '/tasks', (response) => {
            setTasks(response.data)
        });
    }

    function handleOnFinishedClick(task, isFinished) {
        //Don't replace with ===, isFinished can be 0
        const tasksCopy = [...tasks]
        const taskToEdit = tasksCopy.find(t => t.id === task.id)
        if (taskToEdit) {
            taskToEdit.isFinished = isFinished ? 1 : 0
            setTasks(tasksCopy)
        }
    }

    function openNewTask() {
        showTaskPopup({
            examId: props.exam ? props.exam.id : null,
            homeworkItemId: props.homeworkItem ? props.homeworkItem.id : null,
        }, null, getTasksFromApi, null, null, null, getTasksFromApi, props.exam ? props.exam : props.homeworkItem)
    }

    function openTask(task, setRead = true) {
        if(setRead) {
            Api.post('tasks/' + task.id + '/read', null, () => {
                task.notificationCount = 0
                setTasks([...(tasks.filter((t) => {
                    return t.id !== task.id
                })), task])
            })
        }
        showTaskPopup(task, props.itemTitle, getTasksFromApi, (task) => {
            setTasks([...(tasks.filter((t) => {
                return t.id !== task.id
            }))])
        }, (task) => {
            setTasks([...tasks, task])
            openTask(task, false)
        }, null, getTasksFromApi, props.exam ? props.exam : props.homeworkItem)
    }

    function deleteTask(task) {
        Api.delete('tasks/' + task.id, () => onDeleteTask(task))
    }

    function onDeleteTask(task) {
        let taskList = [...tasks]
        let newTaskList = TaskHelper.removeTaskFromTaskList(task, taskList)
        setTasks(newTaskList)
    }

    function copyTask(task) {
        let taskCopy = TaskHelper.copyTask(task)
        Api.put('pupils/' + AppStorage.get(StorageKey.USER_ID) + '/tasks/', taskCopy, (response) => {
            setTasks([...tasks, response.data])
            openTask(response.data, false)
        })
    }
}

export default TaskListCard;

export const AddTaskButton = styled.a`
  position: relative;
  background: rgba(86, 89, 146, 0.05);
  border: 1px dashed rgba(86, 89, 146, 0.15);
  border-radius: 3px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 107px;
  &:hover {
    cursor: pointer;
  }
  
  @media screen and ${device.mobileL} {
    height: 57px;
  }   
`;

export const CardTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-content: start;
    box-sizing: content-box;
    @media screen and ${device.tablet} {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 8px;
    }`;

export const NoTasksWarning = styled.div`
    color: ${purple};
    font-family: ${sourceSansPro};
    font-size: 16px;
    font-weight: 400;
    display: ${props => props.$display ? "flex" : "none"};
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: -24px;
    
     @media screen and ${device.mobileL} {
        margin-bottom: 24px;
    } 
`;

export const AddTaskButtonTextWrapper = styled.div`
    display: flex;
    gap: 14px;
    align-content: center;
    justify-content: center;
`;

export const AddTaskButtonText = styled.span`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 1.125em;
    color: #565992;
`;