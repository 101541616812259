import {useRef, useState} from 'react';
import {determineSkill} from '../../../util/Helpers';
import styled, {css} from 'styled-components';
import {black, nunito, sourceSansPro, white} from '../../../Theme';
import {skills} from '../../../constants/skills';
import {Skills} from '../../../constants/enums';

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Label = styled.p`
    flex-shrink: 0;
    flex-basis: 20px;
    margin: 0;
    font-family: ${sourceSansPro};
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, .5);
`;

const Bar = styled.div`
    height: 20px;
    width: 100%;
    background: rgba(86, 89, 146, 0.1);
    display: grid;
    grid-template-columns: 31px repeat(${({$maxScore}) => $maxScore}, 1fr);
    border-radius: 10px;
`;

const InnerBar = styled.div`
    grid-column: span ${({$columns}) => $columns ? $columns : 1};
    height: 100%;
    background-color: ${({$backgroundColor}) => $backgroundColor ?? "#D6D6DC"};
    position: relative;
    border-radius: 13px;
    font-family: ${nunito};
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &:after {
        content: '${({$score}) => $score ? $score : "Niet ingevuld"}';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(calc(100% + 4px), -50%);
        pointer-events: none;
        white-space: nowrap;
        font-size: 13px;
    }
`;

const triangleBase = css`
    content: "";
    display: block;
    position: absolute;
    border: solid;
`;

const HoverElement = styled.div.attrs(({$x}) => ({
    style: {
        left: $x + 'px'
    }
}))`
    position: absolute;
    top: 16px;
    background-color: ${white};
    color: ${black};
    padding: 10px;
    border-radius: 3px;
    display: ${({$visible}) => ($visible ? 'block' : 'none')};
    pointer-events: none;
    width: 175px;
    border: 1px solid #E2E2EB;
    box-shadow: 0 8px 20px -2px rgba(0, 0, 0, .07);
    z-index: 1;

    &:before {
        ${triangleBase};
        top: -8px;
        left: 20px;
        border-color: #E2E2EB transparent;
        border-width: 0 8px 8px 8px;
    }

    &:after {
        ${triangleBase};
        top: -7px;
        left: 19px;
        border-color: ${white} transparent;
        border-width: 0 9px 9px 9px;
    }
`;

const HoverElementTitle = styled.p`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
`;

const HoverElementText = styled.p`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    margin: 0;

    ${({$addMarginBottom}) => $addMarginBottom && "margin-bottom: 16px;"};
`;

const HiddenRow = styled(Row)``;

export const HiddenLabel = styled(Label)`
    visibility: hidden;
`;

const HiddenBar = styled(Bar)`
    height: 0;
    width: 100%;
`;

const VerticalBar = styled.div`
    grid-column: ${({ $column }) => $column};
    position: relative;
    
    &:after {
        content: "";
        position: absolute;
        top: -16px;
        right: 0;
        height: 40px;
        width: 2px;
        background-color: #DFDFEA;
    }
    
    ${({$isLast}) => $isLast && css`
        &:after {
            height: 80px;
        }
    `};
`;

// Notice the showAllEf condition, where usage changes when filtering "Alle Executieve Functies", and also the isPupilDetail boolean when used in MentorPlanOfActionScreen
export const SingleRow = ({periodData, selectedEfId, data, isLast = false, isPupilDetail = false}) => {
    const showAllEf = !Boolean(selectedEfId) && !isPupilDetail;

    const totalScore = isPupilDetail ? (periodData?.totalScore ?? undefined) : showAllEf ? (calculateAverageScore(data?.pupils) ?? undefined) : (Array.isArray(periodData?.scoresPerExecutiveFunction) ? periodData?.scoresPerExecutiveFunction?.find((item) => item.id === selectedEfId)?.totalScore : 0);
    const skillScale = {beginner: periodData?.beginner, developing: periodData?.developing, mastered: periodData?.mastered}
    const pupilSkillCounts = showAllEf ? countPupilsBySkillScale(data.pupils, skillScale) : null;

    const minScore = periodData?.mastered / 4; // divided by 4 because there are 4 options per answer
    const maxScore = periodData?.mastered - minScore;

    const beginnerStart = minScore;
    const developingStart = periodData?.beginner + 1;
    const masteredStart = periodData?.developing + 1;

    const beginnerColumn = beginnerStart - minScore + 1;
    const developingColumn = developingStart - minScore + 1;
    const masteredColumn = masteredStart - minScore + 1;

    // Hover when mouse over
    const [hoverPosX, setHoverPosX] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const innerBarRef = useRef(null);

    const handleMouseMove = (e) => {
        const rect = innerBarRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left - 20;

        window.requestAnimationFrame(() => {
            setHoverPosX(x);
        });
    };

    return (
        <>
            <HiddenRow>
                <HiddenLabel />
                <HiddenBar $maxScore={maxScore}>
                    <VerticalBar $column={beginnerColumn} $isLast={isLast} />
                    <VerticalBar $column={developingColumn} $isLast={isLast} />
                    <VerticalBar $column={masteredColumn} $isLast={isLast} />
                </HiddenBar>
            </HiddenRow>
            <Row>
                <Label>{getShortenedTitle(showAllEf ? data.title : periodData.title)}</Label>
                <Bar $maxScore={maxScore}>
                    <InnerBar
                        ref={innerBarRef}
                        onMouseMove={handleMouseMove}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        $columns={totalScore - minScore + 1}
                        $score={totalScore}
                        $backgroundColor={determineSkill(totalScore, skillScale)?.color}
                    >
                        <HoverElement
                            $x={hoverPosX}
                            $visible={isHovered}
                        >
                            <HoverElementTitle>Score</HoverElementTitle>
                            <HoverElementText $addMarginBottom={showAllEf}>{totalScore ?? "Niet ingevuld"}</HoverElementText>
                            {showAllEf &&
                                <>
                                    <HoverElementTitle>Aantal leerlingen</HoverElementTitle>
                                    {Object.keys(pupilSkillCounts)?.map(skillLevel => {
                                        const { label } = skills[skillLevel];
                                        return (
                                            <HoverElementText key={label}><strong>{pupilSkillCounts[skillLevel]}</strong> {label}</HoverElementText>
                                        );
                                    })}
                                </>
                            }
                        </HoverElement>
                    </InnerBar>
                </Bar>
            </Row>
        </>
    );

    // Helper function to shorten "Periode 1" to "P1"
    function getShortenedTitle(title) {
        return title?.[0] + title?.[title.length - 1];
    }

    // Calculate the average score when filtering "Alle Executieve Functies"
    function calculateAverageScore(pupils) {
        // Filter out pupils with null scores and continue when there are validScores
        const validScores = pupils?.filter(pupil => pupil.score !== null)?.map(pupil => pupil.score);
        if (validScores.length === 0) return null;

        // Calculate the sum of valid scores and then the average
        const totalScore = validScores.reduce((sum, score) => sum + score, 0);
        const average = totalScore / validScores.length;

        return Math.round(average);
    }

    // Count pupils by skill scale when filtering "Alle Executieve Functies"
    function countPupilsBySkillScale(pupils, skillScale) {
        const skillCounts = {
            [Skills.BEGINNER]: 0,
            [Skills.DEVELOPING]: 0,
            [Skills.MASTERED]: 0
        };

        pupils?.forEach(pupil => {
            // Exclude pupils with null scores
            if (pupil.score !== null) {
                const skillLevel = determineSkill(pupil.score, skillScale);
                if (skillLevel?.key) {
                    skillCounts[skillLevel.key]++;
                }
            }
        });

        return skillCounts;
    }
}