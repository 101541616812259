import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import {
    BackgroundedScreen,
    Header,
    HeaderLogo,
    HeaderTitle,
    nunito,
    purple,
    purpleLight,
    red,
    white
} from "../../Theme";
import styled from "styled-components";
import deleteLogo from "../../resources/images/delete.svg";
import backLogo from "../../resources/images/back.svg";
import '../datepicker.scss';
import ExamCard from "./ExamCard";
import Api from "../../util/Api";
import TaskListCard from "../TaskListCard";
import {device} from "../../constants/breakpoints";
import {useHistory, useLocation} from "react-router-dom";
import {isInReadOnlyMode} from "../../App";
import {showConfirmationPopup} from "../../popups/showBasicPopup";
import CommentsCard from "../CommentsCard";
import {showNativeNavigation} from "../../AppInterface";

function ExamScreen(props) {
    const history = useHistory();
    const [exam, setExam] = useState(history.location.state ? (history.location.state.exam ?? null) : null);
    const isMakingNewExam = !props.match.params.id
    const [mobileActiveTab, setMobileActiveTab] = useState(0)
    const location = useLocation()
    const date = location.state ? location.state.date : null

    useEffect(() => {
        showNativeNavigation()
        if (props.match.params.id) {
            Api.post('exams/' + props.match.params.id + '/read')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMakingNewExam) {
            getExamFromApi();
        }
        // eslint-disable-next-line
    }, [props.match.params.id])

    const content = (
        <BackgroundedScreen>
            <HeaderAndListWrapper>
                <Header>
                    <HeaderLogo src={backLogo} onClick={() => {
                        history.goBack();
                    }}/>
                    <HeaderTitle>{exam ? exam.subject : "Toets"}</HeaderTitle>
                    <HeaderLogo visibility={isMakingNewExam || isInReadOnlyMode() ? 'hidden' : 'default'}
                                src={deleteLogo}
                                onClick={deleteExam}/>
                </Header>
                <TabWrapper hidden={isMakingNewExam}>
                    <Tab isActive={mobileActiveTab === 0} onClick={() => setMobileActiveTab(0)}>
                        Algemeen
                    </Tab>
                    <Tab isActive={mobileActiveTab === 1} onClick={() => setMobileActiveTab(1)}>
                        Planning
                    </Tab>
                    <Tab isActive={mobileActiveTab === 2} onClick={() => setMobileActiveTab(2)}>
                        Opmerkingen
                    </Tab>
                </TabWrapper>
                <CardList>
                    {(exam || isMakingNewExam) &&
                        <>
                            <ExamCard
                                exam={exam}
                                hideOnMobile={mobileActiveTab !== 0}
                                onExamChange={(updatedExam) => setExam(updatedExam)}
                                date={date}
                                match={props?.match}
                                {...props}
                            />
                            {!isMakingNewExam &&
                                <TaskListCard
                                    exam={exam}
                                    hideOnMobile={mobileActiveTab !== 1}
                                    {...props}
                                />
                            }
                            {!isMakingNewExam &&
                                <CommentsCard
                                    exam={exam}
                                    examId={props.match.params.id}
                                    hideOnMobile={mobileActiveTab !== 2}
                                    {...props}
                                />
                            }
                        </>
                    }
                </CardList>
            </HeaderAndListWrapper>
        </BackgroundedScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"Toetsweek"}
            content={content}
        />
    )


    function deleteExam() {
        showConfirmationPopup(
            'Let op!',
            'Weet je zeker dat je deze toets wilt verwijderen? Je kunt \'m dan niet meer terughalen!',
            'Verwijderen',
            () => {
                Api.patch('exams/' + props.match.params.id,{isRemoved: 1}, onSuccess);

                function onSuccess(response) {
                    history.replace('/toetsweek')
                }
            },
            'Annuleren', () => {
            })
    }

    function getExamFromApi() {
        Api.get('exams/' + props.match.params.id, onSuccess, null, null, () => history.replace('/toetsweek'));

        function onSuccess(response) {
            setExam(response.data);
        }
    }
}

export const Tab = styled.div`
    background: ${props => props.isActive ? red : 'transparent'};
    color: ${props => props.isActive ? white : purple};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
    font-size: 16px;
    font-family: ${nunito};
    padding: 4px;
    border-radius: 5px;
`

export const TabWrapper = styled.div`
    background: ${purpleLight};
    height: 50px;
    width 100%;
    border-radius: 3px;
    display: none;
    margin-bottom: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    padding-right: 9px;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    
    @media screen and ${device.tablet} {
        display: ${props => props.hidden ? 'none' : 'flex'};
    }
`

export const CardList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 60px;
`
export const HeaderAndListWrapper = styled.div`
    width: 590px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    
    @media screen and ${device.mobileL} {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
`


export default ExamScreen;
