import {skills} from '../constants/skills';
import {Skills} from '../constants/enums';

// Check if the user has the required feature flags
export function checkRequiredFeatureFlags(userFeatureFlags, requiredFeatureFlags) {
    if(Array.isArray(requiredFeatureFlags) && requiredFeatureFlags?.length > 0) {
        return requiredFeatureFlags.every(requiredFeatureFlag => userFeatureFlags?.includes(requiredFeatureFlag));
    }

    return userFeatureFlags?.includes(requiredFeatureFlags);
}

// Determine the skill based on a dynamic scale
export function determineSkill(totalScore, skillScale) {
    if (totalScore <= skillScale.beginner) {
        return skills[Skills.BEGINNER];
    } else if (totalScore <= skillScale.developing) {
        return skills[Skills.DEVELOPING];
    } else if (totalScore <= skillScale.mastered || totalScore >= skillScale.mastered) {
        return skills[Skills.MASTERED];
    }

    return {};
}