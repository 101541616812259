import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import logo from "../../resources/images/logo.svg"
import loginDecoration from "../../resources/images/loginDecoration.png"
import {Button, ButtonSpinner, Input} from "../../Theme";
import Api from "../../util/Api";
import showBasicPopup, {showConfirmationPopup} from "../../popups/showBasicPopup";
import {
    LoginButtonContainer,
    LoginContainer,
    LoginDecoration,
    LoginExplanation,
    LoginFormContainer,
    LoginFormInputContainer,
    LoginInputLabel,
    LoginLogo,
    LoginScreenRoot,
    LoginWrapper
} from "./LoginScreen";
import styled from "styled-components";
import backIcon from "../../resources/images/backWhite.svg";
import {useHistory} from "react-router-dom";
import {hideNativeNavigation} from "../../AppInterface";

function ResetPasswordScreen(props) {
    const {register, handleSubmit, reset} = useForm();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        hideNativeNavigation();
    });

    function onSubmit(data) {
        setIsLoading(true);
        Api.post("/resetPassword", {
            email: data.email,
        }, handleSuccess,null, (error) => handleFailure(error), (error) => handleFailure(error))

        function handleSuccess() {
            setIsLoading(false);
            showBasicPopup("Wachtwoordherstel aangevraagd", "Je ontvangt binnen een paar minuten een e-mail met daarin een link om je wachtwoord te wijzigen", "OK")
            reset();
        }

        function handleFailure(error) {
            setIsLoading(false);
            if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error === "Email does not exist") {
                    showBasicPopup("Wachtwoordherstel mislukt!", "Er bestaat geen account met dit e-mailadres", "OK")
                    return
                }

                if (error.response.data.error === "More members with the same email") {
                    showConfirmationPopup(
                        "Wachtwoordherstel mislukt!",
                        "Het is momenteel niet mogelijk om het wachtwoord opnieuw in te stellen. Neem contact op met de Opnij helpdesk.",
                        "Contact",
                        () => {
                            window.open('https://onderwijscentrumopnij.nl/helpdeskformulier-digitale-ef-planagenda/', '_blank');
                        },
                    )
                    return
                }
            }

            showBasicPopup("Wachtwoordherstel mislukt!", "Probeer het later nog eens.", "OK")
        }
    }

    return (
        <LoginScreenRoot>
            <LoginWrapper>
                <LoginContainer>
                    <LoginLogo src={logo} alt="Opnij-logo"/>
                    <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>

                        <LoginExplanation>Ben je je wachtwoord vergeten? Vul hier je e-mailadres in en je ontvangt een e-mail met daarin een link om je wachtwoord te wijzigen.</LoginExplanation>

                        <LoginFormInputContainer>
                            <LoginInputLabel htmlFor="email">E-mailadres</LoginInputLabel>
                            <Input id={"email"} type="email" {...register("email", {required: true})}/>
                        </LoginFormInputContainer>

                        <LoginButtonContainer>
                            <Button disabled={isLoading}>{isLoading ? <ButtonSpinner /> : 'Wachtwoord herstellen'}</Button>
                        </LoginButtonContainer>
                    </LoginFormContainer>

                    <LoginDecoration src={loginDecoration} alt=""/>

                    {/* If redirected, there is no history */}
                    {history?.length > 1
                        ? <BackButton src={backIcon} alt={"Pijltje naar links"} onClick={() => history.goBack()} />
                        : <BackButton src={backIcon} alt={"Pijltje naar links"} onClick={() => history.replace('/login')} />
                    }
                </LoginContainer>
            </LoginWrapper>
        </LoginScreenRoot>
    )
}

export default ResetPasswordScreen;

export const BackButton = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
`;