import styled from "styled-components";
import {Card, CardTitle, nunito, purple, purpleDark, purpleLightest, sourceSansPro} from "../Theme";
import React, {useEffect, useState} from "react";
import Api from "../util/Api";
import {device} from "../constants/breakpoints";
import getReadableTimeDifferenceSince from "../util/Methods";
import AppStorage, {StorageKey} from "../util/AppStorage";
import trashIconBlack from "../resources/images/ic-trashBlack.svg";
import {CommentCreationBar} from "../popups/showTaskPopup";
import {UserType} from "../constants/enums";
import {useUserType} from "../components/hooks/userTypeHooks";

function CommentsCard(props) {
    const [comments, setComments] = useState([]);
    useEffect(
        getCommentsFromApi,
        // eslint-disable-next-line
        []
    )
    const userType = useUserType()
    const userMayComment = userType === UserType.PUPIL || userType === UserType.MENTOR

    return <Card {...props} padding={"0"} mobilePadding={"0"}>
        <PaddedContent>
            <CardTitleContainer>
                <CardTitle>Opmerkingen</CardTitle>
                <NoComments $display={comments.length === 0}>
                    Er zijn nog geen opmerkingen...
                </NoComments>
            </CardTitleContainer>
            <CommentsList>
                {comments.map((c) => {
                    return <Comment key={c.id}>
                        <CommentTopBar>
                            <CommentTitle>{c.authorId === AppStorage.get(StorageKey.USER_ID) ? "Ik" : c.authorName}</CommentTitle>
                            <CommentSubtitle>{getReadableTimeDifferenceSince(c.createdDate)}</CommentSubtitle>
                            {c.authorId === AppStorage.get(StorageKey.USER_ID) &&
                            <CommentDeleteIcon onClick={() => {
                                deleteComment(c)
                            }} src={trashIconBlack}/>}
                        </CommentTopBar>
                        <CommentText>{c.text}</CommentText>
                    </Comment>
                })}
            </CommentsList>
        </PaddedContent>
        {userMayComment && <CommentCreationBar onComment={createComment}/>}
    </Card>

    function getCommentsFromApi() {
        Api.get((props.exam ? 'exams/' : 'homeworkItems/') + props.match.params.id + '/comments', (response) => {
            setComments(response.data)
        });
    }

    function deleteComment(c) {
        Api.delete((props.exam ? 'exams/' : 'homeworkItems/') + props.match.params.id + '/comments/' + c.id, onSuccess);

        function onSuccess() {
            setComments(comments.filter((c2) => {
                return c2.id !== c.id
            }))
        }
    }

    function createComment(text) {
        let commentJson = {
            text: text
        }
        if (props.exam) {
            commentJson.examId = props.exam.id
        } else {
            commentJson.homeworkItemId = props.homeworkItem.id
        }

        Api.put((props.exam ? 'exams/' : 'homeworkItems/') + props.match.params.id + '/comments', commentJson, (response) => {
            setComments([...comments, response.data])
        })
    }
}

export default CommentsCard;

export const PaddedContent = styled.div`
    padding: 38px 67px 38px 67px;
    
    @media screen and ${device.mobileL} {
        padding: 15px 20px 15px 20px;
    }
`;

export const CardTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-content: start;
    box-sizing: content-box;
    
    @media screen and ${device.tablet} {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 8px;
    } 
`;

export const NoComments = styled.div`
    color: ${purple};
    font-family: ${sourceSansPro};
    font-size: 16px;
    font-weight: 400;
    display: ${props => props.$display ? "flex" : "none"};
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: -24px;
    
     @media screen and ${device.mobileL} {
        margin-bottom: 24px;
    } 
`;


const Comment = styled.div`
    border: 1px solid #5659920F;
    box-shadow: 0px 2px 0px 0px #5659921A;
    border-radius: 3px;
    background-color: ${purpleLightest};
    padding: 10px;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    gap: 9px;
`;

const CommentTitle = styled.div`
    font-family: ${nunito};
    font-style: bold;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    flex-grow: 1;
    text-align: left;
    color: ${purpleDark};
`;

const CommentSubtitle = styled.div`
    font-family: ${sourceSansPro};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.5;
`;

const CommentDeleteIcon = styled.img`
    src: ${props => props.src};
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-left: 12px;
    opacity: 0.5;
`

const CommentText = styled.div`
    font-family: ${sourceSansPro};
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    white-space: pre-wrap
`

const CommentTopBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`;

const CommentsList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
`;
