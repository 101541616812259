import React, {useState} from "react";
import styled from "styled-components";
import {black, nunito, purple, sourceSansPro, white} from "../../../Theme";
import Api from "../../../util/Api";
import AppStorage, {StorageKey} from "../../../util/AppStorage";
import showBasicPopup from "../../../popups/showBasicPopup";

function FeedbackForm() {

    const [message, setMessage] = useState('');

    return (
        <FeedbackFormRoot>
            <Title>Heb jij feedback voor ons?</Title>
            <Text>
                We proberen de EF-planagenda steeds een beetje beter te maken.
                Heb je een idee of ondervind je problemen? Dan ontvangen we jouw feedback heel graag!
                Wij proberen binnen een werkdag te reageren.
                <br/>
                <br/>
                Groeten van het EF-planagenda team.
            </Text>

            <Label>Bericht</Label>
            <Textarea value={message} onChange={(e) => {
                setMessage(e.target.value)
            }}/>
            <Button onClick={() => sendFeedback()}>Versturen</Button>
        </FeedbackFormRoot>
    )

    function sendFeedback() {
        const data = {
            senderId: AppStorage.get(StorageKey.USER_ID),
            message: message
        }
        Api.put('feedback', data, onSuccess, null, onFailure, onFailure);
    }

    function onSuccess() {
        setMessage("")
        showBasicPopup("Bedankt voor de feedback!", null, "Ok")
    }

    function onFailure() {
        showBasicPopup("Mislukt!", "Het versturen van je feedback is helaas niet gelukt, probeer het nog een keer!", "Ok")
    }
}

export default FeedbackForm;

export const FeedbackFormRoot = styled.div`
    background: ${white};
    width: 100%;
    padding: 20px 15px;
    border: 1px solid rgba(86, 89, 146, 0.1);
    border-bottom: 2px solid rgba(86, 89, 146, 0.1);
    border-radius: 3px;
`;

export const Title = styled.div`
    font-family: ${nunito};
    font-size: 16px;
    font-weight: 700;
    color: ${black};
    margin-bottom: 5px;
`;

export const Text = styled.div`
    font-family: ${sourceSansPro};
    font-size: 16px;
    font-weight: 400;
    color: ${black};
    margin-bottom: 25px;
`;

export const Label = styled.div`
    font-family: ${nunito};
    font-size: 14px;
    font-weight: 400;
    color: ${black};
    opacity: 0.6;
`;

export const Textarea = styled.textarea`
    font-family: ${sourceSansPro};
    font-size: 14px;
    font-weight: 400;
    color: ${black};
    width: 100%;
    height: 123px;
    padding: 5px 10px;
    resize: none;
    border: 1px solid #E2E2EB;
    border-radius: 2px;
    margin-bottom: 25px;
`;

export const Button = styled.div`
    font-family: ${sourceSansPro};
    font-size: 15px;
    font-weight: 600;
    background: ${purple};
    color: ${white};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    cursor: pointer;
    height: 35px;
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    float: right;
`;