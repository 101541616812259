import styled from "styled-components";
import {nunito} from "../../Theme";
import filledNotification from "../../resources/images/ic-notification-filled.svg";
import outlineNotification from "../../resources/images/ic-notification-outline.svg";

function PupilCard(props) {
    const totalItems = props.totalExams + props.totalHomeworkItems;
    const totalScheduledItems = props.totalScheduledExams + props.totalScheduledHomeworkItems;
    const totalFinishedItems = props.totalFinishedExams + props.totalFinishedHomeworkItems;
    const ScheduledItemsProgress = (totalScheduledItems / totalItems) * 100;
    const FinishedItemsProgress = (totalFinishedItems / totalItems) * 100;

    const getScheduledBarColor = () => {
        if (totalItems > 0) {
            if (ScheduledItemsProgress <= 49) {
                return "#DD492F"
            } else if (ScheduledItemsProgress > 49 && ScheduledItemsProgress < 75) {
                return "#F28A03"
            } else {
                return "#7AB65C"
            }
        }
        return null;
    }

    const getFinishedBarColor = () => {
        if (totalItems > 0) {
            if (FinishedItemsProgress <= 49) {
                return "#DD492F"
            } else if (FinishedItemsProgress > 49 && FinishedItemsProgress < 75) {
                return "#F28A03"
            } else {
                return "#7AB65C"
            }
        }
        return null;
    }

    return (<PupilCardContainer onClick={props.onClick}>
            <CenteredDiv>
                <NameLabel>
                    <NameText>{props.firstname} {props.surname}</NameText>
                    <img src={props.hasUnreadNotifications ? filledNotification : outlineNotification}
                         alt="Notification Bell"/>
                </NameLabel>

                <ItemContainer>
                    <ItemButton>Toetsen ({props.totalExams})</ItemButton>
                    <ItemButton>Huiswerk ({props.totalHomeworkItems})</ItemButton>
                </ItemContainer>

                <PlannedContainer>
                    <PlannedContainerText>
                        <GrayText>Ingepland</GrayText>
                        <GrayText>{(props.totalScheduledExams + props.totalScheduledHomeworkItems)}&nbsp;/&nbsp;{(props.totalExams + props.totalHomeworkItems)}</GrayText>
                    </PlannedContainerText>

                    <PlannedContainerGrayBar>
                        <ScheduledBar $progress={ScheduledItemsProgress} $barColor={getScheduledBarColor} />
                    </PlannedContainerGrayBar>
                </PlannedContainer>

                <PlannedContainer>
                    <PlannedContainerText>
                        <GrayText>Afgerond</GrayText>
                        <GrayText>{(props.totalFinishedExams + props.totalFinishedHomeworkItems)}&nbsp;/&nbsp;{(props.totalExams + props.totalHomeworkItems)}</GrayText>
                    </PlannedContainerText>
                    <PlannedContainerGrayBar>
                        <FinishedBar $progess={FinishedItemsProgress} $barColor={getFinishedBarColor} />
                    </PlannedContainerGrayBar>
                </PlannedContainer>
            </CenteredDiv>
        </PupilCardContainer>)
}

const PupilCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 30px;
  border: 1px solid rgba(86, 89, 146, 0.1);
  box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
  border-radius: 3px;
  cursor: pointer;
  height: 212px;
  width: 100%;
  min-width: 0;
  min-height: 0;
`;

const CenteredDiv = styled.div`
  margin: auto 0;
`;

const NameLabel = styled.div`
  font-family: ${nunito};
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NameText = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  gap: 5px;
`;

const ItemButton = styled.div`
  text-align: center;
  font-family: ${nunito};
  font-size: 12px;
  margin: auto;
  background: rgba(86, 89, 146, 0.1);
  box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.2);
  width: 50%;
  border-radius: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const PlannedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  gap: 5px;
`;

const PlannedContainerText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GrayText = styled.div`
  font-family: ${nunito};
  color: black;
  opacity: 0.5;
`;

const PlannedContainerGrayBar = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 100px;
  background: rgba(86, 89, 146, 0.1);
`;

const ScheduledBar = styled.div`
  width: ${({$progress}) => $progress && `${$progress}%`};
  height: 5px;
  border-radius: 100px;
  background: ${({$barColor}) => $barColor};
`;

const FinishedBar = styled.div`
  width: ${({$progress}) => $progress && `${$progress}%`};
  height: 5px;
  border-radius: 100px;
  background: ${({$barColor}) => $barColor};
`;

export default PupilCard;