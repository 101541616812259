import Planning from "../resources/executiveFunctions/plannen.jpg";
import TimeManagement from "../resources/executiveFunctions/tijd_verdelen.jpg";
import Remember from "../resources/executiveFunctions/onthouden.png";
import Organize from "../resources/executiveFunctions/organiseren.png";
import SelfAwareness from "../resources/executiveFunctions/ken_jezelf.jpg";
import ThinkBeforeActing from "../resources/executiveFunctions/denken_dan_doen.jpg";
import EmotionRegulation from "../resources/executiveFunctions/emoties_controleren.png";
import Focus from "../resources/executiveFunctions/aandacht_erbij.jpg";
import Initiation from "../resources/executiveFunctions/starten.png";
import GoalOriented from "../resources/executiveFunctions/doelen_halen.jpg";
import Flexibility from "../resources/executiveFunctions/flexibel_zijn.jpg";
import BrainImageLogo from "../resources/executiveFunctions/logo_ef.jpg"
import {ExecutiveFunctions} from "../constants/enums";
import styled from "styled-components";

export function BrainImage({className, executiveFunction}) {
    return (
        <StyledBrainImage
            className={className}
            src={getBrainImageForEF(executiveFunction)}
            alt=""
        />
    )

    function getBrainImageForEF(executiveFunction) {
        if (!executiveFunction) return BrainImageLogo;
        executiveFunction = executiveFunction.toString().toLowerCase();

        switch (executiveFunction) {
            case ExecutiveFunctions.PLANNING:
                return Planning;
            case ExecutiveFunctions.TIME_MANAGEMENT:
                return TimeManagement;
            case ExecutiveFunctions.REMEMBER:
                return Remember;
            case ExecutiveFunctions.ORGANIZE:
                return Organize;
            case ExecutiveFunctions.SELF_AWARENESS:
                return SelfAwareness;
            case ExecutiveFunctions.THINK_BEFORE_ACTING:
                return ThinkBeforeActing;
            case ExecutiveFunctions.EMOTION_REGULATION:
                return EmotionRegulation;
            case ExecutiveFunctions.FOCUS:
                return Focus;
            case ExecutiveFunctions.INITIATION:
                return Initiation;
            case ExecutiveFunctions.GOAL_ORIENTED:
                return GoalOriented;
            case ExecutiveFunctions.FLEXIBILITY:
                return Flexibility;
            default:
                return BrainImageLogo
        }
    }
}

const StyledBrainImage = styled.img`
    width: 60px;
    height: 60px;
    display: block;
    object-fit: contain;
`;