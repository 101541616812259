import Swal from "sweetalert2";
import React from "react";
import withReactContent from "sweetalert2-react-content";
import closeIcon from "../resources/images/ic-closeWhite.svg";
import styled from "styled-components";
import "./opnijCustomPopup.scss";

export const ShowImagePopup = withReactContent(Swal)

function showImagePopup(src) {
    ShowImagePopup.fire({
        html: (<PopupContent src={src} onClick={() => ShowImagePopup.close()}/>),
        showCancelButton: false,
        showConfirmButton: false,
        width: "90%",
        customClass: {
            container: "swal2-container-custom",
            htmlContainer: "swal2-html-container-custom",
            popup: "swal2-popup-custom"
        },
        heightAuto: false
    });
}

function PopupContent(props) {
    return (
        <PopupContentRoot>
            <BackgroundImage src={props.src}/>
            <CloseButton src={closeIcon} onClick={props.onClick}/>
        </PopupContentRoot>
    );
}

const PopupContentRoot = styled.div`
    margin: 0;
    padding: 0;
`;

const BackgroundImage = styled.img`
    border-radius: 3px;
    width: 100%;
`;

const CloseButton = styled.img`
   width: 43px;
   position: absolute;
   top: -55px;
   right: -13px;
   cursor: pointer;
`;


export default showImagePopup;