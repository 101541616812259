function getReadableTimeDifferenceSince(msTimestamp) {
    const diffInMs = new Date() - new Date(msTimestamp)
    const diffInS = Math.max(0, Math.floor(diffInMs / 1000));
    if (diffInS < 59) {
        return diffInS + ' sec'
    }
    const diffInMin = Math.max(0, Math.floor(diffInS / 60));
    if (diffInMin < 59) {
        return diffInMin + ' min'
    }
    const diffInHours = Math.max(0, Math.floor(diffInMin / 60));
    if (diffInHours < 24) {
        return diffInHours + ' uur'
    }
    const diffInDays = Math.max(0, Math.floor(diffInHours / 24));
    if (diffInDays === 1) {
        return '1 dag'
    }
    return diffInDays + ' dagen'
}

export default getReadableTimeDifferenceSince;