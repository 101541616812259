import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import logo from "../../resources/images/logo.svg"
import loginDecoration from "../../resources/images/loginDecoration.png"
import {Button, Input} from "../../Theme";
import Api from "../../util/Api";
import showBasicPopup, {showConfirmationPopup} from "../../popups/showBasicPopup";
import {
    LoginButtonContainer,
    LoginContainer,
    LoginDecoration,
    LoginExplanation,
    LoginFormContainer,
    LoginFormInputContainer,
    LoginInputLabel,
    LoginLogo,
    LoginScreenRoot,
    LoginWrapper
} from "./LoginScreen";
import {useHistory} from "react-router-dom";
import {hideNativeNavigation, setHistoryRef} from "../../AppInterface";
import {loginAndContinueToNextScreen} from "../../menu/SideMenu";

function SetPasswordScreen(props) {
    const queryParams = new URLSearchParams(window.location.search)
    const history = useHistory()
    const [personId,] = useState(queryParams.has('m') ? queryParams.get('m') : null);
    const [token,] = useState(queryParams.has('t') ? queryParams.get('t') : null);
    const {register, handleSubmit} = useForm();

    useEffect(() => {
        hideNativeNavigation();

        let changedUrl = false;
        if (queryParams.has('m')) {
            queryParams.delete('m');
            changedUrl = true;
        }
        if (queryParams.has('t')) {
            queryParams.delete('t');
            changedUrl = true;
        }
        if (changedUrl) {
            history.replace({
                search: queryParams.toString(),
            })
        }

        setHistoryRef(history)
        // eslint-disable-next-line
    }, [])

    if (!personId || !token) {
        history.replace("/login")
    }

    function onSubmit(data) {
        if(!data.password || data.password.length < 8) {
            showBasicPopup("Wachtwoord aanpassen mislukt!", "Zorg ervoor dat het wachtwoord uit minimaal 8 tekens bestaat", "OK")
            return;
        }

        if(data.password !== data.passwordverify) {
            showBasicPopup("Wachtwoord aanpassen mislukt!", "Zorg ervoor dat de wachtwoorden hetzelfde zijn", "OK")
            return;
        }

        Api.post("/changePassword", {
            personId: personId,
            autoLoginHash: token,
            newPassword: data.password
        }, (response) => {
            loginAndContinueToNextScreen(response.data, history)
        }, null, null, (error) => {
            if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error === "You've already used that password in the past, please choose a new password") {
                    showBasicPopup("Wachtwoord aanpassen mislukt!", "Je hebt dit wachtwoord al eens gebruikt. Kies een ander wachtwoord.", "OK")
                    return
                }

                if(error.response.data.error === "Incorrect AutoLoginHash") {
                    showConfirmationPopup(
                        "Wachtwoord aanpassen mislukt!",
                        "Deze \"wachtwoord vergeten\" link is niet meer geldig. Zorg ervoor dat je de link uit de meest recente e-mail gebruikt, of vraag een nieuwe aan.",
                        "OK",
                        () => {
                            history.replace('/herstel-wachtwoord')
                        },
                    )
                    return
                }
            }

            showBasicPopup("Wachtwoord aanpassen mislukt!", "Probeer het later nog eens.", "OK")
        })
    }

    return (
        <LoginScreenRoot>
            <LoginWrapper>
                <LoginContainer>

                    <LoginLogo src={logo} alt="Opnij-logo"/>
                    <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>

                        <LoginExplanation>Maak een nieuw wachtwoord aan door twee keer hetzelfde wachtwoord van minimaal 8 tekens in te vullen.</LoginExplanation>

                        <LoginFormInputContainer>
                            <LoginInputLabel htmlFor="password">Wachtwoord</LoginInputLabel>
                            <Input id={"password"} type="password" {...register("password", {required: true})}/>
                        </LoginFormInputContainer>

                        <LoginFormInputContainer>
                            <LoginInputLabel htmlFor="passwordverify">Herhaal wachtwoord</LoginInputLabel>
                            <Input id={"passwordverify"}
                                   type="password" {...register("passwordverify", {required: true})}/>
                        </LoginFormInputContainer>

                        <LoginButtonContainer>
                            <Button>Wachtwoord instellen</Button>
                        </LoginButtonContainer>
                    </LoginFormContainer>

                    <LoginDecoration src={loginDecoration} alt=""/>
                </LoginContainer>
            </LoginWrapper>
        </LoginScreenRoot>
    )
}

export default SetPasswordScreen;
