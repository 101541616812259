import React, {useEffect} from "react";
import styled from "styled-components";
import {black} from "../../Theme";
import {StorageKey, useAppStorageState} from "../../util/AppStorage";
import {useGlobalState} from "../../util/GlobalState";
import showOnboardingPopup from "../../popups/showOnboardingPopup";
import {useHistory} from "react-router-dom";
import {setHistoryRef} from "../../AppInterface";
import {logoutAndGoToLogin} from "../../menu/SideMenu";
import {useQuery} from "../../App";
import {useIsUserAuthenticated} from "../../components/hooks/useIsUserAuthenticated";

function BaseScreen(props) {
    const [overlayActive, setOverlayActive] = useGlobalState('overlayActive', false);
    const [isNative, setNative] = useGlobalState('isNative',false)
    const [isMentorView] = useGlobalState('isMentorView',false)

    const [finishedOnboarding] = useAppStorageState(StorageKey.FINISHED_ONBOARDING)
    const history = useHistory();
    const query = useQuery();

    const isUserAuthenticated = useIsUserAuthenticated()

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            setOverlayActive(false);
        });

        setHistoryRef(history)

        return () => {
            unlisten();
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(query.get("app")) {
            setNative(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!finishedOnboarding) {
            showOnboardingPopup()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isUserAuthenticated) {
            logoutAndGoToLogin(history)
        }
        // eslint-disable-next-line
    }, [isUserAuthenticated])

    // Return nothing if not authenticated, this prevents loading the schedule component even though the user is not authenticated
    return (
        <ScreenWrapper
            isNative={isNative}
            isMentorView={isMentorView}
            onClick={(e) => {
                setOverlayActive(false)
            }}
        >
            <ContentWrapper>
                {props.content}
            </ContentWrapper>

            {overlayActive && <PopupOverlay onClick={() => setOverlayActive(false)}/>}
        </ScreenWrapper>
    );
}

const ScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
`;//padding bottom and top are to compensate for the top and bottom banner

const ContentWrapper = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background: #F9F8FF;
`;

const PopupOverlay = styled.div`
    background: ${black};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: 100;
`;

export default BaseScreen;