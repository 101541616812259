import Swal from "sweetalert2";
import React from "react";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import "./opnijCustomPopup.scss";
import {Button, red, sourceSansPro, white} from "../Theme";
import closeIcon from "../resources/images/ic-closeWhite.svg";

export const TasksFinishedPopup = withReactContent(Swal)

function showTasksFinishedPopup(text, imageUrl) {
    TasksFinishedPopup.fire({
        html: (<PopupContent text={text} imageUrl={imageUrl} onClick={() => TasksFinishedPopup.close()}/>),
        showCancelButton: false,
        showConfirmButton: false,
        customClass: {
            container: "swal2-container-custom",
            htmlContainer: "swal2-html-container-custom",
            popup: "swal2-popup-custom"
        },
        heightAuto: false
    });
}

function PopupContent(props) {
    return (
        <PopupContentRoot>
            <ImageRoot>
                <BackgroundImage
                    src={props.imageUrl}/>
                <CloseButton onClick={props.onClick} src={closeIcon}/>
            </ImageRoot>
            <TextRoot>
                <Title>
                    {props.text}
                </Title>
                <StyledButton onClick={props.onClick}>
                    Yes!
                </StyledButton>
            </TextRoot>
        </PopupContentRoot>
    );
}

const StyledButton = styled(Button)`
    max-width: 114px;
`

const TextRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    gap: 35px;
`

const ImageRoot = styled.div`
    display: relative;
`;

const Title = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: ${red};
`;

const PopupContentRoot = styled.div`
    max-width: 416px;
    margin: auto;
    background: ${white};
    border-radius: 3px;
`;

const BackgroundImage = styled.img`
    height: 225px;
    width: 100%;
    object-fit: cover;
    border-radius: 3px 3px 0px 0px;
`;

const CloseButton = styled.img`
   max-width: 24px;
   max-height: 24px;
   position: absolute;
   right: 45px;
   cursor: pointer;
`;


export default showTasksFinishedPopup;