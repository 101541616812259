import React from "react";
import styled from "styled-components";
import {nunito, purple, red, white} from "../../Theme";
import chevronRight from "../../resources/images/ic-chevronRight.png"
import {useHistory} from "react-router-dom";
import {getFormattedDateFromTimestamp} from "./ExamOverviewScreen";

function ExamOverviewCard(props) {
    const history = useHistory();

    return (
        <ExamCardRoot>
            <ExamCardTitle>{!!props.title ? props.title : "Toets"}</ExamCardTitle>

            <ExamCardParagraph>
                <ExamCardParagraphTitle>
                    Hoofdstukken:
                </ExamCardParagraphTitle>
                {props.exam.chapters}
            </ExamCardParagraph>

            <ExamCardParagraph>
                <ExamCardParagraphTitle>
                    Paragrafen:
                </ExamCardParagraphTitle>
                {props.exam.paragraphs}
            </ExamCardParagraph>

            <ExamCardParagraph>
                <ExamCardParagraphTitle>
                    Datum:
                </ExamCardParagraphTitle>
                {!!props.exam.date ? getFormattedDateFromTimestamp(props.exam.date) : ''}
            </ExamCardParagraph>

            <ExamCardViewScheduleButton onClick={() => history.push("/toets/" + props.exam.id, {exam: props.exam})}>
                <ExamCardViewScheduleButtonText>Bekijk planning</ExamCardViewScheduleButtonText>
                <ExamCardViewScheduleButtonChevron src={chevronRight} alt="Pijltje naar rechts"/>
            </ExamCardViewScheduleButton>
        </ExamCardRoot>
    );
}

export const ExamCardRoot = styled.div`
    min-height: 193px;
    background: ${white};
    padding: 20px 20px 0 20px;
    border-radius: 3px;
    border: solid rgba(86, 89, 146, 0.1);
    border-width: 1px 1px 3px 1px ;
    display: flex;
    flex-direction: column;
    p:last-of-type {
        margin-bottom: 20px;
    }
`;

export const ExamCardTitle = styled.h2`
    font-family: ${nunito};
    font-size: 1.125em;
    font-weight: 700;
    color: ${red};
    margin: 0 0 13px 0;
    padding: 0;
`;

export const ExamCardParagraph = styled.p`
    font-family: ${nunito};
    font-weight: 400; 
    margin: 0 0 5px 0;
`;

export const ExamCardParagraphTitle = styled.span`
    opacity: 0.6;
    font-family: ${nunito};
    margin-right: 5px;
`;

export const ExamCardViewScheduleButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    border-top: 1px solid rgba(86, 89, 146, 0.1);
    padding: 10px 0 10px 0;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
`;

export const ExamCardViewScheduleButtonText = styled.span`
    font-family: ${nunito};
    font-weight: 700;
    color: ${purple};
`;

export const ExamCardViewScheduleButtonChevron = styled.img`
    justify-self: flex-end;
`;

export default ExamOverviewCard;