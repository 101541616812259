import React from "react";
import styled from "styled-components";
import {green, nunito, red} from "../Theme";

function ActionButton(props) {
    return (
        <ActionButtonRoot visiblity={props.visibility} isNegative={props.isNegative} onClick={props.onClick} $disabled={props.disabled}>
            <ActionButtonTitle isNegative={props.isNegative}>{props.text}</ActionButtonTitle>
            <ActionButtonImage src={props.src}/>
        </ActionButtonRoot>
    );
}

const ActionButtonTitle = styled.div`
    color: ${props => props.isNegative ? red : green};
    font-family: ${nunito};
    font-size: 14px;
    font-style: normal;
    text-align: bottom;
    font-weight: 700;
`;

const ActionButtonImage = styled.img`
    display: block;
    height: 20px;
    width: 20px;
    src: ${props => props.src};
`

const ActionButtonRoot = styled.div`
    visibility: ${props => props.visibility || 'default'};
    cursor: pointer;
    color: ${props => props.isNegative ? red : green};
    display: flex;
    flex-direction: ${props => props.isNegative ? 'row-reverse' : 'row'};
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 10px;
    ${({$disabled}) => $disabled && 'pointer-events: none;'};
`;

export default ActionButton;