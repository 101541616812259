import React from "react";
import styled from "styled-components";
import {blueLight, nunito, purpleLight, sourceSansPro, yellow} from "../../../Theme";
import {useHistory} from "react-router-dom";

function ScheduleMonthGridItemView(props) {
    const history = useHistory();
    const {
        dayNumber, // Moment instance to manipulate
        isCurrentDay,
        isOtherMonth,
        items,
    } = props

    function handleItemClicked(item) {
        if (item.isImportant) {
            if (item.isHomework === true && item.isExam === false) {
                history.push("/huiswerk/" + item.id);
            } else if (item.isHomework === false && item.isExam === true) {
                history.push("/toets/" + item.id);
            }
        } else {
            props.onTitleClick(item)
        }
    }

    const itemsHtml = [];
    for (let i = 0; i < Math.min(5, items.length); i++) {
        const item = items[i];
        if(i === 4 && items.length > 5) {
            itemsHtml.push(
                <ItemContainer key={item.id} cursor={"default"}>
                    <MoreLabel>{items.length - 4} meer...</MoreLabel>
                </ItemContainer>
            )
        } else {
            itemsHtml.push(
                <ItemContainer key={item.id}>
                    <ItemType isExam={item.isExam} isHomework={item.isHomework} isSpareTime={item.isSpareTime}
                              isFinished={item.isFinished}/>
                    <ItemTitle
                        isFinished={item.isFinished}
                        isImportant={item.isImportant}
                        onClick={() => handleItemClicked(item)}
                    >
                        {getItemTitle(item)}
                    </ItemTitle>
                </ItemContainer>
            )
        }
    }

    function getItemTitle(item) {
        if (item.isImportant && item.isHomework) {
            return `Huiswerk: ${item.subject}`
        } else if (item.isImportant && item.isExam) {
            return `Toets: ${item.subject}`
        } else if (item.isSpareTime) {
            return `Vrije tijd: ${item.title}`
        } else {
            return `Taak: ${item.subject}`
        }
    }

    return <Container isOtherMonth={isOtherMonth}>
        {isCurrentDay && <CurrentDayNumber>{dayNumber}</CurrentDayNumber>}
        {!isCurrentDay && <DayNumber>{dayNumber}</DayNumber>}
        {itemsHtml}
    </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;
  opacity: ${props => props.isOtherMonth ? 0.3 : 1.0};
`;

const DayNumber = styled.h4`
  display: flex;
  height: 24px;
  padding: 0 0 3px 0;
  margin: 0;
  font-family: ${nunito};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  flex: 0 0 auto;
`;

const CurrentDayNumber = styled.h4`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 0 3px -3px;
  font-family: ${nunito};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: purple;
`;

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${props => props.cursor ? props.cursor : "pointer"};

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const ItemType = styled.div`
  width: 12px;
  height: 12px;
  background: ${props => props.isSpareTime ? blueLight : (props.isExam ? yellow : purpleLight)};
  border: 1px solid rgba(94, 65, 135, 0.15);
  border-radius: 3px;
  opacity: ${props => props.isFinished ? "0.5" : "1"};
  margin-right: 7px;
  flex: 0 0 auto;
`;

const ItemTitle = styled.div`
  display: inline-block;
  width: 100%;
  font-family: ${sourceSansPro};
  font-style: normal;
  font-size: 16px;
  font-weight: ${props => props.isImportant ? 700 : 400};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 0px;
  opacity: ${props => props.isFinished ? "0.5" : "1"};
  text-decoration: ${props => props.isFinished ? "line-through" : "none"};
`;

const MoreLabel = styled.div`
  display: inline-block;
  width: 100%;
  font-family: ${nunito};
  font-style: normal;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 0px;
  padding-top: 4px;
  padding-left: 16px;
  opacity: 0.5;
`;

export default ScheduleMonthGridItemView;