import React, {useState} from "react";
import styled from "styled-components";
import {nunito, purple, red, white} from "../../Theme";
import {device} from "../../constants/breakpoints";

function TabBar(props) {

    const [selectedTab, setSelectedTab] = useState(props.tabs[0]);

    return (
        <TabBarRoot>
            {props.tabs.map(tab => {
                return <TabBarItem
                    key={tab}
                    isSelected={selectedTab === tab}
                    onClick={() => onTabClick(tab)}>
                    {tab}
                </TabBarItem>
            })}
        </TabBarRoot>
    )

    function onTabClick(tab) {
        setSelectedTab(tab)
        props.onSelectTab(tab)
    }
}

export class Tab {
    constructor(label) {
        this.label = label
    }
}

const TabBarRoot = styled.div`
    background: rgba(94, 65, 135, 0.07);
    width: 100%;
    height: 50px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const TabBarItem = styled.div`
    background: ${props => props.isSelected ? red : "unset"};
    color: ${props => props.isSelected ? white : purple};
    font-family: ${nunito};
    font-size: 16px;
    font-weight: 700;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    
    @media screen and ${device.mobileL} {
       font-size: 13px;
    }
`;

export default TabBar;