import {useEffect} from 'react';
import AppStorage, {StorageKey, useAppStorageState} from "../../util/AppStorage";
import {useGlobalState} from "../../util/GlobalState";

export function useIsUserAuthenticated() {
    const [userIsAuthenticated, setUserIsAuthenticated] = useGlobalState("isUserAuthenticated", AppStorage.get(StorageKey.USER_ID) && AppStorage.get(StorageKey.API_ACCESS_TOKEN) && AppStorage.get(StorageKey.USER_TYPE))
    const [userID,] = useAppStorageState(StorageKey.USER_ID);
    const [userType,] = useAppStorageState(StorageKey.USER_TYPE);
    const [accessToken,] = useAppStorageState(StorageKey.API_ACCESS_TOKEN);

    useEffect(() => {
            if (userID && userType && accessToken) {
                setUserIsAuthenticated(true)
            } else {
                setUserIsAuthenticated(false)
            }
        // eslint-disable-next-line
        }, [userID, accessToken, userType]
    );

    return userIsAuthenticated;
}