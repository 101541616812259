import Swal from "sweetalert2";
import React from "react";
import withReactContent from "sweetalert2-react-content";
import closeIcon from "../resources/images/close.svg";
import styled from "styled-components";

export const ShowExecutiveFunctionProgressPopup = withReactContent(Swal)

function showExecutiveFunctionProgressPopup(content) {
    ShowExecutiveFunctionProgressPopup.fire({
        html: (<PopupContent content={content}/>),
        showCancelButton: false,
        showConfirmButton: false,
        width: "395px",
        heightAuto: false,
    });
}

function PopupContent(props) {

    return (
        <PopupContentRoot>
            <CloseButton src={closeIcon} alt={"Sluiten"} onClick={() => ShowExecutiveFunctionProgressPopup.close()}/>
            {props.content}
        </PopupContentRoot>
    );
}

const PopupContentRoot = styled.div`
    
`;

const CloseButton = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
`;


export default showExecutiveFunctionProgressPopup;