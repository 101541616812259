import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import moment from "moment";
import plusIcon from "../../resources/images/ic-plus.svg";
import tasksCompletedGif from "../../resources/images/allTasksCompleted.gif";
import unfinishedTasksReminderGif from "../../resources/images/unfinishedTasksReminder.gif";
import ScheduleDropdownButton from "./ScheduleDropdownButton";
import {DateNavigatorMode, Day as Days, Day, DropDownType} from "../../constants/enums";
import {
    black,
    capriola,
    fjallaOne,
    lemon,
    lobster,
    markoOne,
    nunito,
    purple,
    purpleDark,
    purpleLightest,
    red,
    sourceSansPro,
    violet,
    white,
} from "../../Theme";
import {device} from "../../constants/breakpoints";
import showTaskPopup from "../../popups/showTaskPopup";
import Api from "../../util/Api";
import TaskCard from "../TaskCard";
import _debounce from 'lodash/debounce';
import folderIllustration from "../../resources/images/folder.svg";
import AppStorage, {StorageKey, useAppStorageState} from "../../util/AppStorage";
import showTasksFinishedPopup from "../../popups/showTasksFinishedPopup";
import {isInReadOnlyMode, useQuery} from "../../App";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useGlobalState} from "../../util/GlobalState";
import TaskHelper from "../../util/TaskHelper";
import showWhatsNewPopup from "../../popups/whatsNewPopup";
import {useHistory} from "react-router-dom";
import DateNavigator from "../../components/date-navigator/DateNavigator";
import ScheduleMonthView from "./components/ScheduleMonthView";
import {showNativeNavigation} from "../../AppInterface";
import {FeaturePreview} from '../../components/FeaturePreview/FeaturePreview';
import {useAccessContext} from '../../contexts/AccessContext';
import {checkRequiredFeatureFlags} from '../../util/Helpers';
import {FeatureFlags} from '../../constants/featureFlags';

function ScheduleScreen() {
    const {featureFlags} = useAccessContext();
    const canViewScheduleScreen = checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE);

    if (!canViewScheduleScreen) {
        return <FeaturePreview feature="schedule" />
    }

    return (
        <ScheduleScreenComponent />
    );
}

function ScheduleScreenComponent() {
    const history = useHistory();

    const TASK_COLUMN_AMOUNT = 7;

    // date related states
    const [currentMoment, setCurrentMoment] = useState(moment());
    const [currentDateNavigatorMode, setCurrentDateNavigatorMode] = useState(DateNavigatorMode.WEEK);

    // data related states
    const [tasksMonday, setTasksMonday] = useState([]);
    const [tasksTuesday, setTasksTuesday] = useState([]);
    const [tasksWednesday, setTasksWednesday] = useState([]);
    const [tasksThursday, setTasksThursday] = useState([]);
    const [tasksFriday, setTasksFriday] = useState([]);
    const [tasksSaturday, setTasksSaturday] = useState([]);
    const [tasksSunday, setTasksSunday] = useState([]);

    const [taskIdToOpen, setTaskIdToOpen] = useState(null);

    const [weekObject, setWeekObject] = useState(null);
    const [weekGoal, setWeekGoal] = useState(null);
    const [currentTasks, setCurrentTasks] = useState([]);
    const [currentExams, setCurrentExams] = useState([]);
    const [currentHomeworkItems, setCurrentHomeworkItems] = useState([]);

    const [isDataFetched, setDataFetched] = useState(false);
    const [noTaskViewVisibility, setNoTaskViewVisibility] = useState(false);
    const [finishedOnboarding] = useAppStorageState(StorageKey.FINISHED_ONBOARDING)
    const taskReminderShouldBeShown = currentTasks.length !== 0 && shouldLastTaskReminderBeShown()

    // Notification side menu
    const [whatsNewObjects, setWhatsNewObjects] = useState([]);
    const [overlayActive, setOverlayActive] = useGlobalState('overlayActive', false);
    const [isMentorView] = useGlobalState('isMentorView', false);
    const [notifications, setNotifications] = useState([])

    const query = useQuery();

    // Set Week and Year states
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        showNativeNavigation();
        getWhatsNew(signal);
        processUrlQueryParams();

        return () => controller.abort();
        // eslint-disable-next-line
    }, []);

    // Fetch new data on currentWeek change
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setNoTaskViewVisibility(false);
        if (currentMoment){
            resetDataStates();
            fetchAllData(signal);
        }

        return () => controller.abort();
        // eslint-disable-next-line
    }, [currentMoment])

    // Get Weekly tips
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setWeekGoal(null)
        if (weekObject) {
            getWeekGoal(signal);
        }
        return () => controller.abort();
        // eslint-disable-next-line
    }, [weekObject]);

    // Set state for each of the columns
    useEffect(() => {
        setTaskStateForEachDay()
        // eslint-disable-next-line
    }, [currentTasks])

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getNotificationsFromApi(signal)
        // eslint-disable-next-line
    }, [])

    if (taskIdToOpen) {
        const taskToOpen = currentTasks.find(t => {
            return t.id === taskIdToOpen
        })
        if (taskToOpen) {
            Api.post('tasks/' + taskToOpen.id + '/read', null, () => {
                taskToOpen.notificationCount = 0
                setCurrentTasks([...(currentTasks.filter((t) => {
                    return t.id !== taskToOpen.id
                })), taskToOpen])
            })
            showTaskPopup(taskToOpen, "temp", (task) => {
                    editTask()
                }, () => {
                    onDeleteTask(taskToOpen)
                }, (task) => {
                    editTask()
                    navigateToTask(task.id, task.date)
                },
                null,
                getAllTasksForCurrentWeek)
            setTaskIdToOpen(null)
        }
    }
    const content = (
        <ScheduleRoot>

            <DateNavigator
                momentObject={currentMoment}
                whatsNewObjects={whatsNewObjects}
                navigateToComment={navigateToComment}
                notifications={notifications}
                onAfterNavigation={(moment) => {
                    setCurrentMoment(moment)
                }}
                defaultNavigatorMode={currentDateNavigatorMode}
                onModeSwitch={(dateNavigatorMode) => {
                    setCurrentDateNavigatorMode(dateNavigatorMode)
                }}
            />

            {finishedOnboarding && !taskReminderShouldBeShown && whatsNewObjects.length > 0 && !whatsNewObjects[0].isRead &&
                showWhatsNewPopup(whatsNewObjects[0], () => setWhatsNewIsRead(), () => getNotificationsFromApi())
            }

            {currentDateNavigatorMode === DateNavigatorMode.WEEK &&
                <ScheduleContentWrapper>
                    <ScheduleContentPadding>
                        {isDataFetched &&
                        <ScheduleTopRow>
                            {[...Array(TASK_COLUMN_AMOUNT)].map((e, columnIndex) => {
                                switch (columnIndex) {
                                    case 0:
                                        return <WeeklyHint key={columnIndex}>
                                            <WeeklyHintTitle>Tip van de week</WeeklyHintTitle>
                                            <WeeklyHintText
                                                dangerouslySetInnerHTML={{__html: weekObject ? weekObject.tip : null}}/>
                                        </WeeklyHint>
                                    case 1:
                                        return <WeeklyGoal key={columnIndex}>
                                            <WeeklyGoalTitle>Weekdoel</WeeklyGoalTitle>
                                            <WeeklyGoalText
                                                key={weekGoal ? weekGoal.id : 'weekGoal'}
                                                maxLength="75"
                                                placeholder={"Schrijf hier welk doel je deze week wilt behalen..."}
                                                disabled={weekObject == null || isInReadOnlyMode()}
                                                onChange={_debounce((e) => updateWeekGoal(e, weekObject.id), 1000)}
                                                defaultValue={weekGoal ? weekGoal.text : ""}
                                            />
                                        </WeeklyGoal>
                                    default:
                                        return <ExamsAndHomeworkWrapper key={columnIndex}>
                                            <ScheduleDropdownButton
                                                primary
                                                type={DropDownType.EXAM}
                                                items={filterHomeworkOrExamItemsOnDay(currentExams, columnIndex)}
                                                date={getDateForDayOfWeek(columnIndex, true)}
                                            />
                                            <ScheduleDropdownButton
                                                type={DropDownType.HOMEWORK}
                                                items={currentHomeworkItems.filter(item => (
                                                    columnIndex === (TASK_COLUMN_AMOUNT - 1)
                                                        ? item.date >= getDateForDayOfWeek(columnIndex)
                                                        : item.date >= getDateForDayOfWeek(columnIndex) && item.date < getDateForDayOfWeek(columnIndex + 1)
                                                ))}
                                                date={getDateForDayOfWeek(columnIndex, true)}/>
                                        </ExamsAndHomeworkWrapper>
                                }
                            })}
                        </ScheduleTopRow>
                        }

                        {isDataFetched &&
                        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            <TasksRow>
                                {[...Array(TASK_COLUMN_AMOUNT)].map((e, columnIndex) => {
                                    return <TasksColumn key={columnIndex}>
                                        <TasksColumnDate>
                                            <DateText>{getFormattedDate(getDateForDayOfWeek(columnIndex))}</DateText>
                                            {!isInReadOnlyMode() && <AddTaskIcon src={plusIcon} alt={"plus icoon"}
                                                                                 onClick={() => handleAddTaskClick(columnIndex)}/>}
                                        </TasksColumnDate>
                                        <Droppable droppableId={"column-" + columnIndex}>
                                            {provided => (
                                                <TaskColumnDroppableArea
                                                    ref={provided.innerRef} {...provided.droppableProps}>
                                                    {
                                                        getTaskStateForDay(columnIndex).map((task, index) => {
                                                            return <Draggable draggableId={task.id} index={index}
                                                                              key={task.id}
                                                                              isDragDisabled={isInReadOnlyMode() || overlayActive}>
                                                                {provided => (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        <TaskCard
                                                                            id={task.id}
                                                                            title={task.title}
                                                                            examId={task.examId}
                                                                            homeworkItemId={task.homeworkItemId}
                                                                            subject={task.subject}
                                                                            colorHex={task.colorHex}
                                                                            startTime={task.startTime}
                                                                            endTime={task.endTime}
                                                                            isFinished={task.isFinished}
                                                                            timeEstimate={task.timeEstimate}
                                                                            commentCount={task.commentCount}
                                                                            notificationCount={task.notificationCount}
                                                                            setOnFinishedChanged={(isFinished) => {
                                                                                handleOnTaskFinishClick(task, isFinished)
                                                                            }}
                                                                            onClick={() => {
                                                                                Api.post('tasks/' + task.id + '/read', null, () => {
                                                                                    task.notificationCount = 0
                                                                                    setCurrentTasks([...(currentTasks.filter((t) => {
                                                                                        return t.id !== task.id
                                                                                    })), task])
                                                                                })
                                                                                showTaskPopup(task, "temp", (task) => {
                                                                                        editTask()
                                                                                    }, () => {
                                                                                        onDeleteTask(task)
                                                                                    }, (task) => {
                                                                                        editTask()
                                                                                        navigateToTask(task.id, task.date)
                                                                                    },
                                                                                    null,
                                                                                    getAllTasksForCurrentWeek)
                                                                            }}
                                                                            deleteAction={() => deleteTask(task, columnIndex)}
                                                                            copyAction={() => copyTask(task)}
                                                                            disabled={isInReadOnlyMode()}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        })
                                                    }
                                                    {provided.placeholder}
                                                </TaskColumnDroppableArea>
                                            )}
                                        </Droppable>
                                        {!isInReadOnlyMode() &&
                                        <AddTaskButton onClick={() => handleAddTaskClick(columnIndex)}>
                                            <AddTaskButtonIcon src={plusIcon} alt="plus icoon"/>
                                            <AddTaskButtonText>Kaart toevoegen</AddTaskButtonText>
                                        </AddTaskButton>}
                                    </TasksColumn>
                                })}
                            </TasksRow>
                        </DragDropContext>
                        }

                        {noTaskViewVisibility && isDataFetched && currentTasks.length === 0 &&
                        <NoTasksView>
                            <NoTasksViewImage src={folderIllustration} alt-={"Illustratie van folder"}/>
                            <NoTasksViewText>
                                Vul bovenaan
                                je <NoTasksViewTextStyle1>toetsen</NoTasksViewTextStyle1> en <NoTasksViewTextStyle2>huiswerk</NoTasksViewTextStyle2> in.
                                Bepaal daarna welke <NoTasksViewTextStyle3>taken</NoTasksViewTextStyle3> je
                                wanneer wilt oppakken door <NoTasksViewTextStyle4>kaartjes</NoTasksViewTextStyle4> aan
                                een <NoTasksViewTextStyle5>dag</NoTasksViewTextStyle5> toe te voegen.
                            </NoTasksViewText>
                        </NoTasksView>
                        }

                        {taskReminderShouldBeShown &&
                        showTasksFinishedPopup(
                            'Eén of meerdere taken zijn nog niet gedaan deze week. Maak je die nog af?',
                            unfinishedTasksReminderGif
                        )
                        }
                    </ScheduleContentPadding>
                    <ScheduleContentPaddingRight/>
                </ScheduleContentWrapper>
            }

            {currentDateNavigatorMode === DateNavigatorMode.MONTH &&
                <ScheduleMonthView
                    momentObject={currentMoment}
                    onTitleClick={(item, reloadData) => {
                        showTaskPopup(item, "", (item) => {
                                editTask()
                            }, () => {
                                onDeleteTask(item)
                            }, (item) => {
                                editTask()
                                navigateToTask(item.id, item.date)
                            },
                            null, reloadData)
                    }}
                />
            }
        </ScheduleRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Planagenda"}
            content={content}
            isMentorView={isMentorView}
        />
    )

    function getNotificationsFromApi(signal) {
        Api.get('notifications', (response) => {
            setNotifications(response.data)
        }, null, null, null, { signal });
    }

    function navigateToComment(comment) {
        if (comment.examId) {
            navigateToExam(comment.examId)
        } else if (comment.homeworkItemId) {
            navigateToHomework(comment.homeworkItemId)
        } else if (comment.taskId) {
            navigateToTask(comment.taskId, comment.topicDate)
        }
    }

    function navigateToTask(taskId, date) {
        const taskDate = moment(new Date(date))
        let weekToNavigateTo = taskDate.week()
        let dayOfWeek = taskDate.isoWeekday()
        if (dayOfWeek === 6 || dayOfWeek === 7) {
            // if the taskDate is on Saturday or Sunday, add 1 to the current week
            // for example: saturday and sunday from week 16 are actually shown in week 17
            weekToNavigateTo++
        }
        setCurrentMoment(moment(currentMoment.set({week: weekToNavigateTo})))
        setTaskIdToOpen(taskId)
        setOverlayActive(false)
    }

    function navigateToHomework(homeworkItemId) {
        history.push("/huiswerk/" + homeworkItemId);
    }

    function navigateToExam(examId) {
        history.push("/toets/" + examId);
    }

    function fetchAllData(signal) {
        Promise.all([
            getAllTasksForCurrentWeek(signal),
            getAllExamsForCurrentWeek(signal),
            getAllHomeworkItemsForCurrentWeek(signal),
            getWeek(signal)
        ]).then(() => {
            setDataFetched(true);
        })
    }

    function shouldLastTaskReminderBeShown() {
        const currentMoment = moment()
        const checkDay = getDateForDayOfWeek(Day.FRIDAY)
        const isCheckDayToday = currentMoment.isSame(checkDay, "day")
        const allTasksCompleted = allTasksAreCompletedThisWeek()

        /*
        * Only check if task reminder should be shown on the day defined above (friday in this case)
        * If the check has been executed on said day, this function will return false for the rest of that day
        * Meaning it can only be shown once a week and only on app startup
        * */

        if (isCheckDayToday) {
            const lastTaskReminder = AppStorage.get(StorageKey.LAST_TASK_REMINDER_CHECK)

            if (!lastTaskReminder && !allTasksCompleted) {
                // Key did not yet exist in localstorage, show reminder
                AppStorage.set(StorageKey.LAST_TASK_REMINDER_CHECK, currentMoment.unix())
                return true
            } else {
                const lastTaskReminderCheckWasToday = moment.unix(lastTaskReminder).isSame(checkDay, 'day')
                if (!lastTaskReminderCheckWasToday && !allTasksCompleted) {
                    // Key was set in localstorage but its value was not today, show reminder
                    AppStorage.set(StorageKey.LAST_TASK_REMINDER_CHECK, currentMoment.unix())
                    return true
                } else {
                    // Key was set in localstorage but it's value was today or all tasks were completed
                    AppStorage.set(StorageKey.LAST_TASK_REMINDER_CHECK, currentMoment.unix())
                    return false
                }
            }
        } else {
            // Today is not the day to show the reminder
            return false
        }
    }

    function allTasksAreCompletedThisWeek() {
        if (currentTasks.length !== 0) {
            const unFinishedTasks = currentTasks.filter((t) => t.isFinished === 0 && !TaskHelper.isSpareTime(t))
            return unFinishedTasks.length === 0;
        }
    }

    function filterHomeworkOrExamItemsOnDay(itemList, columnIndex) {
        return itemList.filter(item => (
            columnIndex === (TASK_COLUMN_AMOUNT - 1)
                ? item.date >= getDateForDayOfWeek(columnIndex)
                : item.date >= getDateForDayOfWeek(columnIndex) && item.date < getDateForDayOfWeek(columnIndex + 1)
        ))
    }

    function setTaskStateForEachDay() {
        for (let day = Day.SATURDAY; day <= Day.FRIDAY; day++) {
            let listOfTasks = currentTasks.filter(task => (
                day === Days.FRIDAY
                    ? task.date >= getDateForDayOfWeek(day)
                    : task.date >= getDateForDayOfWeek(day) && task.date < getDateForDayOfWeek(day + 1)
            ))
            listOfTasks.sort((a, b) => {
                return a.sortOrder - b.sortOrder
            })
            setTaskStateForDay(day, listOfTasks)
        }
    }

    function setTaskStateForDay(columnIndex, taskList) {
        switch (columnIndex) {
            case Day.SATURDAY:
                return setTasksSaturday(taskList)
            case Day.SUNDAY:
                return setTasksSunday(taskList)
            case Day.MONDAY:
                return setTasksMonday(taskList)
            case Day.TUESDAY:
                return setTasksTuesday(taskList)
            case Day.WEDNESDAY:
                return setTasksWednesday(taskList)
            case Day.THURSDAY:
                return setTasksThursday(taskList)
            case Day.FRIDAY:
                return setTasksFriday(taskList)
            default: return
        }
    }

    function getTaskStateForDay(columnIndex) {
        switch (columnIndex) {
            case Day.SATURDAY:
                return tasksSaturday
            case Day.SUNDAY:
                return tasksSunday
            case Day.MONDAY:
                return tasksMonday
            case Day.TUESDAY:
                return tasksTuesday
            case Day.WEDNESDAY:
                return tasksWednesday
            case Day.THURSDAY:
                return tasksThursday
            case Day.FRIDAY:
                return tasksFriday
            default: return;
        }
    }

    function onDragEnd(result) {
        const {destination, source, draggableId} = result

        // Task is dropped on a non-droppable area -> do nothing
        if (!destination) {
            return
        }

        // Task is dropped back on the same column on the same index -> do nothing
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        // Task is dropped on a different column
        if (destination.droppableId !== source.droppableId) {
            // Get the columnIndexes from both columns and get the current states with these indexes
            const destinationColumnIndex = getIndexFromColumnName(destination.droppableId)
            const sourceColumnIndex = getIndexFromColumnName(source.droppableId)
            const destinationState = getTaskStateForDay(destinationColumnIndex)
            const sourceState = getTaskStateForDay(sourceColumnIndex)

            // Copy current states so we can mutate them + find the task that's been dragged
            let destinationTaskList = Array.from(destinationState)
            let sourceTaskList = Array.from(sourceState)
            const taskToMove = sourceTaskList.find((task) => task.id === draggableId)

            // Remove task with draggable id from sourceTaskList
            const filteredSourceTaskList = sourceTaskList.filter(task => task.id !== draggableId)
            resetTaskListSortOrder(filteredSourceTaskList)
            setTaskStateForDay(sourceColumnIndex, filteredSourceTaskList)

            // Insert task with draggable id in destinationTaskList
            setTaskDateOnDrop(taskToMove, sourceColumnIndex, destinationColumnIndex)
            destinationTaskList.splice(destination.index, 0, taskToMove)
            resetTaskListSortOrder(destinationTaskList)
            setTaskStateForDay(destinationColumnIndex, destinationTaskList)

            // Save state to back-end
            const thisWeeksTasks = [...tasksMonday, ...tasksTuesday, ...tasksWednesday, ...tasksThursday, ...tasksFriday, ...tasksSaturday, ...tasksSunday]
            updateSortOrder(thisWeeksTasks)
        }

        // Task was moved within the same column
        if (
            destination.droppableId === source.droppableId &&
            destination.index !== source.index
        ) {
            const destinationColumnIndex = getIndexFromColumnName(destination.droppableId)
            const destinationState = getTaskStateForDay(destinationColumnIndex)
            let destinationTaskList = Array.from(destinationState)

            // Set new state
            changeTaskListSortOrderOnDrop(destinationTaskList, draggableId, source.index, destination.index)
            setTaskStateForDay(destinationColumnIndex, destinationTaskList)

            // Save state to back-end
            const thisWeeksTasks = [...tasksMonday, ...tasksTuesday, ...tasksWednesday, ...tasksThursday, ...tasksFriday, ...tasksSaturday, ...tasksSunday]
            updateSortOrder(thisWeeksTasks)
        }
    }

    function setTaskDateOnDrop(task, sourceColumnIndex, destinationColumnIndex) {
        if (destinationColumnIndex > sourceColumnIndex) {
            task.date = moment(task.date).add(`${destinationColumnIndex - sourceColumnIndex}`, 'days').toDate().getTime()
        } else {
            task.date = moment(task.date).subtract(`${sourceColumnIndex - destinationColumnIndex}`, 'days').toDate().getTime()
        }
    }

    function changeTaskListSortOrderOnDrop(taskList, draggableId, sourceIndex, destinationIndex) {
        const taskToMove = taskList.find((task) => task.id === draggableId)
        taskList.splice(sourceIndex, 1)
        taskList.splice(destinationIndex, 0, taskToMove)
        return resetTaskListSortOrder(taskList)
    }


    function resetTaskListSortOrder(taskList) {
        taskList.map((task, index) => {
            return task.sortOrder = index
        })
    }

    function getIndexFromColumnName(columnName) {
        const splitArray = columnName.split("-")
        return Number(splitArray[splitArray.length - 1])
    }

    function onDeleteTask(taskToRemove) {
        /*
        * This function is only fired when a delete call was successful
        * After delete the sortOrder of the tasks in the column where the current task was removed from
        * will be recalculated here in the front-end and these changes will be send to the back-end so they can be persisted
        * */

        // Update local state
        let newTaskList = TaskHelper.removeTaskFromTaskList(taskToRemove, [...currentTasks])

        // Subtract from taskAmount of this task's exam or homeworkItem
        subtractOrAddToTaskAmount(taskToRemove, true)

        setCurrentTasks(newTaskList);
    }

    function editTask() {
        /*
        * This function is only fired when the put call was successful
        * The back-end recalculates the sortOrder for the sourceColumn and sets the sortOrder for the edited task
        * Whenever the date of the edited task has changed (only if it exists in DB)
        * */

        setDataFetched(false)
        resetDataStates();
        fetchAllData();
    }

    function resetDataStates() {
        setDataFetched(false);
        setCurrentTasks([]);
        setCurrentExams([]);
        setCurrentHomeworkItems([]);
    }

    function getDateForDayOfWeek(columnIndex, useCurrentTime = false) {
        let thisWeekMonday = moment().year(currentMoment.year()).week(currentMoment.week()).isoWeekday(1);

        // If useCurrentTime is false the time is set to midnight
        !useCurrentTime && (thisWeekMonday = thisWeekMonday.set({hour: 0, minute: 0, second: 0, millisecond: 0}));
        const daysOfWeek = [
            thisWeekMonday.subtract(2, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
            thisWeekMonday.add(1, 'days').toDate(),
        ]
        return daysOfWeek[columnIndex]
    }

    function handleAddTaskClick(columnIndex) {
        showTaskPopup(null, null, (task) => {
            /*
            * This function is only fired when the creation call was successful
            * When adding a task the back-end sets the sortOrder of the created task to the length of
            * the column the task was added to. This means a newly added task is always placed at the bottom.
            * */

            // Update state when the task is added to the current week (state is re-initialized when changing weeks)
            if (task.date >= getDateForDayOfWeek(Day.SATURDAY) && task.date <= getDateForDayOfWeek(Day.FRIDAY)) {
                let tasks = [...currentTasks];
                tasks.push(task)
                setCurrentTasks(tasks)

                // Add to taskAmount of this task's exam or homeworkItem
                subtractOrAddToTaskAmount(task, false)

                if (noTaskViewVisibility) {
                    setNoTaskViewVisibility(false);
                }
            }
        }, null, (task) => {
            editTask()
            navigateToTask(task.id, task.date)
        }, getDateForDayOfWeek(columnIndex, true), getAllTasksForCurrentWeek);
    }

    function handleOnTaskFinishClick(task, isFinished) {
        const tasks = [...currentTasks]
        const taskToEdit = tasks.find(t => t.id === task.id)
        if (taskToEdit) {
            taskToEdit.isFinished = isFinished ? 1 : 0
        }
        setCurrentTasks(tasks)

        //Don't replace with ===, isFinished can be 0
        // eslint-disable-next-line
        if (isFinished && currentTasks.filter((t) => t.id !== task.id && t.isFinished == false && !(t.homeworkItemId == null && t.examId == null)).length === 0) {
            showTasksFinishedPopup(
                'Hoera! Al je taken voor deze week zijn afgerond. Goed bezig!',
                tasksCompletedGif
            )
        }
    }

    function subtractOrAddToTaskAmount(task, subtract) {
        if (task.examId) {
            const examList = [...currentExams]
            const examToEdit = examList.find(exam => exam.id === task.examId)
            if (examToEdit) {
                subtract ? examToEdit.taskAmount -= 1 : examToEdit.taskAmount += 1
                setCurrentExams(examList)
            }
        } else if (task.homeworkItemId) {
            const homeworkList = [...currentHomeworkItems]
            const homeworkItemToEdit = homeworkList.find(homeworkItem => homeworkItem.id === task.homeworkItemId)
            if (homeworkItemToEdit) {
                if (homeworkItemToEdit) {
                    // homeworkItemToEdit is undefined if it's date is not set to this week
                    subtract ? homeworkItemToEdit.taskAmount -= 1 : homeworkItemToEdit.taskAmount += 1
                    setCurrentHomeworkItems(homeworkList)
                }
            }
        }
    }

    function getFormattedDate(date) {
        return moment(date).format("dd D MMM").replace('.', '');
    }

    function setWhatsNewIsRead() {
        let updatedWhatsNewArray = [...whatsNewObjects]
        updatedWhatsNewArray[0].isRead = 1
        setWhatsNewObjects(updatedWhatsNewArray)
    }

    function processUrlQueryParams() {
        // Check for navigation to task
        const taskId = query.get("taskId")
        const taskDate = query.get("taskDate")
        if (taskId && taskDate) {
            navigateToTask(taskId, Number(taskDate))
        }

        // Check for navigation to homework
        const homeworkId = query.get("homeworkId")
        if (homeworkId) {
            navigateToHomework(homeworkId)
        }

        const examId = query.get("examId")
        if (examId) {
            navigateToExam(examId)
        }
    }

    function getAllTasksForCurrentWeek(signal) {
        const config = {
            params: {
                'filter[date][GT]': getDateForDayOfWeek(0).getTime(),
                'filter[date][LE]': moment(getDateForDayOfWeek(6)).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999
                }).toDate().getTime(),
            }, signal
        }

        Api.get("pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/tasks", (response) => {
            setCurrentTasks(response.data)
            if (response.data.length === 0) {
                setNoTaskViewVisibility(true);
            }
        }, null, null, null, config)
    }

    function getAllExamsForCurrentWeek(signal) {
        const config = {
            params: {
                'filter[date][GT]': getDateForDayOfWeek(0).getTime(),
                'filter[date][LE]': moment(getDateForDayOfWeek(6)).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999
                }).toDate().getTime(),
            }, signal
        }

        Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/exams", (response) => {
            setCurrentExams(response.data)
        }, null, null, null, config)
    }

    function getAllHomeworkItemsForCurrentWeek(signal) {
        const config = {
            params: {
                'filter[date][GT]': getDateForDayOfWeek(0).getTime(),
                'filter[date][LE]': moment(getDateForDayOfWeek(6)).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999
                }).toDate().getTime(),
            }, signal
        }

        Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/homeworkItems", (response) => {
            setCurrentHomeworkItems(response.data)
        }, null, null, null, config)
    }

    function getWeek(signal) {
        const config = {
            params: {
                'filter[number]': currentMoment.week(),
                'filter[year]': currentMoment.year()
            }, signal
        }
        Api.get("/weeks", (response) => {
            setWeekObject(response.data[0])
        }, null, null, null, config);
    }

    function updateWeekGoal(event, weekId) {
        const newWeekGoal = weekGoal ?? {
            pupilId: AppStorage.get(StorageKey.USER_ID)
        }

        newWeekGoal.text = event.target.value
        newWeekGoal.weekId = weekId

        if (newWeekGoal.id) {
            Api.put("/weeks/" + weekObject.id + "/goals/" + newWeekGoal.id, newWeekGoal, (response) => {
                setWeekGoal(response.data)
            });
        } else {
            Api.put("/weeks/" + weekObject.id + "/goals/", newWeekGoal, (response) => {
                setWeekGoal(response.data)
            });
        }
    }

    function getWeekGoal(signal) {
        Api.get("/weeks/" + weekObject.id + "/goals/", (response) => {
            if (response.data.length) {
                setWeekGoal(response.data[0])
            } else {
                setWeekGoal(null)
            }
        }, null, null, null, { signal });
    }

    function updateSortOrder(tasks) {
        Api.post("/sortTasks", tasks, null, null, null)
    }

    function deleteTask(task, columnIndex) {
        Api.delete('tasks/' + task.id, () => onDeleteTask(task, columnIndex))
    }

    function copyTask(task) {
        let taskCopy = TaskHelper.copyTask(task)
        Api.put('pupils/' + AppStorage.get(StorageKey.USER_ID) + '/tasks/', taskCopy, (response) => {
            editTask()
            let newTask = response.data
            navigateToTask(newTask.id, task.date)
        })
    }

    function getWhatsNew(signal) {
        const config = {
            params: {
                'filter[published][EQ]': 1,
                'page[size]': 5,
                'page[number]': 1
            }, signal
        }

        Api.get("/whatsNew/", (response) => {
            if (response.data.length) {
                setWhatsNewObjects(response.data)
            }
        }, null, null, null, config);
    }
}

export const ScheduleRoot = styled.div`
    width: 100%;
    height: 100%;
    background: ${purpleLightest};
    min-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const ScheduleContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex: 1 1 0;
`;

export const ScheduleContentPaddingRight = styled.div`
    width: 40px;
    height: 100%;
`;

//right padding doesnt work in scrollview due to its position, thus ScheduleContentPaddingRight
export const ScheduleContentPadding = styled.div`
    padding: 20px 40px 00px 40px;
    display: flex;
    flex-direction: column;
`;

export const HeaderTitle = styled.span`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 1.3125em;
    color: ${purple};
`;

export const ScheduleTopRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: 98px;
    margin-bottom: 30px;
    
    > * {
        width: 234px;
        flex-shrink: 0;
    }
`;

export const WeeklyHint = styled.div`
    background: ${red};
    border-radius: 3px;
    box-shadow: 0px 2px 0px #CB351A;
    padding: 13px;
    overflow: hidden;
`;

export const WeeklyHintTitle = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${white};
    opacity: 0.6;
    margin-bottom: 5px;
`;

export const WeeklyHintText = styled.div`
    font-size: 15px;
    color: ${white};
`;

export const WeeklyGoalTitle = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${black};
    opacity: 0.6;
    margin-bottom: 5px;
`;

export const WeeklyGoalText = styled.textarea`
    font-family: ${sourceSansPro};
    font-weight: 400;
    resize: none;
    font-size: 16px;
    color: ${black};
    background: none;
    text-decoration: none;
    padding: none;
    border: none;
    width: 100%;
    height: 100%;
    text-align: top;
    align-content: top;
    vertical-align: top;
    border-style: none;
    border-color: Transparent;
    outline: none;
    overflow: hidden;
    ::placeholder {
       font-style: italic;
    }
`;

export const WeeklyGoal = styled.div`
    background: #FFEEB9;
    box-shadow: 0px 2px 0px #F3E1A8;
    border-radius: 3px;
    padding: 13px;
`;

export const ExamsAndHomeworkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const TasksRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    
    > * {
        width: 234px;
        flex-grow: 0;
        flex-shrink: 0;
    }
`;

export const TasksColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const TasksColumnDate = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const TaskColumnDroppableArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 20px;
`;

export const DateText = styled.div`
   font-family: ${nunito};
   font-weight: 700;
   font-size: 14px;
   color: ${purpleDark};
   padding-left: 10px;
   text-transform: uppercase;
`;

export const AddTaskIcon = styled.img`
   padding: 10px;
   &:hover {
      cursor: pointer;
   }
`;

export const AddTaskButton = styled.div`
   display: flex;
   gap: 5px;
   padding-bottom: 10px;
   &:hover {
      cursor: pointer;
   }
`;

export const AddTaskButtonIcon = styled.img`
   opacity: 0.6;
`;

export const AddTaskButtonText = styled.div`
   font-family: ${nunito};
   font-weight: 400;
   font-size: 14px;
   opacity: 0.6;
   color: ${purpleDark};
`;

export const NoTasksView = styled.div`
   max-width: 430px;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 18px;
   position: fixed;
   top: 60vh;
   left: 50vw;
   transform: translate(-90px, -50%);
   pointer-events: none;
   box-sizing: content-box;
   padding: 0 20px;

   @media screen and ${device.tablet} {
    transform: translate(-50%, -50%);
   }
`;

export const NoTasksViewImage = styled.img`
   width: 125px;
   align-self: center;

   @media screen and ${device.mobileL} {
    width: 95px;
    align-self: center;
   }
`;

export const NoTasksViewText = styled.p`
   font-family: ${nunito};
   font-weight: 600;
   font-size: 17px;
   text-align: center;
   line-height: 27px;
   opacity: 0.8;
   color: ${purpleDark};
   margin: 0;
    align-self: center;

   @media screen and ${device.mobileL} {
    max-width: 297px;
    font-size: 15px;
    line-height: 22px;
   }
`;

export const NoTasksViewTextStyle1 = styled.span`
    font-family: ${markoOne};
    color: ${red};
`;

export const NoTasksViewTextStyle2 = styled.span`
    font-family: ${lemon};
    color: ${violet};
`;

export const NoTasksViewTextStyle3 = styled.span`
    font-family: ${fjallaOne};
    color: ${purple};
`;

export const NoTasksViewTextStyle4 = styled.span`
    font-family: ${lobster};
    color: ${red};
`;

export const NoTasksViewTextStyle5 = styled.span`
    font-family: ${capriola};
    color: ${purple};
`;

export default ScheduleScreen;
