export const DropDownType = {
    HOMEWORK: "Huiswerk",
    EXAM: "Toetsen"
};

export const Direction = {
    PREVIOUS: "Vorige",
    NEXT: "Volgende"
}

export const DateNavigatorDirection = {
    BACKWARD: "BACKWARD",
    FORWARD: "FORWARD"
}

export const DateNavigatorMode = {
    MONTH: "MONTH",
    WEEK: "WEEK"
}

export const SwitchOption = {
    LEFT: "LEFT",
    RIGHT: "RIGHT"
}

export const Answer = {
    NEVER: 1,
    SOMETIMES: 2,
    OFTEN: 3,
    ALWAYS: 4,
}

export const QuestionnaireType = {
    TEXT: "text",
    QUESTION: "questions"
}

export const PlanOfActionTabs = {
    EF: "EF's",
    PLANOFACTION: "Plan van Aanpak",
    EVALUATION: "Evaluatie"
}

export const Day = {
    MONDAY: 2,
    TUESDAY: 3,
    WEDNESDAY: 4,
    THURSDAY: 5,
    FRIDAY: 6,
    SATURDAY: 0,
    SUNDAY: 1
}

export const UserType = {
    PUPIL: "PUPIL",
    MENTOR: "TEACHER",
    GUARDIAN: "GUARDIAN"
}

export const ExecutiveFunctions = {
    PLANNING: "plannen",
    TIME_MANAGEMENT: "tijd verdelen",
    REMEMBER: "onthouden",
    ORGANIZE: "organiseren",
    SELF_AWARENESS: "ken jezelf",
    THINK_BEFORE_ACTING: "denken dan doen",
    EMOTION_REGULATION: "emoties controleren",
    FOCUS: "aandacht erbij",
    INITIATION: "starten",
    GOAL_ORIENTED: "doelen halen",
    FLEXIBILITY: "flexibel zijn",
}

export const SupportedVideoFormats = [".mp4", ".webm"]

export const Skills = {
    BEGINNER: "BEGINNER",
    DEVELOPING: "DEVELOPING",
    MASTERED: "MASTERED",
}