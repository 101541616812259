import {useEffect, useRef} from "react";

export const useIsMounted = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;
        // eslint-disable-next-line
    }, []);
    
    return isMountedRef.current;
};