import styled from 'styled-components';
import OverViewCard from './OverviewCard';
import React from 'react';
import {white} from '../../Theme';
import {ProgressRadarChart} from '../../components/ProgressRadarChart/ProgressRadarChart';
import {device} from '../../constants/breakpoints';
import {PlanOfActionDesktopFooter} from './PlanOfActionFooter';

export function ExecutiveFunctionsCard({period, activeTab, type, ...props}) {
    return (
        <StyledExecutiveFunctionsCard $type={type} $activeTab={activeTab}>
            <RadarCard>
                <RadarOuter>
                    <RadarInner>
                        <ProgressRadarChart data={period} />
                    </RadarInner>
                </RadarOuter>
                <PlanOfActionDesktopFooter {...props} />
            </RadarCard>
            <OverViewCard period={period} {...props} />
        </StyledExecutiveFunctionsCard>
    );
}

const StyledExecutiveFunctionsCard = styled.div`
    display: ${({$type, $activeTab}) => $type === $activeTab ? "flex" : "none"};
    justify-content: center;
    gap: 20px;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    
    @media screen and ${device.laptopL} {
        flex-direction: column;
        align-items: center;
    }
`;

const RadarCard = styled.div`
    flex: 1;
    max-width: 50%;
    background-color: ${white};
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0 2px 0 rgba(86, 89, 146, 0.1);
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    @media screen and ${device.laptopL} {
        flex-basis: auto;
        max-width: 590px;
        width: 100%;
        height: auto;
    }
`;

const RadarOuter = styled.div`
    aspect-ratio: 1 / 1;
    width: 100%;
    
    @supports not (aspect-ratio: 1 / 1) {
        position: relative;
        overflow: hidden;
        padding-top: 100%;
    }
`;

const RadarInner = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    container-type: inline-size;

    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        inset: 0;
    }
`;