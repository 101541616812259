import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {black, blueLight, ClickableCard, nunito, purple, purpleLight, sourceSansPro, white, yellow} from "../Theme";
import checkedIcon from "../resources/images/checked.svg";
import uncheckedIcon from "../resources/images/unchecked.svg";
import verticalMenuIcon from "../resources/images/ic-menu-vertical.svg";
import trashIcon from "../resources/images/ic-trash.svg";
import copyIcon from "../resources/images/ic-copy.svg";
import commentIcon from "../resources/images/comment.svg";
import noCommentIcon from "../resources/images/noComment.svg";
import timeIcon from "../resources/images/time.svg";
import Api from "../util/Api";
import {isInReadOnlyMode} from "../App";
import {useGlobalState} from "../util/GlobalState";
import moment from "moment";
import {device} from "../constants/breakpoints";

function TaskCard(props) {
    const [isFinished, setFinished] = useState(false);
    const [overlayActive, setOverlayActive] = useGlobalState('overlayActive', false);
    const [taskMenuOpen, setTaskMenuOpen] = useState(false);

    let isSpareTime = !props.examId && !props.homeworkItemId

    useEffect(() => {
        props.isFinished === 1 ? setFinished(true) : setFinished(false);
        // eslint-disable-next-line
    }, [props.isFinished])

    useEffect(() => {
        if (!overlayActive) {
            setTaskMenuOpen(false)
        }
        // eslint-disable-next-line
    }, [overlayActive]);

    //"\u00a0" is a non breaking space
    return <Container increaseZIndex={taskMenuOpen}>
        <TaskMenu {...props} taskMenuOpen={taskMenuOpen}/>
        <ClickableCard padding={"13px 13px 13px 13px"} mobilePadding={"13px 13px 13px 13px"}
                       onClick={() => props.onClick()}>
            <MenuIcon $display={!isInReadOnlyMode()} src={verticalMenuIcon} onClick={(e) => handleOnMenuClicked(e)}/>
            <Title isFinished={isFinished}>{!!props.title ? props.title : ""}</Title>

            <DateAndSummaryHolder>
                {props.date && <Paragraph>{props.date}</Paragraph>}
                <SummaryHolder>
                    {(isSpareTime ? getTimeEstimateString() !== "" : props.timeEstimate) && <SmallIconText>
                        <SmallIcon src={timeIcon}/>
                        <SmallText>{getTimeEstimateString()}</SmallText>
                    </SmallIconText>}
                    {props.commentCount > 0 && <SmallIconText>
                        <SmallIcon src={props.notificationCount > 0 ? commentIcon : noCommentIcon}
                                   opacity={props.notificationCount ? 1 : 0.5}/>
                        <SmallText>{props.commentCount}</SmallText>
                    </SmallIconText>}
                </SummaryHolder>
            </DateAndSummaryHolder>
            <BottomBar>
                <TypeLabel
                    isSpareTime={isSpareTime}
                    examId={props.examId}
                    isFinished={isFinished}>{isSpareTime ? "Vrije tijd" : (props.examId ? "Toets" : "Huiswerk")}
                </TypeLabel>

                {!isSpareTime &&
                <>
                    <SubjectLabel color={props.colorHex} isFinished={isFinished}>{props.subject}</SubjectLabel>
                    <FinishedIcon src={isFinished ? checkedIcon : uncheckedIcon} onClick={(e) => {
                        e.stopPropagation();
                        if (!isInReadOnlyMode()) {
                            toggleTask();
                        }
                    }}/>
                </>
                }
            </BottomBar>
        </ClickableCard>
    </Container>

    function getTimeEstimateString() {
        const startTime = getFormattedTime(props.startTime)
        const endTime = getFormattedTime(props.endTime)
        if (!!startTime && !!endTime) {
            return `${startTime} - ${endTime}`
        } else if (!!startTime) {
            return startTime
        } else if (!!endTime) {
            return endTime
        } else if (props.timeEstimate){
            return props.timeEstimate
        } else {
            return ""
        }
    }

    function toggleTask() {
        let value = !isFinished ? 1 : 0;
        Api.patch('tasks/' + props.id, {isFinished: value}, () => {
            setFinished(!isFinished);
            props.setOnFinishedChanged(!isFinished)
        });
    }

    function handleOnMenuClicked(e) {
        e.stopPropagation()
        setTaskMenuOpen(!taskMenuOpen)
        setOverlayActive(!overlayActive)
    }

    function getFormattedTime(timestamp) {
        if (!!timestamp) {
            const date = new Date(timestamp);
            return moment(date).format("HH:mm");
        } else {
            return ""
        }
    }
}

export function TaskMenu(props) {
    const location = props.location ?? "top"
    const taskMenuRef = useRef()
    const [taskMenuHeight, setTaskMenuHeight] = useState(0);
    const [taskMenuWidth, setTaskMenuWidth] = useState(0);

    useEffect(() => {
        setTaskMenuHeight(taskMenuRef.current.clientHeight)
        setTaskMenuWidth(taskMenuRef.current.clientWidth)
    }, [props.taskMenuOpen])

    return (
        <MenuContainer
            $display={props.taskMenuOpen}
            height={taskMenuHeight}
            width={taskMenuWidth}
            ref={taskMenuRef}
            location={location}>
            <MenuItem onClick={props.copyAction} $disabled={props.disabled}>
                <ItemIcon src={copyIcon}/>
                <ItemText>Kopieer kaart</ItemText>
            </MenuItem>
            <MenuItem onClick={props.deleteAction} $disabled={props.disabled}>
                <ItemIcon src={trashIcon}/>
                <ItemText>Verwijderen</ItemText>
            </MenuItem>
        </MenuContainer>
    )
}

const Title = styled.div`
    font-family: ${sourceSansPro};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    max-width: 185px;
    color: ${black};
    opacity: ${props => props.isFinished ? "0.5" : "1"};
    text-decoration: ${props => props.isFinished ? "line-through" : "none"};
`;

const Paragraph = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    color: ${black};
    opacity: 0.5;
`;

const TypeLabel = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
    background: ${props => props.isSpareTime ? blueLight : (props.examId ? yellow : purpleLight)};
    border: 1px solid rgba(94, 65, 135, 0.15);
    border-radius: 3px;
    opacity: ${props => props.isFinished ? "0.5" : "1"};
`;

const SubjectLabel = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
    background: ${props => props.color ? props.color : white};
    border: 1px solid rgba(94, 65, 135, 0.15);
    border-radius: 3px;
    opacity: ${props => props.isFinished ? "0.5" : "1"};
`;

const FinishedIcon = styled.img`
    src: ${props => props.src};
    cursor: pointer;
`;

const BottomBar = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    img:last-of-type {
       margin-left: auto;
    }
`;

export const MenuIcon = styled.img`
    display: ${props => props.$display ? 'block' : 'none'};
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 4px 0 0;
`;

const SummaryHolder = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 5px;
`;
const DateAndSummaryHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Container = styled.div`
    position: relative;
    z-index: ${props => props.increaseZIndex ? "110" : "0"}
`;

const MenuContainer = styled.div`
    display: ${props => props.$display ? "flex" : "none"};
    top: ${props => props.location === "top" ? ("-" + (props.height + 2) + "px") : "0"};
    right: ${props => props.location === "top" ? "0" : ("-" + (props.width + 2) + "px")};;
    @media screen and ${device.tablet} {
        top: ${props => props.location === "top" ? ("-" + (props.height + 2) + "px") : "45px"};
        right: ${props => props.location === "top" ? "0" : "15px"};;
    }
    flex-direction: column;
    gap: 5px;
    position: absolute;
    z-index: 99999;
    padding-bottom: 5px;
    padding-left: 5px;
`;

const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 36px;
    width: 142px;
    align-items: center;
    background: ${purple};
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    cursor: pointer;
    ${({$disabled}) => $disabled && 'pointer-events: none;'};
`;

const SmallIconText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`;

const SmallText = styled.div`
    font-family: ${nunito};
    font-size: 12px;
    font-weight: 400;
    color: ${black};
    opacity: 0.5;
    margin-left: 5px;
`;

const SmallIcon = styled.img`
    width: 12px;
    height: 12px;
    opacity: ${props => props.opacity ? props.opacity : 0.5};
    src: ${props => props.src};
`;

const ItemIcon = styled.img`
    margin-left: 14px;
`;

const ItemText = styled.div`
   font-family: ${nunito};
   font-size: 14px;
   font-weight: 700;
   color: ${white};
`;

export default TaskCard;