import React from "react";
import styled from "styled-components";
import {ScheduleMonthConstants} from "./ScheduleMonthConstants";
import {nunito} from "../../../Theme";

function ScheduleMonthGridHeaderView(props) {
    return <GridHeader>
        <GridHeaderDay>MAANDAG</GridHeaderDay>
        <GridHeaderDay>DINSDAG</GridHeaderDay>
        <GridHeaderDay>WOENSDAG</GridHeaderDay>
        <GridHeaderDay>DONDERDAG</GridHeaderDay>
        <GridHeaderDay>VRIJDAG</GridHeaderDay>
        <GridHeaderDay>ZATERDAG</GridHeaderDay>
        <GridHeaderDay>ZONDAG</GridHeaderDay>
    </GridHeader>
}

const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: ${ScheduleMonthConstants.maxColumns * ScheduleMonthConstants.cardWidth}px;
  height: 50px;
  flex: 1 0 auto;
`;

const GridHeaderDay = styled.h4`
  display: flex;
  align-items: center;
  width: ${ScheduleMonthConstants.cardWidth}px;
  height: 100%;
  padding: 0 10px 0;
  margin: 0;
  font-family: ${nunito};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`;

export default ScheduleMonthGridHeaderView;