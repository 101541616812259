import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import {BasicGrid, black, nunito, purple, purpleLightest, purpleSoft, red, SmallHeader, SmallHeaderTitle, sourceSansPro, white} from "../../Theme";
import Api from "../../util/Api";
import {useHistory} from "react-router-dom";
import {device} from "../../constants/breakpoints";
import showExecutiveFunctionProgressPopup from "../../popups/showExecutiveFunctionProgressPopup";
import {Loader} from "../../components/Loader";
import rocketIcon from "../../resources/images/rocketIcon.png";
import placeholder from "../../resources/images/placeholder.jpg";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {showNativeNavigation} from "../../AppInterface";
import {ProgressRadarChart} from '../../components/ProgressRadarChart/ProgressRadarChart';
import {determineSkill} from "../../util/Helpers";

function PlanOfActionOverview() {
    const [periods, setPeriods] = useState([]);
    const [lessonUpUrl, setLessonUpUrl] = useState(null);
    const history = useHistory();

    useEffect(() => {
        showNativeNavigation();
        getPeriods();
        getLessonUpUrlFromApi();
        //eslint-disable-next-line
    }, []);

    const content = (
        <PlanOfActionOverviewRoot>

            {periods.length === 0 &&
                <Loader/>
            }

            <SmallHeader>
                <SmallHeaderTitle>EF in beeld</SmallHeaderTitle>
            </SmallHeader>

            {periods.length !== 0 &&
                <PlanOfActionOverviewWrapper>

                    <GridWrapper>
                        <MobileProgressButton onClick={() => handleOnMobileProgressButtonClick()}>
                            <ProgressButtonText>Bekijk voortgangsoverzicht</ProgressButtonText>
                        </MobileProgressButton>

                        <BasicGrid
                            maxWidth={"100%"}
                            rowGap={"40px"}
                            columnGap={"40px"}
                            columnDivision={"1fr 1fr"}
                            laptopRowGap={"30px"}
                            laptopColumnGap={"30px"}
                            mobileRowGap={"16px"}
                            mobileColumnGap={"16px"}
                            mobileColumnDivision={"1fr 1fr"}
                        >
                            {periods.map((period, index) => {
                                return <PlanOfActionOverviewCard
                                    key={period.id}
                                    period={period}/>
                            })}
                        </BasicGrid>
                    </GridWrapper>

                    <PlanOfActionProgressCard periods={periods}/>

                </PlanOfActionOverviewWrapper>
            }
        </PlanOfActionOverviewRoot>
    );

    function PlanOfActionProgressCard(props) {

        return (
            <ProgressCard popup={props.popup}>
                <ProgressCardTitle>Voortgangsoverzicht</ProgressCardTitle>

                <ProgressListLabelWrapper>
                    <ProgressListLabel>Executieve Functies</ProgressListLabel>
                    <ProgressListScoreLabelWrapper>
                        <ProgressListLabel>P1</ProgressListLabel>
                        <ProgressListLabel>P2</ProgressListLabel>
                        <ProgressListLabel>P3</ProgressListLabel>
                        <ProgressListLabel>P4</ProgressListLabel>
                    </ProgressListScoreLabelWrapper>
                </ProgressListLabelWrapper>

                <ProgressList>
                    {
                        (periods[0].scoresPerExecutiveFunction ?? []).map((ef, i) => {
                            return (
                                <ProgressListItem
                                    key={ef.title}
                                    index={i + 1}
                                    title={ef.title}
                                    skillScale={{beginner: periods[0].beginner, developing: periods[0].developing, mastered: periods[0].mastered}}
                                    scores={props.periods.map((p) => p.scoresPerExecutiveFunction[i].totalScore)}
                                />
                            )
                        })
                    }

                </ProgressList>
                {lessonUpUrl &&
                    <a href={lessonUpUrl} target={"_blank"} rel="noreferrer"><PracticeButton>Digitaal oefenen<RocketIcon src={rocketIcon}/></PracticeButton></a>}
            </ProgressCard>
        );
    }

    function PlanOfActionOverviewCard(props) {
        const hasFilledInQuestionnaire = props?.period?.scoresPerExecutiveFunction?.every(item => item.totalScore !== null && item.totalScore !== undefined);

        return (
            <PlanOfActionOverviewCardRoot onClick={() => navigateToPeriod()}>
                <OverviewCardImageOuter>
                    {hasFilledInQuestionnaire ? (
                        <OverviewCardChart>
                            <ProgressRadarChart data={props?.period}/>
                        </OverviewCardChart>
                    ) : (
                        <OverviewCardImage src={placeholder} alt=""/>
                    )}
                </OverviewCardImageOuter>
                <OverviewCardButton>
                    <OverViewCardButtonText>{props.period.title}</OverViewCardButtonText>
                </OverviewCardButton>
            </PlanOfActionOverviewCardRoot>
        );

        function navigateToPeriod() {
            if (props.period.scoresPerExecutiveFunction.filter(ef => ef.totalScore == null).length) {
                //If the user hasn't finished this period's questionnaire yet
                history.push("/ef-voortgang/" + props.period.id + "/vragenlijst", {period: props.period});
            } else {
                history.push("/ef-voortgang/" + props.period.id, {period: props.period})
            }
        }
    }

    function ProgressListItem({skillScale, ...props}) {
        return (
            <ProgressListItemRoot>
                <ProgressListItemTitle>{props.index}. {props.title}</ProgressListItemTitle>
                <ScoreRow>
                    {props.scores.map((totalScore, index) => {
                        const determinedSkill = determineSkill(totalScore, skillScale);

                        return <ScoreIndicatorIcon key={index} totalScore={totalScore} determinedSkill={determinedSkill} />
                    })}
                </ScoreRow>
            </ProgressListItemRoot>
        );
    }

    return (
        <BaseScreen
            activeMenuItem={"EF in beeld"}
            content={content}
        />
    )

    function getPeriods() {
        const config = {
            params: {
                'context[personId]': AppStorage.get(StorageKey.PUPIL_ID) //call contains information generated on logged in member or context person
            }
        }
        Api.get("/periods", (response) => {
            setPeriods(response.data);
        }, null, null, null, config)
    }

    function handleOnMobileProgressButtonClick() {
        const content = (<PlanOfActionProgressCard popup={true} periods={periods}/>)
        showExecutiveFunctionProgressPopup(content);
    }

    function getLessonUpUrlFromApi() {
        Api.get('configuration', (response) => {
            setLessonUpUrl(response.data[0].lessonUpUrl)
        })
    }
}

export function ScoreIndicatorIcon({determinedSkill, ...props}) {
    return (
        <ScoreIndicatorRoot $color={determinedSkill?.color}>
            <ScoreNumber>
                {determinedSkill?.shortLabel}
            </ScoreNumber>
        </ScoreIndicatorRoot>
    );
}

export const PlanOfActionOverviewRoot = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background: ${purpleLightest};
`;

export const PlanOfActionOverviewWrapper = styled.div`
    width: 100%;
    padding: 30px 30px 30px 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and ${device.laptopL} {
        padding: 30px 17px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    @media screen and ${device.mobileL} {
        padding: 17px;
    }
`;

export const PlanOfActionOverviewCardRoot = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
`;

const OverviewCardImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: white;
    margin-bottom: 5px;
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 3px;

    @supports not (aspect-ratio: 1 / 1) {
        overflow: hidden;
        padding-top: 100%;
    }
`;

const OverviewCardChart = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    container-type: inline-size;

    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        inset: 0;
    }
`;

const OverviewCardImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        inset: 0;
    }
`;

export const OverviewCardButton = styled.div`
    width: 100%;
    height: 100%;
    background: ${purple};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    padding: 12px;
`;

export const ProgressCard = styled.div`
    min-width: ${props => props.popup ? undefined : "395px"};
    min-height: ${props => props.popup ? undefined : "835px"};
    background: ${white};
    border: ${props => props.popup ? undefined : "1px solid rgba(86, 89, 146, 0.1);"};
    box-shadow: ${props => props.popup ? undefined : "0px 2px 0px rgba(86, 89, 146, 0.1);"};
    border-radius: 3px;
    padding: ${props => props.popup ? undefined : "30px 40px 30px 40px"};
    margin-left: ${props => props.popup ? undefined : "72px"};

    @media screen and ${device.laptopL} {
        display: ${props => props.popup ? "block" : "none"};
    }
`;

export const OverViewCardButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const ProgressCardTitle = styled.h2`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 18px;
    color: ${red};
    margin: 0 0 35px 0;
    text-align: center;
`;

export const ProgressList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ProgressListItemRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);

    &:last-of-type {
        border-bottom: unset;
    }
`;

export const ProgressListItemTitle = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
`;

export const ScoreRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 9px;
`;

export const ScoreIndicatorRoot = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({$color}) => $color ? $color : purpleSoft};
    border-radius: 50%;
`;

export const ScoreNumber = styled.span`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 16px;
    color: ${white};
    margin-bottom: 1px;
`;

export const ProgressListLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px
`;

export const ProgressListScoreLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 9px;
`;

export const ProgressListLabel = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${black};
    opacity: 0.7;
    min-width: 20px;
    text-align: center;
`;

export const MobileProgressButton = styled.div`
    background: ${red};
    width: 100%;
    height: 41px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px #B7341D;
    border-radius: 3px;
    margin-bottom: 30px;
    cursor: pointer;
    display: none;

    @media screen and ${device.laptopL} {
        display: flex;
        margin-bottom: 16px;
    }
`;

export const ProgressButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const PracticeButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #5E4187;
    font-family: ${sourceSansPro};
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    width: 100%;
    cursor: pointer;
    height: 35px;
    border: none;
    border-bottom: #4D2F78 3px;
    border-radius: 3px;

    &:hover {
        cursor: pointer;
    }
`;

export const RocketIcon = styled.img`
    width: 18px;
    height: 18px;
`
export const GridWrapper = styled.div`
    max-width: 900px;
    width: 100%;
`;


export default PlanOfActionOverview;

