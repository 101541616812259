import {nunito, sourceSansPro, white} from "../../Theme";
import {ScoreIndicatorIcon} from "./PlanOfActionOverview";
import styled from "styled-components";
import {device} from "../../constants/breakpoints";
import {determineSkill} from '../../util/Helpers';

const HeaderLabel = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    opacity: 0.7;
`;

const TitleHeader = styled(HeaderLabel)`
    width: 45%;
    @media screen and ${device.mobileL} {
        width: 70%;
    }
`;

const IndicatorHeader = styled(HeaderLabel)`
    width: 45%;
    @media screen and ${device.mobileL} {
        width: 20%;
    }
`;

const ScoreHeader = styled(HeaderLabel)`
    width: 10%;
`;

const OverViewHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

function OverViewCard({period, ...props}) {

    return (
        <StyledOverViewCard>
            <OverViewCardRoot {...props}>
                <OverViewHeader>
                    <TitleHeader>Executieve Functies</TitleHeader>
                    <IndicatorHeader>Niveau</IndicatorHeader>
                    <ScoreHeader>Score</ScoreHeader>
                </OverViewHeader>
                {(period ? period.scoresPerExecutiveFunction : []).map((ef, i) => (
                    <ProgressListItem
                        key={ef.title}
                        index={i + 1} title={ef.title}
                        totalScore={ef.totalScore}
                        averageScore={ef.averageScore}
                        skillScale={{beginner: period.beginner, developing: period.developing, mastered: period.mastered}}
                    />
                ))}
            </OverViewCardRoot>
        </StyledOverViewCard>
    );

    function ProgressListItem({skillScale, ...props}) {
        const determinedSkill = determineSkill(props.totalScore, skillScale);

        return (
            <ScoreRow>
                <ScoreTitle>{props.index}. {props.title}</ScoreTitle>
                <ScoreIndicatorRoot>
                    <ScoreIndicatorIcon totalScore={props.totalScore} determinedSkill={determinedSkill} />
                    <ScoreText>{determinedSkill?.label}</ScoreText>
                </ScoreIndicatorRoot>
                <Score>
                    {props.totalScore}
                </Score>
            </ScoreRow>
        );
    }
}

export default OverViewCard;

const StyledOverViewCard = styled.div`
    flex: 1;
    max-width: 50%;
    background: ${white};
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0 2px 0 rgba(86, 89, 146, 0.1);
    border-radius: 3px;
    padding: 38px 67px;
    display: flex;
    flex-direction: column;
    
    @media screen and ${device.laptopL} {
        max-width: 590px;
        width: 100%;
    }
    
    @media screen and ${device.tablet} {
        padding: 38px 47px;
    }
    
    @media screen and ${device.mobileL} {
        padding: 20px 23px;
    }
`;

const ScoreTitle = styled.div`
    width: 45%;
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    
    @media screen and ${device.mobileL} {
        width: 70%;
    }
`;

const ScoreIndicatorRoot = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    width: 45%;
    
    @media screen and ${device.mobileL} {
        width: 20%;
    }
`;

const Score = styled.div`
    width: 10%;
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
`;

const ScoreRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);
    align-items: center;
`;

const ScoreText = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    
    @media screen and ${device.mobileL} {
        display: none;
    }
`;

const OverViewCardRoot = styled.div`
    width: 100%; 
    display: block;
`;