import Swal from "sweetalert2";
import {black, purple, red, sourceSansPro, white} from "../Theme";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import closeIcon from "../resources/images/close.svg";

export const BasicPopup = withReactContent(Swal)

function showBasicPopup(title, text, buttonText) {
    BasicPopup.fire({
        html: (
            <CenteredPopupContent>
                <CloseButtonWrapper>
                    <CloseButton src={closeIcon} onClick={BasicPopup.clickConfirm}/>
                </CloseButtonWrapper>
                <PopupTitle>{title}</PopupTitle>
                <PopupText>{text}</PopupText>
                <ConfirmButton onClick={BasicPopup.clickConfirm}>{buttonText}</ConfirmButton>
            </CenteredPopupContent>
        ),
        heightAuto: false,
        showCancelButton: false,
        showConfirmButton: false,
    })
}

function showConfirmationPopup(title, text, positiveButtonText, positiveButtonCallback, negativeButtonText, negativeButtonCallback) {
    BasicPopup.fire({
        html: (
            <CenteredPopupContent>
                <PopupTitle>{title}</PopupTitle>
                <PopupText>{text}</PopupText>
                <ConfirmButton onClick={BasicPopup.clickConfirm}>{positiveButtonText}</ConfirmButton>
                {negativeButtonText && <CancelButton onClick={BasicPopup.clickCancel}>{negativeButtonText}</CancelButton>}
            </CenteredPopupContent>
        ),
        heightAuto: false,
        showCancelButton: false,
        showConfirmButton: false,

    }).then((result) => {
        if (result.isConfirmed) {
            positiveButtonCallback()
        } else {
            if(negativeButtonCallback) negativeButtonCallback()
        }
    })
}

export const CenteredPopupContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`

export const CloseButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center; 
    gap: 16px;   
`

export const CloseButton = styled.img`
    src: ${props => props.src};
    cursor: pointer;
`

export const PopupTitle = styled.div`
    font-family: ${sourceSansPro};
    font-size: 1.125em;
    font-weight: 600;
    color: ${red};
    margin-top: 10px;
`

export const PopupText = styled.div`
    font-family: ${sourceSansPro};
    color: ${black};
    font-weight: 400;
    margin-top: 10px;
`

export const ConfirmButton = styled.button`
    background: ${purple};
    font-family: ${sourceSansPro};
    font-size: 0.9375em;
    font-weight: 600;
    cursor: pointer;
    color: ${white};
    width: 114px;
    height: 38px;
    border: none;
    margin-top: 33px;
    border-bottom: ${red} 3px;
    border-radius: 3px;
`

export const CancelButton = styled.button`
    background: ${purple};
    font-family: ${sourceSansPro};
    font-size: 0.9375em;
    font-weight: 600;
    cursor: pointer;
    color: ${white};
    width: 114px;
    height: 38px;
    border: none;
    margin-top: 8px;
    border-bottom: ${red} 3px;
    border-radius: 3px;
`

export default showBasicPopup;
export { showConfirmationPopup }