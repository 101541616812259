class TaskHelper {

    static removeTaskFromTaskList(taskToRemove, taskList) {
        return taskList.filter(task => task.id !== taskToRemove.id);
    }

    static copyTask(taskToCopy) {
        let taskCopy = Object.assign({}, taskToCopy)
        delete taskCopy.id
        taskCopy.title = '(KOPIE) ' + taskCopy.title
        taskCopy.isFinished = 0
        return taskCopy
    }

    static isSpareTime(task) {
        return (!task.examId && !task.homeworkItemId);
    }

}

export default TaskHelper;