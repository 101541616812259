import React from "react";
import Select, {components} from 'react-select'
import downChevron from "../resources/images/downChevron.svg";
import {red, sourceSansPro} from "../Theme";
import styled from "styled-components";

function Dropdown(props) {
    const defaultOption = props.options && props.defaultValue && props.options.find(o => o.value === props.defaultValue)
    const selectStyles = {
        menuPortal: base => ({...base, zIndex: 9999}),
        menu: provided => ({...provided, zIndex: "9999 !important"}),
        control: (styles, {isDisabled}) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                // This is an example: backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.5)' : 'white'
                backgroundColor: isDisabled ? '#efefef4D' : (props.selectedColor ? props.selectedColor  : 'white'),
                borderColor: props.hasError ? red : 'hsl(0, 0%, 80%)'
            }
        },
        valueContainer: (base) => ({
            ...base,
            paddingLeft: '11px',
            paddingRight: '11px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'black',
            textAlign: 'left',
        }),
        singleValue: (base) => ({
            ...base,
            fontFamily: sourceSansPro,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
        }),
    };
    return <StyledSelect
        isDisabled={props.disabled}
        hasError={props.hasError}
        styles={selectStyles}
        components={{
            DropdownIndicator,
            SingleValue,
            ValueContainer,
            IndicatorSeparator: undefined,
        }}
        register={props.register}
        placeholder={props.placeholder}
        isSearchable={false}
        value={defaultOption}
        options={props.options}
        onChange={(v) => {
            props.onChange(v.value)
        }}
    />
}

const ValueContainer = ({children, ...props}) => (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

const SingleValue = ({children, ...props}) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
);

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <DropdownIcon src={downChevron}/>
    </components.DropdownIndicator>
);

const DropdownIcon = styled.img`
    margin-right: 10px;
`;

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
`;

export default Dropdown;