import React, {useEffect, useState} from "react";
import {useGlobalState} from "../../../util/GlobalState";
import styled from "styled-components";
import {nunito, purple, purpleLighter, purpleLightest} from "../../../Theme";
import {useIsMounted} from "../../../components/hooks/IsMountedHook";
import close from "../../../resources/images/ic-close-big.svg"
import TabBar from "../../../components/tabbar/TabBar";
import {device} from "../../../constants/breakpoints";
import WhatsNewCard from "../../../components/WhatsNewCard";
import Api from "../../../util/Api";
import CommentCard from "../../../components/CommentCard";
import FeedbackForm from "./FeedbackForm";
import AppStorage, {StorageKey} from "../../../util/AppStorage";
import {UserType} from "../../../constants/enums";

function NotificationMenu(props) {
    const notificationMenuTabs = {
        COMMENT: "Opmerkingen",
        NEW: "What's new",
        FEEDBACK: "Feedback"
    }

    const tabs = [
        notificationMenuTabs.COMMENT,
        notificationMenuTabs.NEW,
        notificationMenuTabs.FEEDBACK,
    ];

    const isMounted = useIsMounted()
    const [isNative] = useGlobalState('isNative', false)
    const [overlayActive, setOverlayActive] = useGlobalState('overlayActive', false);
    const [selectedTab, setSelectedTab2] = useState(notificationMenuTabs.COMMENT);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (!isNative) {
            setOverlayActive(true)
        }
        getCommentsFromApi();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isMounted && !overlayActive) {
            props.onClose()
        }
        // eslint-disable-next-line
    }, [overlayActive])

    const userType = AppStorage.get(StorageKey.USER_TYPE)
    const userIsMentor = userType === UserType.MENTOR

    return (
        <NotificationMenuRoot addMentorPadding={userIsMentor} onClick={(e) => {
            e.stopPropagation()
        }}>
            <Header>
                <Title>Meldingen</Title>
                <CloseButton src={close} onClick={() => closeNotificationMenu()}/>
            </Header>
            <TabBarWrapper>
                <TabBar tabs={tabs} onSelectTab={(tab) => setSelectedTab2(tab)}/>
            </TabBarWrapper>
            {selectedTab === notificationMenuTabs.COMMENT && <ContentList addMentorPadding={userIsMentor}>
                {comments.map(comment => {
                    return <CommentCard
                        navigateToComment={props.navigateToComment}
                        key={comment.id}
                        comment={comment}/>
                })}
            </ContentList>}

            {selectedTab === notificationMenuTabs.NEW && <ContentList addMentorPadding={userIsMentor}>
                {props.whatsNewObjects.map(whatsNewObject => {
                        return whatsNewObject.items.map((whatsNewItem) => {
                            return <WhatsNewCard
                                key={whatsNewItem.id}
                                mediaUrl={whatsNewItem.mediaUrl}
                                title={whatsNewItem.title}
                                date={whatsNewItem.date}
                                text={whatsNewItem.text}
                            />
                        })
                    }
                )}
            </ContentList>}

            {selectedTab === notificationMenuTabs.FEEDBACK && <ContentList addMentorPadding={userIsMentor}>
                <FeedbackForm/>
            </ContentList>}
        </NotificationMenuRoot>
    )

    function getCommentsFromApi() {
        const config = {
            params: {
                'sort': '-created',
                'page[size]': 20,
                'page[number]': 1
            }
        }

        Api.get('comments', onSuccess, null, null, null, config);

        function onSuccess(response) {
            setComments(response.data);
        }
    }

    function closeNotificationMenu() {
        setOverlayActive(false)
        props.onClose()
    }
}

export const NotificationMenuRoot = styled.div`
    background: linear-gradient(${purpleLighter} 200px, ${purpleLightest} 200px);
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 375px;
    z-index: 250;
    padding: 12px 0 12px 17px;
    
    @media screen and ${device.tablet} {
        width: 100%;
    }
`;

export const Header = styled.div`
    width: 100%;
    position: relative;
`;

export const CloseButton = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: 0;
    cursor: pointer;
`;

export const Title = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 1.3125em;
    color: ${purple};
    text-align : center;
`;

export const TabBarWrapper = styled.div`
    margin: 23px 17px 10px 0;
`;

export const ContentList = styled.div`
    overflow: auto;
    position: absolute;
    bottom: 0;
    top: 110px;
    padding: 10px 0 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 342px;
    
    @media screen and ${device.tablet} {
        padding-bottom: 80px;
        width: calc(100% - 32px);
        ${props => props.addMentorPadding ? 'top: 110px' : ''}
    }
`;

export default NotificationMenu;