import {createRef} from "react";

/*
* This file contains functions that can be called by the native app's webView
* This is made possible because these functions are set to the Window object
*
* !!! IMPORTANT NOTICE !!!
* Because these functions are set to the Window object, anyone can call these from the console
* So be sure to be extremely cautious when creating new functions for the apps to use
* */

export function setHistoryRef(historyObject) {
    if(!historyRef.current) {
        historyRef.current = historyObject
    }
}

// Used for navigating through React with a Native application
window.pushHistoryObject = function (screenUrl) {
    historyRef.current.replace(screenUrl)
}

window.historyGoBack = function () {
    historyRef.current.goBack()
}

export const historyRef = createRef()


////////////////// Javascript -> Swift + Kotlin

export function hideNativeNavigation() {
    try {
        window.webkit.messageHandlers.toggleNativeNavigation.postMessage(false);
    } catch (e) {
        console.log("Could not interface with iOS application")
    }

    try {
        window.AppInterface.toggleNativeNavigation(false)
    } catch (e) {
        console.log("Could not interface with Android application")
    }
}

export function showNativeNavigation() {
    try {
        window.webkit.messageHandlers.toggleNativeNavigation.postMessage(true);
    } catch (e) {
        console.log("Could not interface with iOS application")
    }

    try {
        window.AppInterface.toggleNativeNavigation(true)
    } catch (e) {
        console.log("Could not interface with Android application")
    }
}