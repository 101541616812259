import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import {
    black,
    HeaderLogo,
    nunito,
    purple,
    purpleLight,
    red,
    SmallHeader,
    SmallHeaderTitle,
    sourceSansPro,
    white
} from "../../Theme";
import Api from "../../util/Api";
import styled from "styled-components";
import backLogoBlack from "../../resources/images/backBlack.svg";
import backLogoWhite from "../../resources/images/backWhite.svg";
import checkLogoWhite from "../../resources/images/checkWhite.svg";
import checkLogoBlack from "../../resources/images/checkBlack.svg";
import zoomLogo from "../../resources/images/zoom.svg";
import {darken, lighten} from 'polished';
import AppStorage, {StorageKey} from "../../util/AppStorage";
import showImagePopup from "../../popups/showImagePopup";
import {ContentWrapper, HeaderImage, HeaderImageWrapper} from "../more/MoreScreen";
import {device} from "../../constants/breakpoints";
import {useHistory} from "react-router-dom";
import {isInReadOnlyMode} from "../../App";
import {showNativeNavigation} from "../../AppInterface";

function ExecutiveFunctionScreen(props) {
    const history = useHistory();
    const [ef, setExecutiveFunction] = useState(history.location.state ? history.location.state.executiveFunction : null);
    const [efCheckedItems, setExecutiveFunctionCheckedItems] = useState([]);

    useEffect(() => {
        showNativeNavigation();
        getExecutiveFunction();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!!ef) {
            getExecutiveFunctionCheckItems();
        }
        // eslint-disable-next-line
    }, [ef]);

    const content = (
        <EFScreen backgroundColor={ef ? ef.color : purpleLight}>
            <SmallHeaderSpaced backgroundColor={ef ? darken(0.05, ef.color) : purple}>
                <HeaderLogo src={ef ? (ef.hasDarkText ? backLogoBlack : backLogoWhite) : backLogoWhite} onClick={() => {
                    history.goBack()
                }}/>
                <SmallHeaderTitle color={ef ? (ef.hasDarkText ? black : white) : white}>Executieve
                    Functies</SmallHeaderTitle>
            </SmallHeaderSpaced>
            <ContentWrapper>
                <HeaderImageWrapper>
                    <HeaderImage src={ef ? ef.imageUrl : undefined}/>
                    <ZoomImage src={zoomLogo} onClick={() => showImagePopup(ef.imageUrl)}/>
                </HeaderImageWrapper>
                {ef && <EFCardWrapper>
                    <EFCard>
                        <CardHeader>
                            <Icon backgroundColor={ef ? ef.color : purple}
                                  color={ef ? (ef.hasDarkText ? black : white) : white}>{ef ? ef.sortOrder + 1 : ''}</Icon>
                            <Title>{ef ? ef.title : 'Executieve Functie'}</Title>
                        </CardHeader>
                        <Subtitle>{ef && ef.subtitle}</Subtitle>
                        <CheckLists>
                            {ef && ef.checkLists.map((checkList) => {
                                return (
                                    <CheckList key={checkList.id}>
                                        <CheckListTitle>{checkList.title}</CheckListTitle>
                                        {checkList.checkItems.map((checkItem) => {
                                            const isChecked = !!efCheckedItems.find((item) => {
                                                return item.id === checkItem.id
                                            })
                                            return <CheckItem key={checkItem.id} updateCheckedItems={getExecutiveFunctionCheckItems} isChecked={isChecked} checkItem={checkItem} executiveFunction={ef}/>
                                        })}
                                    </CheckList>
                                )
                            })}
                        </CheckLists>
                    </EFCard>
                </EFCardWrapper>}
            </ContentWrapper>
        </EFScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"Executieve Functies"}
            content={content}
        />
    )

    function getExecutiveFunctionCheckItems() {
        const config = {
            params: {
                'filter[executiveFunctionId]': props.match.params.id,
            }
        }
        Api.get('pupils/' + AppStorage.get(StorageKey.PUPIL_ID) + "/checkItems", onSuccess, null, null, null, config);

        function onSuccess(response) {
            setExecutiveFunctionCheckedItems(response.data)
        }
    }

    function getExecutiveFunction() {
        Api.get('executiveFunctions/' + props.match.params.id, onSuccess);

        function onSuccess(response) {
            setExecutiveFunction(response.data)
        }
    }
}

function CheckItem(props) {
    return <CheckItemRoot onClick={toggleIsChecked} disabled={isInReadOnlyMode()}>
        <CheckItemCheckWrapper active={props.isChecked} color={props.executiveFunction.color}>
            {props.isChecked && <CheckItemCheck src={props.executiveFunction.hasDarkText ? checkLogoBlack : checkLogoWhite}/>}
        </CheckItemCheckWrapper>
        <CheckItemTitle>{props.checkItem.title}</CheckItemTitle>
    </CheckItemRoot>

    function toggleIsChecked() {
        if (props.isChecked) {
            Api.delete("pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/checkItems/" + props.checkItem.id, () => {
                props.updateCheckedItems()
            });
        } else {
            Api.put("pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/checkItems/", {id: props.checkItem.id}, () => {
                props.updateCheckedItems()
            });
        }
    }
}

export const ZoomImage = styled.img`
    src: ${props => props.src};
    cursor: pointer;
    width: 35px;
    height: 35px;
    padding: 8px;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 10;
    
    @media screen and ${device.mobileL} {
        top: 15px;
    }
    
`

export const CheckItemCheckWrapper = styled.div`
    background-color: ${props => props.active ? props.color : lighten(0.2, props.color)};
    min-width: 20px;
    min-height: 20px;
    min-height: 20px;
    max-height: 20px;
    border: 1px solid ${props => props.active ? props.color : lighten(0.15, props.color)};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckItemCheck = styled.img`
    width: 16px;
    height: 16px;
`;

export const CheckItemTitle = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: ${black};
`;

export const CheckLists = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 30px;
`

export const CheckItemRoot = styled.div`
    pointer-events: ${props => props.disabled ? 'none' : 'default'};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    cursor: pointer;
`;

const CheckListTitle = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: ${red};
`;

const EFCardWrapper = styled.div`
   max-width: 679px;
   margin: auto;
   padding: 0 20px;
   box-sizing: content-box;
`

const EFCard = styled.div`
   width: 100%;
   background: ${white};
   padding: 35px 40px;
   z-index: 10;
   position: relative;
   top: -110px;
   border-radius: 3px;
   border: 1px solid rgba(86, 89, 146, 0.1);
   box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
   
   @media screen and ${device.mobileL} {
        top: -40px;
        padding: 15px 20px;
   }
`;

const CheckList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Subtitle = styled.div`
    font-family: ${sourceSansPro};
    opacity: 0.7;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    margin-top: 17px;
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
`

const Icon = styled.div`
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`;

const Title = styled.div`
    font-family: ${nunito};
    color: black;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
`


const SmallHeaderSpaced = styled(SmallHeader)`
    padding-left: 20px;
    padding-right: 20px;
    
    div:last-of-type {
        margin-left: auto;
        margin-right: auto;
    }
`

const EFScreen = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background: ${props => props.backgroundColor}
`;

export default ExecutiveFunctionScreen;
