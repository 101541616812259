import {useEffect, useState} from 'react';
import Api from '../util/Api';
import {useAccessContext} from '../contexts/AccessContext';
import {FeatureFlags} from '../constants/featureFlags';
import {Loader} from '../components/Loader';

export function AccessGate({children, userIsAuthenticated}) {
    if (userIsAuthenticated) {
        return (
            <Authenticated>
                {children}
            </Authenticated>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

const Authenticated = ({children}) => {
    const [loading, setLoading] = useState(true);
    const {updateFeatureFlags} = useAccessContext();

    // When user has featureSchedule, update context
    useEffect(() => {
        Api.get("/access", (response) => {
            if (response?.data?.featureSchedule) {
                updateFeatureFlags([FeatureFlags.FEATURE_SCHEDULE]);
            }
            setLoading(false);
        }, null, null, () => {
            // no-op
            setLoading(false)
        })
        //eslint-disable-next-line
    }, []);

    if (loading) return <Loader />;

    return (
        <>
            {children}
        </>
    );
}