import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import {
    BasicGrid,
    black,
    nunito,
    purpleLighter,
    SmallHeader,
    SmallHeaderTitle,
    sourceSansPro,
    white,
} from "../../Theme";
import styled from "styled-components";
import {device} from "../../constants/breakpoints";
import Api from "../../util/Api";
import {darken} from 'polished';
import {useHistory} from "react-router-dom";
import {Loader} from "../../components/Loader";
import {showNativeNavigation} from "../../AppInterface";

function ExecutiveFunctionOverviewScreen(props) {
    const [executiveFunctions, setExecutiveFunctions] = useState([]);
    const history = useHistory();

    useEffect(() => {
        showNativeNavigation();
        Api.get(`executiveFunctions`, (response) => {
            setExecutiveFunctions(response.data)
        });
    }, []);

    const content = (
        <EFOverViewRoot>

            {executiveFunctions.length === 0 &&
                <Loader/>
            }

            <SmallHeader>
                <SmallHeaderTitle>Executieve Functies</SmallHeaderTitle>
            </SmallHeader>
            <EFGridWrapper>
                <BasicGrid>
                    {executiveFunctions.map((ef, index) => {
                        return  <EFCard key={ef.id} color={ef.color} onClick={() => navigateToExecutiveFunction(ef)}>
                                    <EFIcon color={ef.hasDarkText ? black : white}>{index + 1}</EFIcon>
                                    <EFTitle color={ef.hasDarkText ? black : white}>{ef.title}</EFTitle>
                                    <EFSubtitle color={ef.hasDarkText ? black : white}>{ef.subtitle}</EFSubtitle>
                                </EFCard>
                    })}
                </BasicGrid>
            </EFGridWrapper>
        </EFOverViewRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"Executieve Functies"}
            content={content}
        />
    )

    function navigateToExecutiveFunction(executiveFunction) {
        history.push("/executieve-functies/" + executiveFunction.id, {executiveFunction: executiveFunction})
    }
}

const EFOverViewRoot = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const EFGridWrapper = styled.div`
    margin: 0 auto;
    max-width: 1050px;
    padding: 40px;
    
    @media screen and ${device.laptop} {
        padding: 17px;
    }   
`;

const EFSubtitle = styled.div`
    color: ${props => props.color};
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
`;

const EFIcon = styled.div`
    color: ${props => props.color};
    background-color: rgba(0, 0, 0, 0.15);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    flex-shrink: 0;
`;

const EFTitle = styled.div`
    color: ${props => props.color};
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
`;

const EFCard = styled.a`
    background: ${props => props.color ? props.color : purpleLighter};
    box-shadow: 0px 2px 0px ${props => darken(0.05, props.color ? props.color : purpleLighter)};
    border-radius: 3px;
    height: 185px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    transition: all 0.15s ease;
    &:hover {
        transform: scale(1.05);
    }
`

export default ExecutiveFunctionOverviewScreen;
