import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import {BackgroundedScreen, Header, HeaderLogo, HeaderTitle} from "../../Theme";
import deleteLogo from "../../resources/images/delete.svg";
import backLogo from "../../resources/images/back.svg";
import '../datepicker.scss';
import HomeworkItemCard from "./HomeworkItemCard";
import Api from "../../util/Api";
import TaskListCard from "../TaskListCard";
import {CardList, HeaderAndListWrapper, Tab, TabWrapper} from "../exam/ExamScreen";
import {useHistory, useLocation} from "react-router-dom";
import {isInReadOnlyMode} from "../../App";
import CommentsCard from "../CommentsCard";
import {showNativeNavigation} from "../../AppInterface";

function HomeworkItemScreen(props) {
    const history = useHistory();
    const [homeworkItem, setHomeworkItem] = useState(history.location.state ? (history.location.state.homeworkItem ?? null) : null);
    const isMakingNewHomeworkItem = !props.match.params.id
    const [mobileActiveTab, setMobileActiveTab] = useState(0)
    const location = useLocation()
    const date = location.state ? location.state.date : null

    useEffect(() => {
        showNativeNavigation()
        if (props.match.params.id) {
            Api.post('homeworkItems/' + props.match.params.id + '/read')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMakingNewHomeworkItem) {
            getHomeworkItemFromApi();
        }
        // eslint-disable-next-line
    }, [props.match.params.id])

    const content = (
        <BackgroundedScreen>
            <HeaderAndListWrapper>
                <Header>
                    <HeaderLogo src={backLogo} onClick={() => {
                        history.goBack();
                    }}/>
                    <HeaderTitle>{homeworkItem ? homeworkItem.subject : "Huiswerk"}</HeaderTitle>
                    <HeaderLogo visibility={isMakingNewHomeworkItem || isInReadOnlyMode() ? 'hidden' : 'default'}
                                src={deleteLogo}
                                onClick={deleteHomeworkItem}/>
                </Header>
                <TabWrapper hidden={isMakingNewHomeworkItem}>
                    <Tab isActive={mobileActiveTab === 0} onClick={() => setMobileActiveTab(0)}>
                        Algemeen
                    </Tab>
                    <Tab isActive={mobileActiveTab === 1} onClick={() => setMobileActiveTab(1)}>
                        Planning
                    </Tab>
                    <Tab isActive={mobileActiveTab === 2} onClick={() => setMobileActiveTab(2)}>
                        Opmerkingen
                    </Tab>
                </TabWrapper>
                <CardList>
                    {(homeworkItem || isMakingNewHomeworkItem) &&
                        <>
                            <HomeworkItemCard
                                homeworkItem={homeworkItem}
                                onHomeworkItemChange={(updatedHomeworkItem) => setHomeworkItem(updatedHomeworkItem)}
                                hideOnMobile={mobileActiveTab !== 0}
                                date={date}
                                match={props?.match}
                                {...props}
                            />
                            {!isMakingNewHomeworkItem &&
                            <TaskListCard
                                homeworkItem={homeworkItem}
                                hideOnMobile={mobileActiveTab !== 1}
                                {...props}
                            />
                            }
                            {!isMakingNewHomeworkItem &&
                            <CommentsCard
                                homeworkItem={homeworkItem}
                                hideOnMobile={mobileActiveTab !== 2}
                                {...props}
                            />
                            }
                        </>
                    }
                </CardList>
            </HeaderAndListWrapper>
        </BackgroundedScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"Planagenda"}
            content={content}
        />
    )

    function getHomeworkItemFromApi() {
        Api.get('homeworkItems/' + props.match.params.id, onSuccess, null, null, () => history.replace('/'));

        function onSuccess(response) {
            setHomeworkItem(response.data);
        }
    }

    function deleteHomeworkItem() {

        Api.patch('homeworkItems/' + props.match.params.id, {isRemoved : 1}, onSuccess);

        function onSuccess(response) {
            history.replace('/')
        }
    }
}

export default HomeworkItemScreen;
