import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled from "styled-components";
import backLogo from "../../resources/images/back.svg";
import '../datepicker.scss';
import {device} from "../../constants/breakpoints";
import Api from "../../util/Api";
import {useHistory} from "react-router-dom";
import {PlanOfActionTabs} from "../../constants/enums";
import QuestionsListCard from "./QuestionsListCard";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {BackgroundedScreen, Header, HeaderLogo, HeaderTitle, nunito, purple, red, sourceSansPro, white} from "../../Theme";
import {Loader} from "../../components/Loader";
import {showNativeNavigation} from "../../AppInterface";
import {ExecutiveFunctionsCard} from './ExecutiveFunctionsCard';
import {PlanOfActionTabletFooter} from './PlanOfActionFooter';

function PlanOfActionScreen(props) {
    const history = useHistory();
    const [period, setPeriod] = useState(history.location.state ? history.location.state.period : null)
    const [answers, setAnswers] = useState(null);
    const [activeTab, setActiveTab] = useState(PlanOfActionTabs.EF)

    useEffect(() => {
        showNativeNavigation();
        getPeriod()
        getAnswers()
        // eslint-disable-next-line
    }, []);

    const content = (
        <BackgroundedScreen>
            {!period && !answers &&
                <Loader/>
            }

            {period && answers &&
                <HeaderListFooterWrapper>
                    <HeaderAndListWrapper>
                        <Wrapper>
                            <Header>
                                <HeaderLogo src={backLogo} onClick={() => {
                                    history.goBack();
                                }}/>

                                <HeaderTitle>{period && period.title}</HeaderTitle>

                                <HeaderLogo visibility={"hidden"}/>
                            </Header>
                            <TabWrapper>
                                <Tab isActive={activeTab === PlanOfActionTabs.EF}
                                     onClick={() => setActiveTab(PlanOfActionTabs.EF)}>
                                    {PlanOfActionTabs.EF}
                                </Tab>
                                <Tab isActive={activeTab === PlanOfActionTabs.PLANOFACTION}
                                     onClick={() => setActiveTab(PlanOfActionTabs.PLANOFACTION)}>
                                    {PlanOfActionTabs.PLANOFACTION}
                                </Tab>
                                <Tab isActive={activeTab === PlanOfActionTabs.EVALUATION}
                                     onClick={() => setActiveTab(PlanOfActionTabs.EVALUATION)}>
                                    {PlanOfActionTabs.EVALUATION}
                                </Tab>
                            </TabWrapper>
                        </Wrapper>
                        <>
                            <ExecutiveFunctionsCard
                                period={period}
                                activeTab={activeTab}
                                type={PlanOfActionTabs.EF}
                                {...props}
                            />
                            <QuestionsListCard
                                period={period}
                                answers={answers}
                                activeTab={activeTab}
                                type={PlanOfActionTabs.PLANOFACTION}
                                onSave={(answerData) => {
                                    saveAnswer(answerData)
                                }}
                                {...props}
                            />
                            <QuestionsListCard
                                period={period}
                                answers={answers}
                                activeTab={activeTab}
                                type={PlanOfActionTabs.EVALUATION}
                                onSave={(answerData) => {
                                    saveAnswer(answerData)
                                }}
                                {...props}
                            />
                        </>
                    </HeaderAndListWrapper>
                    <PlanOfActionTabletFooter {...props} />
                </HeaderListFooterWrapper>
            }
        </BackgroundedScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"EF in beeld"}
            content={content}
        />
    )

    function getPeriod() {
        const config = {
            params: {
                'context[personId]': AppStorage.get(StorageKey.PUPIL_ID) //call contains information generated on logged in member or context person
            }
        }

        Api.get('periods/' + props.match.params.id, onSuccess, null, null, null, config);

        function onSuccess(response) {
            setPeriod(response.data)
        }
    }

    function getAnswers() {
        const config = {
            params: {
                'filter[periodId]': props.match.params.id,
            }
        }

        Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/answers", (response) => {
            setAnswers(response.data);
        }, null, null, null, config);
    }

    function saveAnswer(answerData) {
        const config = {
            params: {
                'filter[periodId]': props.match.params.id,
            }
        }

        Api.put("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/answers/" + (answerData.id ? answerData.id : ""), answerData, null, null, null, null, config);
    }
}

export function SaveButton(props) {
    return (
        <SaveButtonRoot onClick={props.onClick}>
            <SaveButtonText>Opslaan</SaveButtonText>
        </SaveButtonRoot>
    );
}


export const Tab = styled.div`
    background: ${props => props.isActive ? red : 'transparent'};
    color: ${props => props.isActive ? white : purple};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
    font-size: 16px;
    font-family: ${nunito};
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
`;

const TabWrapper = styled.div`
    background: rgba(94, 65, 135, 0.07);
    height: 50px;
    width: 100%;
    border-radius: 3px;
    display: flex;
    margin-bottom: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    padding-right: 9px;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`;

const HeaderListFooterWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderAndListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px 16px 54px 16px;

    @media screen and ${device.tablet} {
        padding-bottom: 114px;
        flex: 1 1 0;
        overflow-y: auto;
    }

    @media screen and ${device.mobileL} {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 84px;
        box-sizing: border-box;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 590px;
`;

const SaveButtonRoot = styled.div`
    width: 125px;
    height: 35px;
    background: ${purple};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    align-self: flex-end;
    cursor: pointer;
`;

const SaveButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export default PlanOfActionScreen;