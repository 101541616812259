import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {black, nunito, purple, white} from "../../Theme";
import caretDownIcon from "../../resources/images/ic-caretDownBlack.svg";
import {useGlobalState} from "../../util/GlobalState";
import {isInReadOnlyMode} from "../../App";
import {device} from "../../constants/breakpoints";

function MentorScheduleDropdownButton(props) {
    const [overlayActive, setOverlayActive] = useGlobalState('overlayActive', false);
    const [active, setActive] = useState(false);
    const [selectedFilter, setSelectedFilter] = useGlobalState('selectedFilter', {id: null, title: "Alle"})

    useEffect(() => {
        if (!overlayActive) {
            setActive(false)
        }
    }, [overlayActive]);

    return (
        <MentorScheduleDropdownWrapper id={"mentorschedulewrapper"} active={active}>
            <ButtonBackground {...props}
                              onClick={!(isInReadOnlyMode() && props.items.length === 0) ? handleButtonClick : undefined}>
                    <ButtonText {...props}>
                        Klas:
                        {selectedFilter && (" " + selectedFilter.title)}
                    </ButtonText>
                {!(isInReadOnlyMode() && props.items.length === 0) &&
                    <ButtonIcon src={caretDownIcon} alt={"pijl icoon"}/>}
            </ButtonBackground>

            <DropdownItemList active={active}>
                {props.items.map((item) => {
                    return <DropdownItem key={item.id ?? "all"} onClick={() => {
                        handleDropdownItemClick(item)
                    }}>
                        <ItemTitle>
                            {item.title}
                        </ItemTitle>
                    </DropdownItem>
                })}
            </DropdownItemList>
        </MentorScheduleDropdownWrapper>
    );

    function handleButtonClick(event) {
        setActive(!active)
        setOverlayActive(!overlayActive)
        event.stopPropagation()
    }

    function handleDropdownItemClick(item) {
        setSelectedFilter(item)
    }
}

const ButtonBackground = styled.div`
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 0px 2px 0px #E2E2EB;
    border: 1px solid #E2E2EB;
    border-radius: 3px;
    cursor: ${props => props.onClick ? 'pointer' : 'initial'};
    width: fit-content; 
    max-width: 125px;
    
    @media screen and ${device.tablet} {
        width: 100%;
        max-width: 100%;
    }
`;

const ButtonText = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
    padding-left: 35px;
    gap: 7px;
    width: max-content;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
    min-width: 125px;
    max-width: 125px;

    @media screen and ${device.tablet} {
      overflow: visible;
      white-space: normal;
      word-break: normal;
      text-overflow: unset;
      min-width: 0;
      max-width: 100%;
    }
`;

const ButtonIcon = styled.img`
   padding: 10px 30px 10px 0;
`;

const MentorScheduleDropdownWrapper = styled.div`
    cursor: pointer;
    width: 24px;
    height: 30px;
    position: absolute;
    border-radius: 3px;
    background: white;
    margin-top: auto;
    margin-bottom: auto;
    right: 150px;
    z-index: ${props => props.active ? "101" : "0"};
    
     @media screen and ${device.laptopL} {
        right: 155px;
     }
    
     @media screen and ${device.laptop} {
        right: 170px;
    }

    @media screen and ${device.tablet} {
        position: relative;
        margin-top: 20px;
        width: 100%;
        right: 0;
    }
`;

const DropdownItemList = styled.div`
   flex-direction: column;
   gap: 5px;
   top: 36px;
   display: ${props => props.active ? "flex" : "none"};
   position: absolute;
   min-width: 125px;
   max-width: 125px;
   text-align: center;
   
   @media screen and ${device.tablet} {
        width: 100%;
        max-width: 100%;
    }
`;

const DropdownItem = styled.div`
   gap: 6px;
   background: ${purple};
   box-shadow: 0px 2px 0px #4D2F78;
   border-radius: 3px;
   padding: 5.5px 13px;
   cursor: pointer;
`;

const ItemTitle = styled.div`
   font-family: ${nunito};
   font-weight: 700;
   font-size: 16px;
   color: ${white};
`;


export default MentorScheduleDropdownButton;