import {useEffect, useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {nunito, purpleDark, red} from "../Theme";
import profileLogo from "../resources/images/pupil_profile.svg";
import chevronLeftLogo from "../resources/images/chevron_left.svg";
import calenderLogo from "../resources/images/calendar.svg";
import chartLogo from "../resources/images/chart.svg";
import bulbLogo from "../resources/images/bulb.svg";
import scienceLogo from "../resources/images/science.svg";
import infoLogo from "../resources/images/info.svg";
import logoutLogo from "../resources/images/logout.svg";
import opnijLogo from "../resources/images/logo.svg";
import menuBackground from "../resources/images/menuBackground.svg";
import AppStorage, {StorageKey} from "../util/AppStorage";
import {Link, useHistory, useLocation} from "react-router-dom";
import {isInReadOnlyMode, RoutesWithoutNav} from "../App";
import {UserType} from "../constants/enums";
import {useGlobalState} from "../util/GlobalState";
import {ReactComponent as PremiumIcon} from '../resources/images/premium.svg';
import {checkRequiredFeatureFlags} from '../util/Helpers';
import {FeatureFlags} from '../constants/featureFlags';
import {useAccessContext} from '../contexts/AccessContext';

function SideMenu() {
    const {featureFlags} = useAccessContext();
    const history = useHistory();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [isMentorView, setIsMentorView] = useGlobalState('isMentorView', false);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        const userType = AppStorage.get(StorageKey.USER_TYPE);
        const pupilID = AppStorage.get(StorageKey.PUPIL_ID);
        if (userType === UserType.MENTOR && pupilID === null && !location.pathname.startsWith("/mentor")) {
            history.push("/mentor/ef-voortgang");
        }
        if (userType === UserType.PUPIL && location.pathname.startsWith("/mentor")) {
            history.push("/ef-voortgang");
        }
        // eslint-disable-next-line
    })

    useEffect(() => {
        if (RoutesWithoutNav.includes(location.pathname)) {
            setVisible(false);
        } else {
            setVisible(true);
        }
        setCurrentLocation(location.pathname);
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (location?.pathname?.startsWith("/mentor/")) {
            setIsMentorView(true)
        } else {
            setIsMentorView(false)
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    return (
        <ThemeProvider theme={defaultSidebarTheme}>
            <SideMenuWrapper $visible={isVisible}>
                <MenuBackground src={menuBackground}/>
                <MenuLogo src={opnijLogo}/>
                {isInReadOnlyMode() && !isMentorView &&
                    <>
                        <MenuItemList>
                            <MenuItemLight
                                backToMentorSchedule={true}
                                size={"14px"}
                                paddingBottom={"20px"}
                                src={chevronLeftLogo}
                                menuClick={() => {
                                    clearPupil()
                                }}
                                text={"Planagenda"}
                                to={"/mentor/planagenda"}
                            />
                        </MenuItemList>
                        <MenuItemList key={0}>
                            <MenuPerson disableClick={true} src={profileLogo} to={"/"} text={AppStorage.get(StorageKey.PUPIL_TITLE)}/>
                            <MenuItemListSeparator key={1}/>
                        </MenuItemList>
                    </>

                }
                {isMentorView ? (
                    <MenuItemList>
                        <MenuItem
                            src={chartLogo}
                            text={"EF in beeld"}
                            isActive={currentLocation.startsWith("/mentor/ef-voortgang")}
                            to={"/mentor/ef-voortgang"}
                            location={location}
                        />
                        <MenuItem
                            src={calenderLogo}
                            text={"Planagenda"}
                            isActive={currentLocation === "/mentor/planagenda"}
                            to={"/mentor/planagenda"}
                            location={location}
                            showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                        />
                        <MenuItem
                            src={infoLogo}
                            text="Handleiding"
                            isActive={currentLocation === "/mentor/meer"}
                            to="/mentor/meer"
                            location={location}
                        />
                    </MenuItemList>
                ) : (
                    <MenuItemList>
                        <MenuItem
                            src={chartLogo}
                            text={"EF in beeld"}
                            isActive={currentLocation.includes("/plan-van-aanpak") || currentLocation.startsWith("/ef-voortgang")}
                            to={"/ef-voortgang"}
                            location={location}
                        />
                        <MenuItem
                            src={bulbLogo}
                            text={"Executieve Functies"}
                            isActive={currentLocation === "/executieve-functies"}
                            to={"/executieve-functies"}
                            location={location}
                        />
                        <MenuItem
                            src={calenderLogo}
                            text={"Planagenda"}
                            isActive={currentLocation === "/" || currentLocation === "/planagenda"}
                            to={"/planagenda"}
                            location={location}
                            showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                        />
                        <MenuItem
                            src={scienceLogo}
                            text={"Toetsweek"}
                            isActive={currentLocation === "/toetsweek"}
                            to={"/toetsweek"}
                            location={location}
                            showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                        />
                        <MenuItem
                            src={infoLogo}
                            text="Handleiding"
                            isActive={currentLocation === "/meer"}
                            to="/meer"
                            location={location}
                        />
                    </MenuItemList>
                )}

                <MenuItemList>
                    <LogOutButton src={logoutLogo} text={"Uitloggen"} to={"/login"} onClick={() => {
                        logoutAndGoToLogin(history)
                    }}/>
                </MenuItemList>
            </SideMenuWrapper>
        </ThemeProvider>
    )
}

export function loginAndContinueToNextScreen(data, history) {
    AppStorage.set(StorageKey.API_ACCESS_TOKEN, data.accessToken)
    AppStorage.set(StorageKey.USER_ID, data.id)
    AppStorage.set(StorageKey.FINISHED_ONBOARDING, data.hasFinishedOnboarding)
    AppStorage.set(StorageKey.USER_TYPE, data.type)
    if (data.type === UserType.PUPIL) {
        //login in as pupil
        AppStorage.set(StorageKey.PUPIL_ID, data.id)
    }

    const userType = AppStorage.get(StorageKey.USER_TYPE)
    if (userType === UserType.PUPIL) {
        history.replace("/ef-voortgang");
    } else if (userType === UserType.MENTOR) {
        history.replace("/mentor/ef-voortgang");
    }
}

export function logoutAndGoToLogin(history) {
    AppStorage.remove(StorageKey.USER_ID)
    AppStorage.remove(StorageKey.USER_TYPE)
    AppStorage.remove(StorageKey.API_ACCESS_TOKEN)
    AppStorage.remove(StorageKey.FINISHED_ONBOARDING)
    AppStorage.remove(StorageKey.PUPIL_ID)
    AppStorage.remove(StorageKey.PUPIL_TITLE)
    history.replace("/login")
}

function clearPupil() {
    AppStorage.remove(StorageKey.PUPIL_ID)
    AppStorage.remove(StorageKey.PUPIL_TITLE)
}

function MenuPerson(props) {
    return (
        <MenuPersonWrapper to={props.to} $backToMentorSchedule={props.backToMentorSchedule} $isActive={props.isActive} onClick={props.menuClick}>
            <MenuItemLogo src={props.src} $size={props.size}/>
            <MenuItemTextLight> {props.text}</MenuItemTextLight>
        </MenuPersonWrapper>
    );
}

function MenuItemLight(props) {
    return (
        <MenuItemWrapper to={props.to} $backToMentorSchedule={props.backToMentorSchedule} $isActive={props.isActive} $paddingBottom={props.paddingBottom} onClick={props.menuClick}>
            <MenuItemLogo src={props.src} $size={props.size}/>
            <MenuItemTextLight> {props.text}</MenuItemTextLight>
        </MenuItemWrapper>
    );
}

function MenuItem({showPremiumIcon, ...props}) {
    return (
        <MenuItemWrapper to={props.to} $isActive={props.isActive}>
            <MenuItemLogo src={props.src}/>
            <MenuItemTextWrapper>
                <MenuItemText> {props.text}</MenuItemText>
                {showPremiumIcon && <StyledPremiumIcon/>}
            </MenuItemTextWrapper>
        </MenuItemWrapper>
    );
}

function LogOutButton(props) {
    return (
        <LogOutButtonWrapper onClick={props.onClick}>
            <MenuItemLogo src={props.src}/>
            <MenuItemText> {props.text}</MenuItemText>
        </LogOutButtonWrapper>
    );
}

const defaultSidebarTheme = {
    outerWidth: "251px",
    innerWidth: "221px"
}

const SideMenuWrapper = styled.div`
    background: ${purpleDark};
    width: ${({theme}) => theme.outerWidth};
    height: 100%;
    display: ${props => props.$visible ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 53px;
    flex-shrink: 0;
    padding-bottom: 39px;
    overflow-y: auto;
    overflow-x: hidden;

    div:last-of-type {
        margin-top: auto;
    }
`;

const MenuItemListSeparator = styled.div`
    height: 0.5px;
    background-color: rgba(255, 255, 255, 0.8);
    width: ${({theme}) => theme.innerWidth};
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
`;
const MenuItemList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 10;
`;

const MenuLogo = styled.img`
    height: 179px;
    width: 188px;
    color: white;
    margin-bottom: 53px;
`;

const MenuBackground = styled.img`
    filter: opacity(7%);
    position: absolute;
    left: 0;
    bottom: 0;
    height: 179px;
    z-index: 1;
    width: 188px;
    color: white;
`;

const MenuItemWrapper = styled(Link)`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.$backToMentorSchedule ? "10px" : "25px"};
    height: 42px;
    width: ${({theme}) => theme.innerWidth};
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: ${props => props.$paddingBottom};
    text-overflow: ellipsis;
`;

const MenuPersonWrapper = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.$backToMentorSchedule ? "10px" : "25px"};
    height: 42px;
    width: ${({theme}) => theme.innerWidth};
    padding-left: 13px;
    padding-right: 13px;
    text-overflow: ellipsis;
`;

const LogOutButtonWrapper = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    height: 42px;
    width: ${({theme}) => theme.innerWidth};
    padding-left: 13px;
    padding-right: 13px;
    cursor: pointer;
`;

const MenuItemLogo = styled.img`
    color: white;
    display: block;
    height: ${props => props.$size ? props.$size : "24px"};
    width: ${props => props.$size ? props.$size : "24px"};
`;

const MenuItemTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MenuItemText = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
`;

const MenuItemTextLight = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledPremiumIcon = styled(PremiumIcon)`
    width: 24px;
    height: 24px;
    display: block;
    flex-shrink: 0;
`;

export default SideMenu;