import styled from 'styled-components';
import {device} from '../../constants/breakpoints';
import {black, purple, red, sourceSansPro, white} from '../../Theme';
import examOverviewDesktop from "../../resources/previews/exam_overview_desktop.jpg";
import examOverviewTablet from "../../resources/previews/exam_overview_tablet.jpg";
import examOverviewMobile from "../../resources/previews/exam_overview_mobile.jpg";
import scheduleDesktop from "../../resources/previews/schedule_desktop.jpg";
import scheduleTablet from "../../resources/previews/schedule_tablet.jpg";
import scheduleMobile from "../../resources/previews/schedule_mobile.jpg";
import teacherScheduleDesktop from "../../resources/previews/teacher_schedule_desktop.jpg";
import teacherScheduleTablet from "../../resources/previews/teacher_schedule_tablet.jpg";
import teacherScheduleMobile from "../../resources/previews/teacher_schedule_mobile.jpg";

const StyledFeaturePreview = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: ${({$feature}) => $feature === "examOverview" ? "top" : "top left"};
    background-image: ${({$feature}) => $feature === "examOverview" ? `url(${examOverviewDesktop})` : $feature === "teacherSchedule" ? `url(${teacherScheduleDesktop})` : `url(${scheduleDesktop})`};
    
    @media screen and ${device.laptop} {
        background-image: ${({$feature}) => $feature === "examOverview" ? `url(${examOverviewTablet})` : $feature === "teacherSchedule" ? `url(${teacherScheduleTablet})` : `url(${scheduleTablet})`};
    }

    @media screen and ${device.mobileL} {
        background-image: ${({$feature}) => $feature === "examOverview" ? `url(${examOverviewMobile})` : $feature === "teacherSchedule" ? `url(${teacherScheduleMobile})` : `url(${scheduleMobile})`};
    }
`;

const Inner = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const Notification = styled.div`
    max-width: 416px;
    background: ${white};
    padding: 30px 20px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-shadow: 0 2px 0 0 rgba(86, 89, 146, .1);
`;

const Title = styled.h2`
    margin: 0;
    text-align: center;
    font-family: ${sourceSansPro};
    font-size: 1.125em;
    font-weight: 600;
    color: ${red};
`;

const Text = styled.p`
    margin: 0;
    text-align: center;
    font-family: ${sourceSansPro};
    color: ${black};
    font-weight: 400;
`;

const Link = styled.a`
    text-align: center;
    background: ${purple};
    font-family: ${sourceSansPro};
    font-size: 0.9375em;
    font-weight: 600;
    cursor: pointer;
    color: ${white};
    padding: 8px 24px;
    margin-top: 10px;
    box-shadow: 0 2px 0 #4D2F78;
    border-radius: 3px;
    
    &:hover {
        color: ${white};
    }
`;

export function FeaturePreview({ feature = "schedule", text, link, linkText }) {
    return (
        <StyledFeaturePreview $feature={feature}>
            <Inner>
                <Notification>
                    <Title>Geen toegang</Title>
                    <Text>{text ?? "Je hebt geen toegang tot dit gedeelte van de EF Planagenda. Wil je wel je eigen planagenda hebben, jouw Executieve Functies in kunnen zien en toegang hebben tot meer informatie? Vraag dan om meer informatie aan je mentor."}</Text>
                    <Link href={link ?? "https://onderwijscentrumopnij.nl/digitale-ef-planagenda/"} target="_blank" rel="noreferrer">{linkText ?? "Lees meer"}</Link>
                </Notification>
            </Inner>
        </StyledFeaturePreview>
    );
}