import {useEffect, useState} from "react";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {UserType} from "../../constants/enums";

export function useUserType() {
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const currentUserType = AppStorage.get(StorageKey.USER_TYPE)
        setUserType(currentUserType)
    }, [])

    return userType;
}

export function useUserIsMentor() {
    const [isMentor, setIsMentor] = useState(false);

    useEffect(() => {
        const currentUserType = AppStorage.get(StorageKey.USER_TYPE)
        if (currentUserType === UserType.MENTOR) {
            setIsMentor(true)
        }
    }, [])

    return isMentor;
}

export function useUserIsGuardian() {
    const [isGuardian, setIsGuardian] = useState(false);

    useEffect(() => {
        const currentUserType = AppStorage.get(StorageKey.USER_TYPE)
        if (currentUserType === UserType.GUARDIAN) {
            setIsGuardian(true)
        }
    }, [])

    return isGuardian;
}

export function useUserIsPupil() {
    const [isPupil, setIsPupil] = useState(false);

    useEffect(() => {
        const currentUserType = AppStorage.get(StorageKey.USER_TYPE)
        if (currentUserType === UserType.PUPIL) {
            setIsPupil(true)
        }
    }, [])

    return isPupil;
}