import {useGlobalState} from '../../util/GlobalState';
import styled from 'styled-components';
import {purple, purpleLighter, sourceSansPro, white} from '../../Theme';
import {device} from '../../constants/breakpoints';
import forwardIcon from '../../resources/images/forwardWhite.svg';
import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';

function FooterContent(props) {
    const history = useHistory();

    return (
        <QuestionnaireButton onClick={() => history.push(`/ef-voortgang/${props.match.params.id}/vragenlijst`, {periodId: props.match.params.id})}>
            <QuestionnaireButtonText>Bekijk ingevulde vragenlijst</QuestionnaireButtonText>
            <QuestionnaireButtonIcon src={forwardIcon} alt={"Pijltje naar rechts"}/>
        </QuestionnaireButton>
    );
}

export function PlanOfActionDesktopFooter(props) {
    const [isNative] = useGlobalState('isNative', false)
    let matchMentorPlanofActionScreen = useRouteMatch("/mentor/ef-voortgang/:pupilId");
    if (matchMentorPlanofActionScreen) return null;

    return (
        <DesktopContentCardFooter isNative={isNative}>
            <FooterContent {...props} />
        </DesktopContentCardFooter>
    );
}

export function PlanOfActionTabletFooter(props) {
    const [isNative] = useGlobalState('isNative', false)
    let matchMentorPlanofActionScreen = useRouteMatch("/mentor/ef-voortgang/:pupilId");
    if (matchMentorPlanofActionScreen) return null;

    return (
        <TabletContentCardFooter isNative={isNative}>
            <FooterContent {...props} />
        </TabletContentCardFooter>
    );
}

const DesktopContentCardFooter = styled.div`
    width: 100%;
    height: 60px;
    background: ${purpleLighter};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 0 rgba(86, 89, 146, 0.1);
    border-radius: 0 0 3px 3px;
    margin-top: auto;

    @media screen and ${device.tablet} {
        display: none;
    }
`;

const TabletContentCardFooter = styled.div`
    display: none;

    @media screen and ${device.tablet} {
        width: 100%;
        height: 60px;
        background: ${purpleLighter};
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 0 rgba(86, 89, 146, 0.1);
        border-radius: 0 0 3px 3px;
        flex: 0 0 auto;
    }
`;

const QuestionnaireButton = styled.div`
    max-width: 313px;
    width: 100%;
    height: 37px;
    background: ${purple};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    box-shadow: 0 2px 0 #4D2F78;
    border-radius: 3px;
    cursor: pointer;
`;

const QuestionnaireButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

const QuestionnaireButtonIcon = styled.img`
    width: 17px;
`;
