import {Skills} from './enums';

export const skills = {
    [Skills.BEGINNER]: {
        key: Skills.BEGINNER,
        label: "Startend",
        shortLabel: "S",
        color: "#DD492F",
    },
    [Skills.DEVELOPING]: {
        key: Skills.DEVELOPING,
        label: "Ontwikkelend",
        shortLabel: "O",
        color: "#F28A03",
    },
    [Skills.MASTERED]: {
        key: Skills.MASTERED,
        label: "Beheerst",
        shortLabel: "B",
        color: "#7AB65C",
    }
}