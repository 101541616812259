import Select, {components} from 'react-select';
import caretDownIcon from '../../resources/images/ic-caretDownBlack.svg';
import {forwardRef} from 'react';
import {black, nunito, purple, white} from '../../Theme';

// Overwrite react-select components
const Control = ({children, ...props}) => (
    <components.Control {...props}>
        {children}
    </components.Control>
);

const Input = ({...props}) => (
    <components.Input {...props} aria-activedescendant={undefined} />
);

const IndicatorsContainer = ({...props}) => (
    <components.IndicatorsContainer {...props} />
);

const ClearIndicator = () => null;
const DropdownIndicator = () => null;
const IndicatorSeparator = () => null;
const MultiValueRemove = () => null;

export const BaseSelect = forwardRef(function BaseSelect(props, ref) {
    const {options, placeholder, onChange, defaultValue, disabled, ...otherProps} = props;

    return (
        <Select
            ref={ref}
            options={options}
            placeholder={placeholder}
            isMulti={false}
            isDisabled={disabled}
            isSearchable={false}
            isClearable={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => "Geen opties gevonden"}
            onChange={onChange}
            defaultValue={defaultValue}
            unstyled={true}
            components={{
                Control,
                Input,
                DropdownIndicator,
                IndicatorsContainer,
                IndicatorSeparator,
                MultiValueRemove,
                ClearIndicator
            }}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                }),
                control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    padding: "4px 40px 4px 17px",
                    borderRadius: "3px",
                    color: black,
                    fontFamily: nunito,
                    fontSize: "16px",
                    backgroundImage: `url(${caretDownIcon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 15px top 50%",
                    backgroundSize: "20px",
                    backgroundColor: white,
                    border: "1px solid #E2E2EB",
                    boxShadow: "0 2px 0 0 #E2E2EB",
                    textAlign: "left",
                    cursor: "pointer",
                }),
                placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: black,
                    opacity: ".3",
                    fontStyle: "italic",
                }),
                input: (baseStyles) => ({
                    ...baseStyles
                }),
                valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    gap: "10px",
                }),
                menu: (baseStyles, {options}) => ({
                    ...baseStyles,
                    background: options?.length > 0 ? "transparent" : white,
                    marginTop: "5px",
                    overflow: "hidden",
                    zIndex: "100",
                }),
                option: (baseStyles) => ({
                    ...baseStyles,
                    padding: "6px 12px",
                    borderRadius: "3px",
                    color: white,
                    fontFamily: nunito,
                    fontSize: "16px",
                    fontWeight: "700",
                    textAlign: "center",
                    background: purple,
                    boxShadow: "0 2px 0 0 #4D2F78",
                    marginBottom: "8px",
                    cursor: "pointer",
                }),
                noOptionsMessage: () => ({
                    color: black,
                    textAlign: "center",
                    fontFamily: nunito,
                    fontSize: "16px",
                    padding: "20px",
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                }),
                clearIndicator: (baseStyles) => ({
                    ...baseStyles
                })
            }}
            {...otherProps}
        />
    );
})