import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {black, nunito, purple, purpleLight, sourceSansPro, white, yellow} from "../../Theme";
import caretDownIcon from "../../resources/images/ic-caretDownBlack.svg";
import plusIcon from "../../resources/images/ic-plusWhite.svg";
import notificationIcon from "../../resources/images/ic-notification.svg";
import {DropDownType} from "../../constants/enums";
import {useGlobalState} from "../../util/GlobalState";
import {useHistory} from "react-router-dom";
import {isInReadOnlyMode} from "../../App";

function ScheduleDropdownButton(props) {
    const history = useHistory();
    const [overlayActive, setOverlayActive] = useGlobalState('overlayActive', false);
    const [active, setActive] = useState(false);
    const [itemIdsToBeNotifiedOf, setItemIdsToBeNotifiedOf] = useState([]);

    useEffect(() => {
        if (!overlayActive) {
            setActive(false)
        }
    }, [overlayActive]);

    useEffect(() => {
        setItemIdsToBeNotifiedOf(getItemIdsToBeNotifiedOf())
        // eslint-disable-next-line
    }, [props.items]);

    return (
        <ScheduleDropdownWrapper $active={active}>
            <ButtonBackground {...props}
                              onClick={!(isInReadOnlyMode() && props.items.length === 0) ? handleButtonClick : undefined}>
                <ButtonText {...props}>
                    {Object.values(DropDownType).includes(props.type) ? props.type : "Dropdown"}
                    <ItemAmount>({props.items.length})</ItemAmount>
                    <NotificationIcon $display={itemIdsToBeNotifiedOf.length !== 0} src={notificationIcon}/>
                </ButtonText>
                {!(isInReadOnlyMode() && props.items.length === 0) &&
                <ButtonIcon src={caretDownIcon} alt={"pijl icoon"}/>}
            </ButtonBackground>

            <DropdownItemList $active={active}>
                {props.items.map((item) => {
                    return <DropdownItem key={item.id} onClick={() => {
                        handleDropdownItemClick(item)
                    }}>
                        <TitleContainer>
                            <ItemTitle>
                                {item.subject}
                            </ItemTitle>
                            <NotificationIcon $display={!!itemIdsToBeNotifiedOf.find((i) => {
                                return i === item.id
                            })} src={notificationIcon}/>
                        </TitleContainer>
                        <ItemParagraph>
                            <ItemParagraphTitle>Hoofdstukken:</ItemParagraphTitle>
                            {item.chapters}
                        </ItemParagraph>

                        <ItemParagraph>
                            <ItemParagraphTitle>Paragrafen:</ItemParagraphTitle>
                            {item.paragraphs}
                        </ItemParagraph>
                    </DropdownItem>
                })}

                {!isInReadOnlyMode() && <AddButton onClick={() => handleAddButtonClick()}>
                    <img src={plusIcon} alt="plus icoon"/>
                    <AddButtonText>
                        {props.type === DropDownType.EXAM ? "Nieuwe toets toevoegen" : "Nieuw huiswerk toevoegen"}
                    </AddButtonText>
                </AddButton>}
            </DropdownItemList>

        </ScheduleDropdownWrapper>
    );

    function getItemIdsToBeNotifiedOf() {
        let itemIds = []
        for (let i = 0; i < props.items.length; i++) {
            if (props.items[i].taskAmount === 0 || props.items[i].notificationCount > 0) {
                itemIds.push(props.items[i].id)
            }
        }
        return itemIds
    }

    function handleButtonClick(event) {
        setActive(!active)
        setOverlayActive(!overlayActive)
        event.stopPropagation()
    }

    function handleAddButtonClick() {
        if (props.type === DropDownType.EXAM) {
            history.push("/toets", {date: props.date});
        } else if (props.type === DropDownType.HOMEWORK) {
            history.push("/huiswerk", {date: props.date});
        }
    }

    function handleDropdownItemClick(item) {
        if (props.type === DropDownType.EXAM) {
            history.push("/toets/" + item.id, {exam: item});
        } else if (props.type === DropDownType.HOMEWORK) {
            history.push("/huiswerk/" + item.id, {homeworkItem: item});
        }
    }
}


export const ButtonBackground = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${props => props.primary ? yellow : purpleLight};
    box-shadow: ${props => props.primary ? "0px 2px 0px #F4D05F" : "0px 2px 0px #D3CDF9"};
    border-radius: 3px;
    flex-grow: 1;
    cursor: ${props => props.onClick ? 'pointer' : 'initial'};
`;

export const ButtonText = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
    padding-left: 17px;
    display: flex;
    gap: 7px;
`;

export const ButtonIcon = styled.img`
   padding: 10px;
`;

export const ScheduleDropdownWrapper = styled.div`
   flex: 1;
   position: relative;
   z-index: ${props => props.$active ? "110" : "0"};
`;

export const DropdownItemList = styled.div`
   flex-direction: column;
   gap: 4px;
   display: ${props => props.$active ? "flex" : "none"};
   position: absolute;
   top: 50px;
   width: 100%;
`;

export const DropdownItem = styled.div`
   gap: 3px;
   background: ${white};
   border-radius: 3px;
   padding: 16px 13px;
   cursor: pointer;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-content: center;
    margin-bottom: 8px;
`;

export const ItemTitle = styled.div`
   font-family: ${nunito};
   font-weight: 700;
   font-size: 16px;
   color: ${black};
`;

export const ItemParagraph = styled.p`
   font-family: ${sourceSansPro};
   font-weight: 400;
   font-size: 14px;
   color: ${black};
   margin: 0 0 5px 0;
`;

export const ItemParagraphTitle = styled.span`
   opacity: 0.6;
   margin-right: 3px;
`;

export const AddButton = styled.span`
   display: flex;
   flex-direction: row;
   gap: 7px;
   justify-content: center;
   align-items: center;
   background: ${purple};
   box-shadow: 0px 2px 0px #4D2F78;
   border-radius: 3px;
   width: 100%;
   height: 35px;
   &:hover{
      cursor: pointer;
   }
`;

export const AddButtonText = styled.span`
   font-family: ${sourceSansPro};
   font-weight: 600;
   font-size: 15px;
   color: ${white};
`;

export const ItemAmount = styled.span`
   opacity: 0.5;
`;

export const NotificationIcon = styled.img`
    align-self: center;
    display: ${props => props.$display ? "block" : "none"};
`;


export default ScheduleDropdownButton;