import React from "react";
import styled from "styled-components";
import {black, nunito, purpleDark, red, sourceSansPro, white} from "../Theme";
import {SupportedVideoFormats} from "../constants/enums";
import moment from "moment";

function WhatsNewCard(props) {

    return (
        <WhatsNewCardRoot popUpVariant={props.popUpVariant}>
            <MediaContainer>
                {props.mediaUrl && isMediaTypeVideo(props.mediaUrl)
                    ?
                    <Video controls>
                        <source src={props.mediaUrl}/>
                    </Video>
                    :
                    <Image src={props.mediaUrl}/>

                }
            </MediaContainer>
            <TextContainer>
                <Title>
                    {props.title}
                </Title>
                <Date>
                    {moment(props.date).format('D MMMM YYYY')}
                </Date>
                <Text>
                    {props.text}
                </Text>
            </TextContainer>
        </WhatsNewCardRoot>
    )

    function isMediaTypeVideo(mediaUrl) {
        let mediaIsVideo = false
        SupportedVideoFormats.forEach(format => {
            if (mediaUrl.toLowerCase().includes(format)) {
                mediaIsVideo = true
            }
        })
        return mediaIsVideo
    }
}

export default WhatsNewCard;

const WhatsNewCardRoot = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${props => props.popUpVariant ? 'unset' : '342px'};
    width: 100%;
    background: ${white};
    border: ${props => props.popUpVariant ? '0' : '1px solid rgba(86, 89, 146, 0.1)'};
    border-bottom: ${props => props.popUpVariant ? '0' : '2px solid rgba(86, 89, 146, 0.1)'};
    border-radius: 3px;
`;

const MediaContainer = styled.div`
    height: 225px;
    width: 100%;
    object-fit: cover;
    position: relative;
`;

const Title = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 18px;
    color: ${red};
    margin-bottom: 10px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid rgba(86, 89, 146, 0.1);
`;

const Date = styled.div`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 14px;
    color: ${purpleDark};
    opacity: 0.5;
    margin-bottom: 10px;
`;

const TextContainer = styled.div`
    padding: 17px;
    text-align: left;
`;

const Text = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black}
`;