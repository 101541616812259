import styled from 'styled-components';
import React from "react";
import {Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer} from "recharts";
import {nunito, purpleDark, sourceSansPro, violet} from '../../Theme';

const CustomPolarAngleAxis = styled.text`
    fill: ${purpleDark};
    font-size: 12px;
    font-weight: 700;
    font-family: ${nunito};

    @container (max-width: 500px) { font-size: 10px; }
    @container (max-width: 300px) { font-size: 8px; }
    @container (max-width: 260px) { font-size: 6px; }
`;

const CustomPolarRadiusAxis = styled(CustomPolarAngleAxis)`
    fill: #DDDCEC;
    font-family: ${sourceSansPro};
`;

export const ProgressRadarChart = ({data}) => {
    if (!data?.scoresPerExecutiveFunction) return null;

    const renderCustomPolarAngleAxis = ({ x, y, payload, textAnchor }) => {
        return (
            <CustomPolarAngleAxis x={x} y={y} textAnchor={textAnchor}>
                {payload.value}
            </CustomPolarAngleAxis>
        );
    };

    const renderCustomPolarRadiusAxis = ({ x, y, payload, textAnchor }) => {
        return (
            <CustomPolarRadiusAxis x={x} y={y} textAnchor={textAnchor} dy={12}>
                {payload.value}
            </CustomPolarRadiusAxis>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RadarChart
                cx="50%"
                cy="50%"
                outerRadius="60%"
                data={data?.scoresPerExecutiveFunction}
            >
                <PolarGrid radialLines={false} stroke="#DDDCEC" strokeWidth={2} />
                <PolarAngleAxis dataKey="title" tick={renderCustomPolarAngleAxis} />
                <PolarRadiusAxis orientation="middle" angle={90} axisLine={false} tick={renderCustomPolarRadiusAxis} />
                <Radar
                    name="Executieve Functies"
                    dataKey="totalScore"
                    stroke={violet}
                    strokeWidth={3}
                    fill={violet}
                    fillOpacity={0.3}
                    dot={{ r: 2, fill: '#B998B9', stroke: '#B998B9', strokeWidth: 6 }}
                />
            </RadarChart>
        </ResponsiveContainer>
    );
}