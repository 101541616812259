import {createContext, useContext, useState} from 'react';

const AccessContext = createContext(null);

export const AccessProvider = ({children}) => {
    const [featureFlags, setFeatureFlags] = useState([]);

    const updateFeatureFlags = (data) => {
        setFeatureFlags((prevValues) => ([
            ...prevValues, ...data
        ]))
    }

    return (
        <AccessContext.Provider
            value={{
                featureFlags,
                setFeatureFlags,
                updateFeatureFlags
            }}
        >
            {children}
        </AccessContext.Provider>
    );
}

export const useAccessContext = () => useContext(AccessContext);