import GlobalLoader from "../GlobalLoader";
import loaderImage from "../resources/images/loader.svg";
import {nunito, purple} from "../Theme";
import styled from 'styled-components';

export const Loader = () => {
    return (
        <GlobalLoader
            image={loaderImage}
            imageWidth={"80px"}
            imageWidthMobile={"40px"}
            text={"Laden..."}
            textFont={nunito}
            textSize={"21px"}
            textSizeMobile={"15px"}
            textWeight={"600"}
            textColor={purple}
            textImageGap={"10px"}
            textImageGapMobile={"5px"}
            delay={true}
        />
    )
};

export const LoaderOverlay = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
`;

export const ConfigurableLoader = ({className, ...props}) => {
    return (
        <GlobalLoader
            className={className}
            image={loaderImage}
            imageWidth={"80px"}
            imageWidthMobile={"40px"}
            textFont={nunito}
            textSize={"21px"}
            textSizeMobile={"15px"}
            textWeight={"600"}
            textColor={purple}
            textImageGap={"10px"}
            textImageGapMobile={"5px"}
            {...props}
        />
    )
};