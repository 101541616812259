import styled from 'styled-components';
import {HiddenLabel, SingleRow} from '../teacherschedule/Partials/SingleRow';
import {Skills} from '../../constants/enums';
import {Container, EmptyState, EmptyStateTitle, getMaxScore, getSkills, Record, Records, RecordTitle, ResultsWrapper, Rows, RowWrapper, ScoringLabel, ScoringLabelBox, ScoringLabels, ScoringLabelsBar, ScoringLabelsRow, ScrollableContainer} from '../teacherschedule/TeacherScheduleScreen';

const StyledContainer = styled(Container)`
    padding: 0;
    margin-top: 20px;
`;

const StyledResultsWrapper = styled(ResultsWrapper)`
    margin-top: unset;
`;

const StyledRecordTitle = styled(RecordTitle).attrs({
    as: "p"
})`
    text-decoration: none;
    cursor: unset;
`;

export const AllPeriodsCard = ({allPeriods, isLoadingNewAllPeriods}) => {
    return (
        <StyledContainer>
            <ScrollableContainer>
                <StyledResultsWrapper>
                    {allPeriods?.length > 0 ? (
                        <>
                            <Records>
                                {allPeriods?.map(ef => (
                                    <Record key={ef.id}>
                                        <StyledRecordTitle>
                                            {ef.title}
                                        </StyledRecordTitle>
                                        <Rows>
                                            {ef?.periods?.map((period, i, {length}) => (
                                                <RowWrapper key={period.id}>
                                                    <SingleRow
                                                        periodData={period}
                                                        selectedEfId={null}
                                                        data={null}
                                                        isPupilDetail={true}
                                                        isLast={i + 1 === length}
                                                    />
                                                </RowWrapper>
                                            ))}
                                        </Rows>
                                    </Record>
                                ))}
                            </Records>

                            <ScoringLabels>
                                <ScoringLabelsRow>
                                    <HiddenLabel />
                                    <ScoringLabelsBar $maxScore={getMaxScore(allPeriods)}>
                                        <ScoringLabelBox $column={getSkills(allPeriods)?.[Skills.BEGINNER]?.column}>
                                            <ScoringLabel>{getSkills(allPeriods)?.[Skills.BEGINNER]?.label}</ScoringLabel>
                                        </ScoringLabelBox>
                                        <ScoringLabelBox $column={getSkills(allPeriods)?.[Skills.DEVELOPING]?.column}>
                                            <ScoringLabel>{getSkills(allPeriods)?.[Skills.DEVELOPING]?.label}</ScoringLabel>
                                        </ScoringLabelBox>
                                        <ScoringLabelBox $column={getSkills(allPeriods)?.[Skills.MASTERED]?.column}>
                                            <ScoringLabel>{getSkills(allPeriods)?.[Skills.MASTERED]?.label}</ScoringLabel>
                                        </ScoringLabelBox>
                                    </ScoringLabelsBar>
                                </ScoringLabelsRow>
                            </ScoringLabels>
                        </>
                    ) : (
                        <EmptyState>
                            {!isLoadingNewAllPeriods &&
                                <>
                                    <EmptyStateTitle>Geen resultaten gevonden</EmptyStateTitle>
                                    <p>Er zijn geen resultaten gevonden. Probeer de filters te wijzigen.</p>
                                </>
                            }
                        </EmptyState>
                    )}
                </StyledResultsWrapper>
            </ScrollableContainer>
        </StyledContainer>
    );
}