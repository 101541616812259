import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import styled, {css} from "styled-components";
import backLogo from "../../resources/images/back.svg";
import '../datepicker.scss';
import {device} from "../../constants/breakpoints";
import Api from "../../util/Api";
import {useHistory, useParams} from "react-router-dom";
import {PlanOfActionTabs} from "../../constants/enums";
import QuestionsListCard from "./QuestionsListCard";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {BackgroundedScreen, Header, HeaderLogo, HeaderTitle, nunito, purple, red, white} from "../../Theme";
import {ConfigurableLoader, Loader, LoaderOverlay} from "../../components/Loader";
import {showNativeNavigation} from "../../AppInterface";
import {ExecutiveFunctionsCard} from './ExecutiveFunctionsCard';
import {PlanOfActionTabletFooter} from './PlanOfActionFooter';
import {BaseSelect} from "../../components/BaseSelect/BaseSelect";
import showBasicPopup from "../../popups/showBasicPopup";
import {useQuery} from '../../App';
import qs from 'qs';
import {EmptyStateCard} from './EmptyStateCard';
import {AllPeriodsCard} from './AllPeriodsCard';

// MentorPlanOfActionScreen has duplicate code with PlanOfActionScreen, but is seperated for readability because mentor includes filtering logic
function MentorPlanOfActionScreen(props) {
    const query = useQuery()
    const history = useHistory();
    const {pupilId} = useParams();
    const periodId = query.get("period");

    // States for initial data
    const [isLoadingPeriodFilterOptions, setIsLoadingPeriodFilterOption] = useState(false);
    const [periodFilterOptions, setPeriodFilterOptions] = useState(null);
    const [isLoadingPupil, setIsLoadingPupil] = useState(false);
    const [pupil, setPupil] = useState(null);

    // States for data that gets refetched on filtering
    const [isLoadingNewPeriod, setIsLoadingNewPeriod] = useState(false);
    const [period, setPeriod] = useState(null);
    const [isLoadingNewAnswers, setIsLoadingNewAnswers] = useState(false);
    const [answers, setAnswers] = useState(null);
    const [isLoadingNewAllPeriods, setIsLoadingNewAllPeriods] = useState(false);
    const [allPeriods, setAllPeriods] = useState(null);

    const [showEmptyState, setShowEmptyState] = useState(false);
    const [activeTab, setActiveTab] = useState(PlanOfActionTabs.EF)

    // Get filter options and
    useEffect(() => {
        const controller = new AbortController();
        getPupil(controller.signal)
        getFilterOptions(controller.signal);
        showNativeNavigation();
        return () => controller.abort();
        //eslint-disable-next-line
    }, []);

    // Default option for the period filter
    const getDefaultPeriodOption = () => periodId ? (periodFilterOptions?.find(filterItem => filterItem.value === periodId) || periodFilterOptions?.[0]) : periodFilterOptions?.[0];

    // When selecting a period, add the correct parameter
    const handleSelectOption = (periodId) => {
        const updatedParams = {period: periodId}
        const queryString = qs.stringify(updatedParams, {skipNulls: true});

        history.replace({search: queryString});
    }

    // After filterOptions have been fetched and also when selecting a new period, fetch the data with filters
    useEffect(() => {
        const controller = new AbortController();

        if (periodFilterOptions) {
            // If there is no periodId, user selected "Alle periodes"
            if (!periodId) {
                setPeriod(null);
                setAnswers(null);
                getAllPeriods(controller.signal, periodFilterOptions)
                return;
            }

            getPeriod(controller.signal)
            getAnswers(controller.signal)
        }
        return () => controller.abort();
        //eslint-disable-next-line
    }, [periodFilterOptions, periodId]);

    const content = (
        <BackgroundedScreen>
            {(!isLoadingPeriodFilterOptions && !isLoadingPupil) ? (
                <HeaderListFooterWrapper>
                    <HeaderAndListWrapper $showAllPeriods={!periodId}>
                        <Wrapper>
                            <Header>
                                <HeaderLogo src={backLogo} onClick={() => {
                                    history.goBack();
                                }}/>

                                <HeaderTitle>{pupil?.title}</HeaderTitle>

                                <HeaderLogo visibility={"hidden"}/>
                            </Header>
                            <TabWrapper>
                                <InnerTabWrapper>
                                    <>
                                        <Tab isActive={activeTab === PlanOfActionTabs.EF}
                                             onClick={() => setActiveTab(PlanOfActionTabs.EF)}>
                                            {PlanOfActionTabs.EF}
                                        </Tab>
                                        {(periodId && !showEmptyState) &&
                                            <>
                                                <Tab isActive={activeTab === PlanOfActionTabs.PLANOFACTION}
                                                     onClick={() => setActiveTab(PlanOfActionTabs.PLANOFACTION)}>
                                                    {PlanOfActionTabs.PLANOFACTION}
                                                </Tab>
                                                <Tab isActive={activeTab === PlanOfActionTabs.EVALUATION}
                                                     onClick={() => setActiveTab(PlanOfActionTabs.EVALUATION)}>
                                                    {PlanOfActionTabs.EVALUATION}
                                                </Tab>
                                            </>
                                        }
                                    </>
                                    {((activeTab === PlanOfActionTabs.EF) || showEmptyState) &&
                                        <Filter>
                                            {periodFilterOptions?.length > 0 && (
                                                <FilterBox $basis="160px">
                                                    <BaseSelect
                                                        options={periodFilterOptions}
                                                        placeholder="Periodes"
                                                        onChange={(selectedOption) => handleSelectOption(selectedOption.value)}
                                                        defaultValue={getDefaultPeriodOption}
                                                        menuPosition="fixed"
                                                    />
                                                </FilterBox>
                                            )}
                                        </Filter>
                                    }
                                </InnerTabWrapper>
                            </TabWrapper>
                        </Wrapper>
                        {showEmptyState ? (
                            <CardWrapper>
                                <EmptyStateCard />
                            </CardWrapper>
                        ) : (period && answers) ? (
                            <CardWrapper $wide={activeTab === PlanOfActionTabs.EF}>
                                <ExecutiveFunctionsCard
                                    period={period}
                                    activeTab={activeTab}
                                    type={PlanOfActionTabs.EF}
                                    {...props}
                                />
                                <QuestionsListCard
                                    period={period}
                                    answers={answers}
                                    activeTab={activeTab}
                                    type={PlanOfActionTabs.PLANOFACTION}
                                    onSave={(answerData) => {
                                        saveAnswer(answerData)
                                    }}
                                    {...props}
                                />
                                <QuestionsListCard
                                    period={period}
                                    answers={answers}
                                    activeTab={activeTab}
                                    type={PlanOfActionTabs.EVALUATION}
                                    onSave={(answerData) => {
                                        saveAnswer(answerData)
                                    }}
                                    {...props}
                                />
                                {(isLoadingNewAnswers || isLoadingNewPeriod) &&
                                    <LoaderOverlay>
                                        <StyledLoader delay={false} />
                                    </LoaderOverlay>
                                }
                            </CardWrapper>
                        ) : !periodId ? (
                            <>
                                <AllPeriodsCard allPeriods={allPeriods} isLoadingNewAllPeriods={isLoadingNewAllPeriods} />
                            </>
                        ) : null}
                    </HeaderAndListWrapper>
                    <PlanOfActionTabletFooter {...props} />
                </HeaderListFooterWrapper>
            ) : (
                <Loader/>
            )}
        </BackgroundedScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"EF in beeld"}
            content={content}
        />
    )

    function getPupil(signal) {
        setIsLoadingPupil(true);

        Api.get(`pupils/${pupilId}`, (response) => {
            setPupil(response.data)
            setIsLoadingPupil(false);
        }, null, null, () => {
            setIsLoadingPupil(false)
            showBasicPopup("Ophalen van leerling mislukt!", "Er is iets mis gegaan met het ophalen van de leerling. Probeer het zometeen nog een keer", "OK")
        }, {signal})
    }

    function getPeriod(signal) {
        setIsLoadingNewPeriod(true);

        const config = {
            params: {
                'context[personId]': pupilId,
                signal
            }
        }

        Api.get(`periods/${periodId}`, (response) => {
            setPeriod(response?.data)
            setIsLoadingNewPeriod(false);
        }, null, null, () => {
            setIsLoadingNewPeriod(false);
            showBasicPopup("Ophalen van periode mislukt!", "Er is iets mis gegaan met het ophalen van de periode. Probeer het zometeen nog een keer.", "OK")
        }, config);
    }

    // Helper function to transform the data of 4 periods in the correct format for AllPeriodsCard
    function transformData(periodsData) {
        const executiveFunctionMap = new Map(); // Map to store executive functions by ID

        periodsData.forEach(period => {
            const { id: periodId, title: periodTitle, beginner, developing, mastered, scoresPerExecutiveFunction } = period;

            scoresPerExecutiveFunction.forEach(score => {
                const { id, title, totalScore = null } = score;

                // Find or create the executive function obj
                if (!executiveFunctionMap.has(id)) {
                    executiveFunctionMap.set(id, { id, title, periods: [] });
                }

                // Push data in the correct executive function obj
                executiveFunctionMap.get(id).periods.push({
                    id: periodId,
                    title: periodTitle,
                    beginner,
                    developing,
                    mastered,
                    totalScore
                });
            });
        });

        // Convert map values to an array
        return Array.from(executiveFunctionMap.values());
    }

    // When selecting "All periods" fetch 4 periods and convert the data
    function getAllPeriods(signal, periods) {
        setIsLoadingNewAllPeriods(true);

        const allPeriods = periods?.slice(1);

        const config = {
            params: {
                'context[personId]': pupilId,
                signal
            }
        }

        const periodResults = [];
        let completedCalls = 0;

        function handleResponse(index, response) {
            periodResults[index] = response.data;
            completedCalls++;

            if (completedCalls === allPeriods.length) {
                const transformedData = transformData(periodResults);
                setAllPeriods(transformedData);
                setIsLoadingNewAllPeriods(false);
                setShowEmptyState(false);
            }
        }

        allPeriods.forEach((period, index) => {
            Api.get(
                `periods/${period.value}`, (response) => {
                    handleResponse(index, response)
                }, null, null, () => {
                    setIsLoadingNewAllPeriods(false);
                    showBasicPopup("Ophalen van periodes mislukt!", "Er is iets mis gegaan met het ophalen van de periodes. Probeer het zometeen nog een keer.", "OK")
                }, config
            )
        })
    }

    function getAnswers(signal) {
        setIsLoadingNewAnswers(true);

        const config = {
            params: {
                'filter[periodId]': periodId,
                signal
            }
        }

        Api.get(`/pupils/${pupilId}/answers`, (response) => {
            setIsLoadingNewAnswers(false);

            // No answer data, so show empty state
            if (response.data.length <= 0) {
                return setShowEmptyState(true);
            }

            setAnswers(response.data);
            setShowEmptyState(false);
        }, null, null, () => {
            setIsLoadingNewAnswers(false)
            showBasicPopup("Ophalen van antwoorden mislukt!", "Er is iets mis gegaan met het ophalen van de antwoorden. Probeer het zometeen nog een keer.", "OK")
        }, config);
    }

    function saveAnswer(answerData) {
        const config = {
            params: {
                'filter[periodId]': periodId,
            }
        }

        Api.put(`/pupils/${pupilId}/answers/${(answerData.id ? answerData.id : "")}`, answerData, null, null, null, null, config);
    }

    function getFilterOptions(signal) {
        setIsLoadingPeriodFilterOption(true);

        Api.get(`persons/${AppStorage.get(StorageKey.USER_ID)}/progress/filterOptions`, (response) => {
            const extendedPeriods = {
                ...response?.data,
                periods: [
                    {value: null, label: "Alle periodes"}, ...response?.data?.periods,
                ]
            }
            setPeriodFilterOptions(extendedPeriods?.periods)
            setIsLoadingPeriodFilterOption(false)
        }, null, null, () => {
            setIsLoadingPeriodFilterOption(false)
            showBasicPopup("Ophalen van filter opties mislukt!", "Er is iets mis gegaan met het ophalen van de filter opties. Probeer het zometeen nog een keer.", "OK")
        }, {signal})
    }
}

const Tab = styled.div`
    background: ${props => props.isActive ? red : 'transparent'};
    color: ${props => props.isActive ? white : purple};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
    font-size: 16px;
    font-family: ${nunito};
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    flex-shrink: 0;
`;

const TabWrapper = styled.div`
    background: rgba(94, 65, 135, 0.07);
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
`;

const InnerTabWrapper = styled.div`
    height: 50px;
    width: 100%;
    border-radius: 3px;
    display: flex;
    padding: 9px;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    white-space: nowrap;
`;

const Filter = styled.div`
    margin-left: auto;
    flex-shrink: 0;
`;

const FilterBox = styled.div`
    flex: 1 1 ${({$basis}) => $basis};
`;

const HeaderListFooterWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderAndListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px 16px 54px 16px;
    ${({$showAllPeriods}) => $showAllPeriods && css`
        padding-bottom: 0;
        height: 100%;
    `};

    @media screen and ${device.tablet} {
        padding-bottom: 114px;
        flex: 1 1 0;
        overflow-y: auto;
        ${({$showAllPeriods}) => $showAllPeriods && "padding-bottom: 0;"};
    }

    @media screen and ${device.mobileL} {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 84px;
        box-sizing: border-box;
        ${({$showAllPeriods}) => $showAllPeriods && "padding-bottom: 0;"};
    }
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 590px;
`;

const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    ${({$wide}) => !$wide && css`max-width: 590px;`};
    
    @media screen and ${device.laptopL} {
        max-width: 590px;
    }
`;

const StyledLoader = styled(ConfigurableLoader)`
    top: 60px;
    transform: translateX(-50%);
    z-index: 1;
`;

export default MentorPlanOfActionScreen;